import React from 'react';
import { CryptoIconType } from 'types/baseTypes';

const BtcIcon = ({ size = '25' }: CryptoIconType) => (
  <svg fill='none' height={size} viewBox='0 0 40 40' width={size} xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_462_18369)'>
      <path
        d='M39.3976 24.8385C36.7263 35.5529 25.8738 42.0729 15.1582 39.4016C4.44755 36.7304 -2.07308 25.8779 0.598797 15.1641C3.2688 4.4485 14.1207 -2.07275 24.8332 0.598497C35.5482 3.26975 42.0682 14.1235 39.3969 24.8385H39.3976Z'
        fill='#F7931A' />
      <path
        d='M28.8168 17.1506C29.2143 14.4894 27.188 13.0588 24.4174 12.1044L25.3162 8.49938L23.1212 7.9525L22.2462 11.4625C21.6699 11.3188 21.0774 11.1831 20.4887 11.0487L21.3699 7.51562L19.1768 6.96875L18.2774 10.5725C17.7999 10.4638 17.3312 10.3563 16.8762 10.2431L16.8787 10.2319L13.8524 9.47625L13.2687 11.82C13.2687 11.82 14.8968 12.1931 14.8624 12.2162C15.7512 12.4381 15.9124 13.0262 15.8849 13.4925L14.8612 17.5994C14.9224 17.615 15.0018 17.6375 15.0893 17.6725L14.858 17.615L13.4224 23.3681C13.3137 23.6381 13.038 24.0431 12.4168 23.8894C12.4387 23.9212 10.8218 23.4912 10.8218 23.4912L9.73242 26.0037L12.5887 26.7156C13.1199 26.8487 13.6405 26.9881 14.1524 27.1194L13.2443 30.7663L15.4362 31.3131L16.3362 27.7056C16.9343 27.8681 17.5155 28.0181 18.0843 28.1594L17.188 31.75L19.3824 32.2969L20.2905 28.6575C24.0324 29.3656 26.8468 29.08 28.0299 25.6962C28.9843 22.9712 27.983 21.3994 26.0143 20.3737C27.448 20.0419 28.528 19.0988 28.8162 17.1506H28.8168ZM23.8024 24.1812C23.1237 26.9062 18.5362 25.4338 17.048 25.0638L18.253 20.2331C19.7405 20.6044 24.5099 21.3394 23.803 24.1812H23.8024ZM24.4805 17.1112C23.8618 19.59 20.043 18.3306 18.8037 18.0219L19.8962 13.6406C21.1355 13.9494 25.1255 14.5256 24.4805 17.1112Z'
        fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_462_18369'>
        <rect fill='white' height='40' width='40' />
      </clipPath>
    </defs>
  </svg>
);

export default BtcIcon;

export class Icon {
}
