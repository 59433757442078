import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useAppDispatch } from 'hooks/store';
import { useTransactions } from 'hooks/useTransactions';
import useTranslate from 'hooks/useTranslate.hook';
import useWrapAccount from 'hooks/useWrapAccount';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useLazyTransactionsQuery } from 'store/services/clientService';
import { useEventsListQuery } from 'store/services/eventsService';
import { setPortfolio } from 'store/slices/appSlice';

import TransactionsWrapper from 'components/Containers/TransactionsWrapper';
import Translated from 'components/Containers/Translated';
import { ChevronIcon } from 'components/Icons';
import Button from 'components/Simple/Button';
import { Loader } from 'components/Simple/Loader/Loader';
import Pagination from 'components/Simple/Pagination';
import TransactionCard from 'components/Simple/TransactionCard';
import Typography from 'components/Simple/Typography';
import getEventById from 'helpers/getEventById';
import { AppLangs } from 'types/baseTypes';

import s from './portfolio.module.scss';
import { TransactionRow } from './transactions/TransactionRow';
import { TransactionTable } from './transactions/TransactionTable';

interface PortfolioPageRightBlockProps {
  isLaptop?: boolean;
}

const PortfolioPageRightBlock = ({ isLaptop }: PortfolioPageRightBlockProps) => {
  const dispatch = useAppDispatch();
  const [getData, { data: transactions, isLoading: isTransactionsLoading }] =
    useLazyTransactionsQuery();
  const { data: events } = useEventsListQuery(null);
  const account = useWrapAccount();

  const theme = useSelector((state: RootState) => state.app.theme);
  const lang = useSelector((state: RootState) => state.app.lang);
  const all = useTranslate('app.view-all');
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (account) {
      getData({ account });
    }
  }, [account]);

  // useEffect(() => {
  //   if (transactions)
  //     dispatch(setPortfolio({ transactions: transactions.totalElements }))
  // }, [transactions])
  return (
    <div className={classNames(s.content, s[`content-${theme}`])}>
      {events && transactions && <TransactionTable events={events} transactions={transactions} />}
      {isTransactionsLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h1">
            <Loader />
          </Typography>
        </div>
      )}
      {transactions?.totalPages > 1 && (
        <Pagination
          page={page}
          onPageChange={({ selected }) => {
            setPage(selected);
            getData({ account, page: selected });
          }}
          totalPages={transactions?.totalPages || 0}
        />
      )}

      {!transactions?.totalElements && !isTransactionsLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h1">No Transactions</Typography>
        </div>
      )}
    </div>
  );
};

export default PortfolioPageRightBlock;
