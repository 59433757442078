const en = {
  'app.lange.en': 'English',
  'app.lange.ru': 'Russian',
  'app.hide': 'Hide',
  'app.show-bets': 'View my bets',
  'app.your-email': 'Your email',
  'app.username': 'Username',
  'app.wallet': 'Wallet',
  'app.bet': 'Scan',
  'app.new-password': 'New password',
  'app.go': 'Link',
  'app.view-all': 'Show more',
  'app.processing': 'Processing',
  'app.request': 'Request',
  'app.back': 'Back',
  'app.show-all': 'View all',
  'app.deal': 'Deal',

  'app.deal-details': 'Deal details',
  'sidebar.finance-deals': 'Finance deals',
  'sidebar.finance-deals.crypto': 'Crypto',
  'sidebar.finance-deals.stocks': 'Stocks',
  'sidebar.finance-deals.bonds': 'Bonds',
  'sidebar.finance-deals.currency': 'Currency',
  'sidebar.finance-deals.metals': 'Metals',
  'sidebar.finance-deals.product': 'Product',
  'sidebar.finance-deals.binary-options': 'Up / Down',
  'sidebar.finance-deals.lottery': 'Darts',
  'sidebar.finance-deals.jackpot': 'ChainJack Pool',
  'sidebar.finance-deals.make-bet': 'Make a bet',

  'header.main': 'Main',
  'header.my-deals': 'My Deals',
  'header.community': 'Community',
  'header.auth': 'Authorization',

  'header.burger.dark-theme': 'Dark theme',
  'header.burger.my-deals': 'My deals',
  'header.burger.my-portfolio': 'Account',
  'header.burger.log-out': 'Log out',
  'header.burger.copy-id': 'Copy ID',
  'header.burger.copy-address': 'Copy my address',
  'header.dropdown.headline': 'Select a Wallet',
  'header.dropdown.download.headline': 'Supported wallet',
  'header.dropdown.download.text':
    'In order to use the platform, you need to connect your wallet. You can do it by any way available below.',
  'header.dropdown.download.action': 'Download',
  'header.dropdown.text.first': 'By connecting your wallet, you agree to our ',
  'header.dropdown.text.second': ' and our ',
  'header.dropdown.text.terms_of_services': 'Terms of Services',
  'header.dropdown.text.privacy_policy': 'Privacy Policy',
  'header.dropdown.connect.button': 'I do not have wallet',

  'my-deals': 'My deals',
  'my-deals.finance-deals': 'Finance deals',
  'my-deals.search-for-deals': 'Search for a deal',
  'my-deals.high-liquidity': 'High liquidity',
  'my-deals.new': 'New',
  'my-deals.private': 'Private',
  'my-deals.will-the-btc': 'Will the ${btcName} price be ',
  'my-deals.common--pool': 'Common pool',
  'my-deals.bets': 'bets',
  'my-deals.bet': 'Bet',
  'my-deals.remove-bet': 'Remove bet',
  'my-deals.get-unclaimed': 'Get unclaimed funds',

  portfolio: 'Portfolio',
  'portfolio.my-deals': 'My Deals',
  'portfolio.win': 'Win',
  'portfolio.all-deals': 'All Deals',
  'portfolio.finance': 'Finance',
  'portfolio.binary-options': 'Up / Down',
  'portfolio.lottery': 'Darts',
  'portfolio.bet-amount': 'Bet amount',
  'portfolio.bet-amount1': 'Bet amount',
  'portfolio.bet-side': 'Selected side',
  'portfolio.chose-outcome': 'Chose outcome',
  'portfolio.will-be-higher': 'Will be higher',
  'portfolio.lower': 'lower',
  'portfolio.get': 'Get',
  'portfolio.outcome': 'Outcome',
  'portfolio.received': 'Received',
  'portfolio.date-time': 'Date and Time',
  'portfolio.transactions': 'Transactions',
  'portfolio.adding-bet': 'Adding a bet',
  'portfolio.will-the-btc': 'Will the BTC price be higher than $xx.xxxx',
  'portfolio.deal-id': 'Deal ID',
  'portfolio.deleting-bet': 'Deleting a bet',
  'portfolio.predict-decrease-increase': 'Predict decrease or increase of the exchange rate',
  'portfolio.return-bet': 'Return of the bet',
  'portfolio.export-report': 'An error occurs while exporting a report',
  'portfolio.deal-cancel-error': 'An error occurred while canceling a bet',
  'portfolio.store-data':
    'We always store all the data of any closed transaction. In the results, you can find a list of rates of all users joined in this deal.',
  'portfolio.download-csv': 'Download csv',
  'portfolio.transactions-result': 'Transaction results',
  'portfolio.deal-result': 'Deal results',
  'portfolio.final-rate': 'Final rate',
  'portfolio.predict-btc': 'Predict the BTC rate',
  'portfolio.predict': 'Predict the rate',
  'portfolio.win-request': 'Win Request',
  'portfolio.report-tooltip': 'Show all joins',

  'states.make-bet': 'Make a bet',
  'states.next-bet': 'Next bet',
  'states.confirm-bets': 'Confirm bets',
  'states.go-to-deal': 'Go to deal',
  'states.back-to-deal': 'Back to deal',
  'states.higher': 'Higher',
  'states.lower': 'Lower',
  'states.fee': 'Fee',
  'states.write-off-bet': 'Write off with the bet',
  'states.deal-ratio': 'Deal ratio',
  'states.maximum-win': 'Maximum win',
  'states.bets-accepted': 'Bets accepted',
  'states.bet-amount': 'Bet amount',
  'states.funds-pending': 'Funds pending',
  'states.will-be-accepted': 'Will be accepted for the deal',
  'states.your-bet': 'Your bet',
  'states.deal-done': 'Deal completed',
  'states.error': 'Error',
  'states.error-occured': 'Произошла ошибка при размещении ставки',
  'states.show-results': 'Show results',

  'private-deal': 'This deal is private',
  'private-deal.only': 'Only those who has the link can view and participate in the deal',
  'private-deal.cop-link': 'Copy the link',

  'options.five-minute-game': '5 minute game',
  'options.sixty-minute-game': '60 minute game',
  'options.weekly-game': 'FREE GAME with daily prize',
  'options.currency-pair-accepting': 'Currency pair accepting bets 12 minutes left ',
  'options.predict-increase-decrease': 'Predict increase or decrease of the BTC rate',
  'options.account': 'User address',
  'options.last-bets': 'Last bets',
  'options.my-bets': 'My bets',
  'options.predict-long-text':
    'Predict the rise or fall of BTC. The rate of the currency pair will be fixed at the specified time, the winner is the party that guessed the growth or fall of the currency pair at the end of the transaction.',

  'options.predict-on-date': 'Predict the BTC rate on',
  'options.accepting-bets': 'Accepting bets',
  'options.currency-pair': 'Currency pair',
  'options.price-bet': 'price of 1 bet',
  'options.waiting-result': 'Waiting for the result',
  'options.waiting-next': 'Waiting for the next game',
  'options.waiting': 'Waiting',
  'options.deal-closed': 'Deal closed',
  'options.pool-distributed': 'The pool is distributed',
  'options.meaning': 'Target',
  'options.make-bet': 'Make bet',
  'options.price-will': 'The price will',
  'options.data-source': 'Data source',
  'options.dinamyc': 'Dinamyc',

  'notification.deal-completed': 'Deal completed',
  'notification.update-page': 'Update the page to see a new deal',
  'notification.update': 'Update',
  'notification.collection-cookie': 'Cookie collection notification',
  'notification.we-use-cookie': 'We use cookie files to improve website quality',
  'notification.cookie-ok': 'Ok',

  'lottery.predict-price': 'Predict the price of BTC in 5 minutes',
  'jackpot.raffle-date': 'Raffle date',
  'jackpot.last-winners': 'Last winners',
  'jackpot.pool': 'ChainJack Pool',
  'jackpot.won-amount': 'won amount',
  'jackpot.predicted-rate': 'predicted rate',
  'jackpot.final-rate': 'final rate',
  'jackpot.long-text':
    'The participant who guessed the BTC rate as accurately as possible gets the 77.7% of jackpot prize fund. If there is no correct answer, the prize fund is carried over to the next week and the prize is accumulated, and the participants who were closest to the correct answer take a part of the prize. \n' +
    'How is the winnings distributed? The user who guessed 7 numbers gets 70% of the ChainJack Pool. A user who guessed 6 numbers gets 7% of the ChainJack Pool; the one\n' +
    'who guesses 5 figures gets 0.7% of the ChainJack Pool.',

  'dashboard.popular-assets': 'Popular assets',
  'dashboard.popular-deals': 'Popular deals',
  'dashboard.new-deals': 'New deals',
  'dashboard.view-deals': 'Viewed deals',
  'dashboard.bets-acception': 'Bets are acception',
  'dashboard.last-transactions': 'Last transactions',
  'dashboard.until': 'until',

  //   FREEGAME
  'freegame.be-part.header': "Be part of P2PBet's $100 DAILY RAFFLE!",
  'freegame.be-part.text1':
    'Every day we raffle 100$ between the Free Game users. Use your 3 attempts to forecast the BTC exchange rate in the given time. The entrant whose result will be the closest or the same as the BTC exchange rate at the time of expiry will receive the prize!',
  'freegame.be-part.text2':
    'But there is a condition - to take the prize you must be followed on any of our social media. And remember to include your address of the USDT TRC20 in the profile editing section. ',

  'freegame.daily-prize': 'FREE GAME with daily prize',
  'freegame.five-minute': 'FREE GAME for 5 minutes',

  'freegame.what-is': 'What is Free Game?',
  'freegame.desc':
    'Free Game - this is a platform fully imitating P2PBET functionality, but without the use of the MetaMask wallet. Accept any financial tools available on the P2PBet, on the FreeGame platform among all registered users will drop real prizes every day! We created FreeGame so that you can participate in financial betting, earn money without investing, only on your calculation and intuition. Good luck!',

  'freegame.amount-attempts': 'The amount of attempts:',
  'freegame.predict-btc':
    'Predict the BTC exchange rate at the given time. The participant whose outcome will be the nearest or the same as the actual BTC/USDT exchange rate at the time of expiration on Binance will be the winner.',
  'freegame.view-list': 'View a list of all bets.',
  'freegame.accrual': 'Accrual',
  'freegame.charging': 'Charging',
  'freegame.main-data': 'Main data',
  'freegame.payment-details': 'Payment details',
  'freegame.login': 'Log in',
  'freegame.username-email': 'Username or Email',
  'freegame.do-have-account': 'Do you have an account? Log in',
  'freegame.signup': 'Sign up',
  'freegame.leave': 'Leave Free Game mode',
  'freegame.back': 'Back',
  'freegame.save-settings': 'Save settings',
  'freegame.repeat-password': 'Repeat password',
  'freegame.safety': 'Safety',
  'freegame.setting-saved': 'Settings have been saved',
  'freegame.date-updated': 'The information has been successfully updated.',
  'freegame.eroor-create': 'An error occurred while saving data',
  'freegame.social-networks': 'Social networks',
  'freegame.loss': 'Loss',

  'bets.option-search': 'Asset search',
  'bets.option-search-name': 'Search an asset by name',

  'bets.open': 'Open',
  'bets.completed': 'Completed',
  'bets.most-bets': 'Most Bets',
  'bets.largest-pool': 'Largest pool',
  'bets.clear': 'Clear all',
  'bets.private': 'Private',
  'bets.popular': 'Popular',
  'bets.new': 'New',
  'bets.accepted': 'Bets accepted',
  'bets.accepted-not': 'Bets are not accepted',
  'bets.higher': 'higher',
  'bets.lower': 'lower',
  'bets.done': 'Number of bets',
  'bets.quick': 'Quick bet',
  'bets.current-rate': 'Current rate',
  'bets.starting-rate': 'Starting rate',
  'bets.win-amount': 'Winning amount',

  'new-bet.ratio': 'Ratio',
  'new-bet.point-ratio': 'Specify the win rate and bet',
  'new-bet.event-date': 'Event time',
  'new-bet.get-with-bet': 'Get with the bet',
  'new-bet.get-without-bet': 'Get without the bet',
  'new-bet.deal-outcome': 'Deal outcome',
  'new-bet.end-accepting': 'End of bidding',
  'new-bet.current-rate': 'Current rate',
  'new-bet.new-deal': 'New deal',
  'new-bet.currency-pair': 'Currency pair',
  'new-bet.select-currency-pair': 'Select currency pair',
  'new-bet.popular-pairs': 'Popular pairs',

  'new-bet.price-condition': 'Price condition',
  'new-bet.outcome-price': 'Select the outcome and price value for the trade',
  'new-bet.specify-beginning': 'Specify the beginning and end of the transaction',
  'new-bet.price-value': 'Price value',
  'new-bet.end-bets': 'End of bets',
  'new-bet.event-end': 'End of event',
  'new-bet.private-deal': 'Private deal',
  'new-bet.create-deal': 'Create deal',
  'new-bet.get': 'Get reward',

  'tooltip.private':
    'Private deal – is a deal, only you and users who have a link will be able to see it.',
  'tooltip.binary':
    'The Up / Down ratio is dynamic, and its value is fixed when each of the bet are completed.',
  'tooltip.deal-ratio': 'The ratio of user bets is set by the person who creates it.',
  'tooltip.maximum-win': 'A ratio used in your bet determines the maximum amount of winnings.',
  'tooltip.your-bet':
    'When you participate in the darts you can join different pools, which differ in terms of the amount of bet.',
  'tooltip.lottery.maximum-win':
    'The maximum win amount is defined as the sum of all users bets in the game, if your bet is closest to the BTC rate at the time of expiration, you will receive the maximum win amount, if there are other users with the same bet, profits will be split equally.',
  'tooltip.data-source': "The currency pair's data is obtained in real time from this exchange.",
  'tooltip.data-source.chainlink':
    'Data for this currency pair is charged according to Chain.link heartbeats for the selected currency pair, the event result is determined by the first heartbeat value after the event is completed.',

  'tooltip.jackpot.your-bet':
    'When you place a bet of $10, you use the format xxxxx.xx, when you place a bet of $100, you use the format xxxxx.x the platform automatically enters all hundredths values, when you place a bet of $1000, you use the format xxxxx, the platform automatically enters all hundredths and tenths.',
  'tooltip.jackpot.maximum-win':
    'The maximum win amount is 77.7% of the total ChainJack Pool; if you correctly guess all 7 digits of the BTC / USDT currency pair, you will receive 77.7% of the ChainJack Pool, if you correctly guess 6 digits, you will receive 7.7% of the ChainJack Pool, and if you correctly guess 5 numbers, you will receive 0.7% of the ChainJack Pool. If you and other users correctly predicted the value of a currency pair, the winnings are divided equally among all participants',

  'notification.delete-bet.title': 'Your bet has been deleted',
  'notification.delete-bet.description': 'Your bet has been successfully deleted',

  'notification.deal-created.title': 'Deal created',
  'notification.deal-created.description': 'Your deal has been successfully created',

  'notification.join-bet.title': 'Join the deal',
  'notification.join-bet.description': 'You have successfully joined the deal',

  'notification.join-bet-mass.title': 'Bets accepted',
  'notification.join-bet-mass.description':
    'Your bets have been successfully accepted to the darts',
  'notification.join-bet-mass-jackpot.description':
    'Your bets have been successfully accepted to the jackpot',

  'notification.money-accepted.title': 'Your funds have been successfully accepted for a bet',
  'notification.money-accepted.description':
    'Your funds have been approved for betting in transaction',

  'notification.expiration.title': 'Bidding ends',
  'notification.expiration.description':
    'There are fewer than 5 minutes before the end of the bet acceptance period',

  'notification.completed.won.title': 'Deal completed',
  'notification.completed.won.description':
    'The trade on which you bet has been executed successfully. Click the button or go to the portfolio to receive your prizes.',

  'notification.completed.fail.title': 'Deal completed',
  'notification.completed.fail.description':
    'The deal in which you placed the bet has been completed successfully. To see the outcome, click the button or go to the portfolio.',

  'leaderboard.title': 'Leaderboard',
  'leaderboard.description':
    'The best players will always be on the leaderboard. Play and win to become one of the best!',
  'leaderboard.table.title': 'Player List',
  'leaderboard.toggle.monthly': 'Monthly',
  'leaderboard.toggle.inyear': 'In a year',
  'leaderboard.useraddress': 'User address',
  'leaderboard.bestbet': 'Best win',
  'leaderboard.totalbets': 'Total bets',
  'leaderboard.won': 'Won',
  'leaderboard.lost': 'Lost',
  'leaderboard.result': 'Total winnings',
  'leaderboard.you': 'You',

  'chainlink.datetime': 'Date time',
  'chainlink.roundid': 'Round Id',
  'chainlink.value': 'Value',
  'chainlink.link': 'Link',
};

export default en;
