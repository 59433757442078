import { useContext, useRef, useState } from 'react';

import { CryptoContext } from 'context';
import useWrapAccount from 'hooks/useWrapAccount';

import { hooks } from 'metamask/connector';
import { BINARY_ADDRESS } from 'metamask/constants';

const { useProvider, useAccount } = hooks;

const useBinary = () => {
  const { state } = useContext(CryptoContext);
  const [isLoading, setIsLoading] = useState(false);
  const prizeTakenBets = useRef<number[]>([]);

  const { binary_company_fee, binary_allowance, token_contract, binary_contract, binary_expiration_delay } = state;

  const provider = useProvider();
  const account = useWrapAccount();

  const refundBinaryBet = async (betId: number) => {
    setIsLoading(true);

    try {
      await binary_contract
        // @ts-ignore
        ?.refundBinaryBet(betId, account);
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const getPrize = async (betId: number) => {
    setIsLoading(true);

    try {
      await binary_contract
        // @ts-ignore
        ?.takeBinaryPrize(betId, account, false); //todo: что это?
      prizeTakenBets.current.push(betId);
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const join = async (betId: any, bet: any, side: any) => {
    setIsLoading(true);
    try {
      const finalAmount = Number(bet);

      const diff = binary_allowance - finalAmount;

      if (diff < 0) {
        const approveTransaction = await token_contract
          // @ts-ignore
          ?.approve(
            BINARY_ADDRESS,
            (Number(Math.abs(finalAmount)) * 10 ** 18).toLocaleString('fullwide', {
              useGrouping: false,
            }),
          );
        await provider?.waitForTransaction(approveTransaction.hash);
      }

      const joinRequest = [
        side,
        (Number(bet) * 10 ** 18).toLocaleString('fullwide', { useGrouping: false }),
      ];

      const joinTransaction = await binary_contract
        // @ts-ignore
        ?.joinBinaryBet(betId, joinRequest);

      await provider?.waitForTransaction(joinTransaction.hash);
    } catch (e) {
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return {
    refundBinaryBet,
    binary_expiration_delay,
    prizeTakenBets: prizeTakenBets.current,
    join,
    getPrize,
    isLoading,
  };
};

export default useBinary;
