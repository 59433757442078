import React from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';

import CurrencyPair from '../../Simple/CurrencyPair';
import PulseBulb from '../../Simple/PulseBulb';
import Typography from '../../Simple/Typography';
import s from './RowBet.module.scss';

interface RowBetProps {
  theme?: ThemeType;
  inIcon?: React.ReactNode;
  outIcon?: React.ReactNode;
  className?: string;
  pulseVariant?: 'open' | 'accepted' | 'waiting' | 'closed' | 'canceled';
  currencyTitle?: string;
  currencySubTitle?: string;
  pulseTitle?: string;
  pulseSubtitle?: string;
  generalTitle?: string;
  generalSubTitle?: string;
  doneTitle?: string;
  doneSubTitle?: string;
}

interface CeilProps {
  leftBlock?: React.ReactNode;
  title?: string;
  subTitle?: string;
}

const Ceil = ({ leftBlock, title = '', subTitle = '' }: CeilProps) => (
  <div className={s.ceil}>
    {leftBlock}
    <div className={s.centerText}>
      <Typography>{title}</Typography>
      <Typography color='grey' variant='subtitle'>
        {subTitle}
      </Typography>
    </div>
  </div>
);

const RowBet = ({
                  className,
                  theme = 'default',
                  inIcon,
                  outIcon,
                  pulseVariant = 'open',
                  pulseSubtitle = '',
                  pulseTitle = '',
                  currencyTitle = '',
                  currencySubTitle = '',
                  generalTitle = '',
                  generalSubTitle = '',
                  doneTitle = '',
                  doneSubTitle = '',
                }: RowBetProps) => (
  <div className={classNames(s.headRow, s[theme], className)}>
    <Ceil
      leftBlock={<CurrencyPair iconLeft={inIcon} iconRight={outIcon} theme={theme} />}
      subTitle={currencySubTitle}
      title={currencyTitle}
    />
    <div className={s.divider} />
    {/* @ts-ignore */}
    <Ceil
      leftBlock={<PulseBulb variant={pulseVariant} />}
      subTitle={pulseSubtitle}
      title={pulseTitle}
    />
    <div className={s.divider} />
    <Ceil subTitle={generalSubTitle} title={generalTitle} />
    <div className={s.divider} />
    <Ceil subTitle={doneSubTitle} title={doneTitle} />
  </div>
);

export default RowBet;
