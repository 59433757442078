import { useAppSelector } from 'hooks/store';

import { hooks } from 'metamask/connector';

const { useAccount } = hooks;

const useWrapAccount = () => {
  const account = useAccount();
  const logout = useAppSelector((state) => state.app.logout);

  return logout ? null : account;
};
export default useWrapAccount;
