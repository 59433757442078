import { FC, memo } from 'react';
import { Link } from 'react-router-dom';

import { ThemeType } from 'types/baseTypes';
import { Urls } from '../../../../../consts/urls';
import { useWallets } from '../../../../../hooks/useWalletsData';

import s from './style.module.scss';

import Modal from 'components/Containers/Modal';
import Typography from 'components/Simple/Typography';
import Translated from 'components/Containers/Translated';
import { CardMob } from '../../../../childrens/card-mob';
import Button from 'components/Simple/Button';

interface IModalWalletConnect {
  theme: ThemeType,
  openModal: boolean,
  setOpen: () => void,
  handleOpenModalWalletDownload: () => void
}

export const ModalWalletConnect: FC<IModalWalletConnect> = memo((props) => {

  const {
    theme = 'default',
    openModal,
    setOpen,
    handleOpenModalWalletDownload,
  } = props;

  const {
    btnsConnectWallet,
  } = useWallets();

  return (
    <div className={s[theme]}>
      <Modal
        theme={theme}
        open={openModal}
        setOpen={setOpen}
      >
        <div className={s['content-modal']}>
          <div className={s['text']}>
            <Typography variant='title'>
              <Translated id='header.dropdown.headline' />
            </Typography>
          </div>

          <div className={s['text']} style={{ marginTop: '10px', marginBottom: '10px' }}>
            <Typography color='grey' variant='small' style={{ lineHeight: '18px' }}>
              <Translated id='header.dropdown.text.first' /><br />
              <Link to={Urls.termsOfServices} target='_blank'>
                <Translated id='header.dropdown.text.terms_of_services' />
              </Link>
              <Translated id='header.dropdown.text.second' />
              <Link to={Urls.privacyPolicy} target='_blank'>
                <Translated id='header.dropdown.text.privacy_policy' />
              </Link>
            </Typography>
          </div>

          {
            btnsConnectWallet.map((btn: any, index: number) => {
              return (
                <div
                  className={s['card']}
                  key={index}
                >
                  <CardMob
                    click={() => btn.handler(btn.type, handleOpenModalWalletDownload)}
                    theme={theme}
                    type={btn.type}
                    icon={btn.icon}
                  />
                </div>
              );
            })
          }

          <Button
            onClick={handleOpenModalWalletDownload}
            align='center'
            variant='outlined'
            color='none'
            className={s.walletDontHaveButtonMob}
          >
            <div className={s.blurDiv}></div>
            <Typography variant='small' color='darkblue' style={{ textAlign: 'center', marginTop: '-37px' }}>
              <Translated id='header.dropdown.connect.button' />
            </Typography>
          </Button>

        </div>
      </Modal>
    </div>
  );
});
