import React from 'react';
import { IconsTypes } from 'types/baseTypes';

const CrumbsIcon = ({ height = '19', width = '3', color = '#101319', className }: IconsTypes) => (
  <svg className={className} fill='none' height={height} viewBox='0 0 3 19' width={width}
       xmlns='http://www.w3.org/2000/svg'>
    <circle cx='1.5' cy='1.5' fill={color} r='1.5' />
    <circle cx='1.5' cy='9.5' fill={color} r='1.5' />
    <circle cx='1.5' cy='17.5' fill={color} r='1.5' />
  </svg>
);

export default CrumbsIcon;
