import { FC, MouseEvent } from 'react';
import { ThemeType } from 'types/baseTypes';

import classNames from 'classnames';
import s from './style.module.scss';

interface ICardMob {
  click?: (e?: MouseEvent<HTMLDivElement> | MouseEvent) => void,
  icon: string,
  border?: 'default' | 'install',
  background?: 'default' | 'install',
  theme: ThemeType,
  type: 'MetaMask' | 'WalletConnect' | 'TrustWallet' | 'BitKeep',
  install?: boolean
}

export const CardMob: FC<ICardMob> = (props) => {
  const {
    icon,
    click,
    border = 'default',
    background = 'default',
    theme = 'default',
    type,
    install = false,
  } = props;

  const classes = classNames(
    s[theme],
    s['base'],
    {
      [s[`${theme}-border--${border}`]]: !!border,
      [s[`${theme}-border--${background}`]]: !!background,
    },
  );

  return (
    <button
      className={classes}
      onClick={click}
    >
      <div className={s['type-wallet']}>
        <img
          className={s['type-wallet__icon']}
          src={icon}
        />
        <p className={s['type-wallet__name']}>
          {type}
        </p>
      </div>

      {
        !!install &&
        (<p className={s['base__install-wallet']}> Download </p>)
      }
    </button>
  );
};
