import React, { useState } from 'react';

import classNames from 'classnames';
import useTranslate from 'hooks/useTranslate.hook';

import { ThemeType } from 'types/baseTypes';

import { ChevronIcon } from '../../Icons';
import Button from '../../Simple/Button';
import Typography from '../../Simple/Typography';
import s from './StringTabContent.module.scss';

interface StringTabContentProps {
  str: string;
  showMore?: boolean;
  theme?: ThemeType;
}

const StringTabContent = ({ str, showMore, theme = 'default' }: StringTabContentProps) => {
  const [fullText, setFullText] = useState<boolean>(false);
  const all = useTranslate('app.view-all');
  const hide = useTranslate('app.hide');
  return (
    <div className={classNames(s.stringTabs, s[theme])}>
      <Typography>{str}</Typography>
      {showMore && (
        <Button
          className={s.betButton}
          endIcon={<ChevronIcon className={classNames({ [s.rotateChevron]: fullText })} />}
          onClick={() => setFullText((prevState) => !prevState)}
          variant='outlined'>
          {!fullText ? all : hide}
        </Button>
      )}
    </div>
  );
};

export default StringTabContent;
