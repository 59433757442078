import { useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import s from './index.module.scss';

const mock_chainlink = [
  {
    time: '1M',
    val: 'PT1M',
  },
  {
    time: '5M',
    val: 'PT5M',
  },
  {
    time: '15M',
    val: 'PT15M',
  },
  {
    time: '30M',
    val: 'PT30M',
  },
  {
    time: '1H',
    val: 'PT1H',
  },
  {
    time: '1D',
    val: 'P1D',
  },
];

const mock = [
  {
    time: '1M',
    val: 1,
  },
  {
    time: '5M',
    val: 5,
  },
  {
    time: '15M',
    val: 15,
  },
  {
    time: '30M',
    val: 30,
  },
  {
    time: '1H',
    val: 60,
  },
  {
    time: '1D',
    val: 'D',
  },
  {
    time: '1W',
    val: 'W',
  },
  {
    time: '1M',
    val: 'M',
  },
];

export interface IChartRadioGroup {
  onChange: (val: number | string) => void;
  chainlink?: boolean;
}

const ChartRadioGroup: React.FC<IChartRadioGroup> = ({ chainlink, onChange }) => {
  const [selected, setSelected] = useState<number | string>(chainlink ? 'PT1M' : 1);
  const theme = useSelector((state: RootState) => state.app.theme);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSelected(e.target.value);
    onChange(e.target.value);
  };
  return (
    <div className={s.wrapper}>
      {(chainlink ? mock_chainlink : mock).map((el: typeof mock[0], idx: number) => (
        <label
          key={idx}
          className={classNames(s.container, {
            [s.dark]: theme === 'dark',
            [s.default]: theme === 'default',
            [s.selected]: selected == el?.val,
          })}>
          {el?.time}
          <input onChange={handleChange} type="radio" name="radio" value={el?.val} />
          <span className="checkmark"></span>
        </label>
      ))}
    </div>
  );
};

export default ChartRadioGroup;
