import { auctionService } from './auctionService';
import { binaryService } from './binaryService';
import { clientService } from './clientService';
import { customService } from './customService';
import { eventsService } from './eventsService';
import { jackpotService } from './jackpotService';
import { leaderboardService } from './leaderboardService';
import { notificationService } from './notificationService';
import { chainLinkService } from './сhainLinkService';

export const services = {
  [clientService.reducerPath]: clientService.reducer,
  [customService.reducerPath]: customService.reducer,
  [eventsService.reducerPath]: eventsService.reducer,
  [binaryService.reducerPath]: binaryService.reducer,
  [auctionService.reducerPath]: auctionService.reducer,
  [jackpotService.reducerPath]: jackpotService.reducer,
  [notificationService.reducerPath]: notificationService.reducer,
  [leaderboardService.reducerPath]: leaderboardService.reducer,
  [chainLinkService.reducerPath]: chainLinkService.reducer,
};

export const servicesMiddleware = [
  clientService.middleware,
  customService.middleware,
  eventsService.middleware,
  binaryService.middleware,
  auctionService.middleware,
  jackpotService.middleware,
  leaderboardService.middleware,
  notificationService.middleware,
  chainLinkService.middleware,
];
