import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import cn from 'classnames';
import { useAppSelector } from 'hooks/store';
import { useSelector } from 'react-redux';

import { ThemeType } from 'types/baseTypes';
import { Children } from 'types/children';

import palette from '../../../theme/Palette.module.scss';
import useMediaQuery from '../../../theme/useMediaQuery';
import { CloseIcon, TriangleLeft } from '../../Icons';
import Button from '../../Simple/Button';
import s from './Menu.module.scss';

interface MenuProps {
  children?: Children;
  theme?: ThemeType;
  button?: React.ReactNode;
  anchor?: 'bottom' | 'right' | 'left';
  className?: string;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  noMobile?: boolean;
}

const getToggle = (toggle: boolean): string => (toggle ? 'block' : 'none');
const getTheme = (theme?: ThemeType): string => (theme === 'dark' ? 'dark' : '');

const getPositionStyles = (anchor: string) => {
  if (anchor === 'right') {
    return 'wrapper-right';
  }
  if (anchor === 'left') {
    return 'wrapper-left';
  }
  return 'wrapper-bottom';
};

const Menu: React.FC<MenuProps> = ({
                                     children,
                                     theme,
                                     button,
                                     anchor = 'right',
                                     className,
                                     top,
                                     left,
                                     right,
                                     bottom,
                                     noMobile,
                                   }) => {
  const [toggle, setToggle] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const refBtn = useRef<HTMLDivElement>(null);
  const lastHide = useAppSelector((state) => state.app.lastHide);
  const refMobileOverlay = useRef<HTMLDivElement>(null);
  const isLaptop = useMediaQuery(1024);
  const isAnchorRight = anchor === 'right';
  const isAnchorLeft = anchor === 'left';
  const isDarkTheme = theme === 'dark';

  const handleToggle = (e: any) => {
    if (refMobileOverlay.current === e.target) return; //! examination for mobile

    const path = e.composedPath && e.composedPath();
    const isRefTargetCustomButton = path && path.some((el: object) => el === refBtn.current);
    const isRefTargetNotDropMenu = path && path.some((el: object) => el === ref.current);

    if (isRefTargetCustomButton) {
      setToggle((prevState) => !prevState);
    } else if (!isRefTargetNotDropMenu) {
      setToggle(false);
    }
  };

  useEffect(() => {
    setToggle(false);
  }, [lastHide]);

  const getClassNamesToTrianglePosition = () => {
    if (isAnchorRight) return s.triangle;
    if (isAnchorLeft) return s.triangleRight;
    return s.triangleTop;
  };

  useEffect(() => {
    window.document.addEventListener('click', handleToggle, true);
    return () => window.document.removeEventListener('click', handleToggle, true);
  }, []);

  const overlayClasses = classNames(s.overlay, s[getToggle(toggle)]);
  const wrapperClasses = classNames(
    s.wrapper,
    s[getToggle(toggle)],
    s[getPositionStyles(anchor)],
    s[getTheme(theme)],
    className,
  );
  const buttonClasses = classNames(s.closeBtn, isDarkTheme && s.closeBtnDark);
  const triangleClasses = classNames(
    getClassNamesToTrianglePosition(),
    isDarkTheme ? palette.darkSvg : palette.whiteSvg,
  );

  return (
    <div className={cn(s.container, !noMobile && s.withMobile)}>
      <div className={s.btnWrap} ref={refBtn}>
        {button}
      </div>
      <div className={overlayClasses} ref={refMobileOverlay} />
      <div className={wrapperClasses} ref={ref} style={{ top, left, right, bottom }}>
        {isLaptop && !noMobile && <div className={s.swipe} />}
        {children}
        <div>
          <Button className={buttonClasses} iconButton onClick={handleToggle} variant='contained'>
            <CloseIcon />
          </Button>
        </div>
        {!isLaptop && <TriangleLeft className={triangleClasses} />}
      </div>
    </div>
  );
};

export default Menu;
