import React from 'react';
import { IconsTypes } from 'types/baseTypes';

const BadgeIcon = ({ height = '20', width = '21', className, color = '#FFFFFF' }: IconsTypes) => (
  <svg className={className} fill='none' height={height} viewBox='0 0 21 20' width={width}
       xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.6671 12.4561C14.5329 12.6333 14.551 12.9044 14.7131 13.0665L20.2625 18.6159C20.5792 18.9322 20.5792 19.4457 20.2625 19.7625C19.9457 20.0792 19.4322 20.0792 19.1159 19.7625L13.5665 14.2131C13.4067 14.0532 13.1329 14.034 12.9542 14.1682C12.9542 14.1682 12.8871 14.2255 12.6816 14.3628C11.4385 15.1923 9.94465 15.6757 8.33802 15.6757C4.00912 15.6757 0.5 12.1666 0.5 7.83805C0.5 3.50913 4.00912 0 8.33802 0C12.6665 0 16.1756 3.50913 16.1756 7.83805C16.1756 9.45106 15.6881 10.9502 14.853 12.1963C14.7188 12.3965 14.6671 12.4561 14.6671 12.4561L14.6671 12.4561ZM8.33806 14.054C11.771 14.054 14.554 11.271 14.554 7.83805C14.554 4.40469 11.771 1.62171 8.33806 1.62171C4.90471 1.62171 2.12174 4.40469 2.12174 7.83805C2.12174 11.271 4.90471 14.054 8.33806 14.054Z'
      fill={color} />
  </svg>
);

export default BadgeIcon;
