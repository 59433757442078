import React from 'react';
import { IconsTypes } from 'types/baseTypes';

type CircleIconProps = {
  stopColor?: string;
  size?: string;
}

type IconProps = IconsTypes & CircleIconProps

const CircleIconMob = ({ size = '35', stopColor = '#0075FF', className }: IconProps) => (
  <svg className={className} fill='none' height={size} viewBox='0 0 35 35' width={size}
       xmlns='http://www.w3.org/2000/svg'>
    <circle cx='17.5' cy='17.5' fill='url(#paint1_angular_538_2893111)' r='17.5' />
    <defs>
      <radialGradient cx='0' cy='0' gradientTransform='translate(17.5 17.5) rotate(90) scale(17.5)'
                      gradientUnits='userSpaceOnUse' id='paint1_angular_538_2893111'
                      r='1'>
        <stop stopColor='#0075FF' />
        <stop offset='1' stopColor={stopColor} stopOpacity='0.25' />
      </radialGradient>
    </defs>
  </svg>
);

export default CircleIconMob;
