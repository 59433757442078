import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApi } from 'store/consts';
import { getExecutionType } from 'store/utils/getExecutionType';

export const API_NAME = 'jackpotService';

export const jackpotService = createApi({
  reducerPath: API_NAME,
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseApi}/jackpot/`,
    headers: {
      Accept: 'application/json',
    },
  }),
  endpoints: (builder) => ({
    getReport: builder.mutation({
      query: (id) => ({
        url: `bet/${getExecutionType()}/${id}/report`,
        method: 'GET',
        responseHandler: async (response) => {
          if (response.status === 200 && response.url) {
            const tempLink = document.createElement('a');
            tempLink.href = response.url;
            tempLink.click();
          }
        },
        cache: 'no-cache',
      }),
    }),
    getNext: builder.query({
      query: () => ({
        url: `bet/${getExecutionType()}/next`,
        method: 'GET',
      }),
    }),
    betList: builder.query({
      query: (id) => {
        let body;
        if (id) {
          body = {
            executionType: getExecutionType(),
            page: 0,
            size: 1,
            ids: [Number(id)],
          };
        } else {
          body = {
            executionType: getExecutionType(),
            page: 0,
            size: 1,
            sort: {
              direction: 'DESC',
              property: 'createdDate',
            },
          };
        }
        return {
          url: `bet`,
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body,
        };
      },
    }),
    joinList: builder.query({
      query: ({ id, client, page, size }) => ({
        url: `bet/joins`,
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: {
          executionType: getExecutionType(),
          page: page || 0,
          size: size || 20,
          client,
          jackpotBet: id,
          statusList: {
            list: [
              'JOINED',
              'LOST',
              'PRIZE_TAKEN_FIRST',
              'PRIZE_TAKEN_SECOND',
              'PRIZE_TAKEN_THIRD',
              'WON_FIRST',
              'WON_SECOND',
              'WON_THIRD',
            ],
          },
          sort: {
            direction: 'DESC',
            property: 'createdDate',
          },
        },
      }),
    }),
    leader: builder.query({
      query: ({ id, value }) => ({
        url: `bet/${getExecutionType()}/${id}/leader?value=${value}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetNextQuery,
  useGetReportMutation,
  useLazyBetListQuery,
  useLazyJoinListQuery,
  useLazyLeaderQuery,
} = jackpotService;
