import React from 'react';
import { IconsTypes } from 'types/baseTypes';

const ChevronIcon = ({ height = '8', width = '12', color = '#195BDC', className }: IconsTypes) => (
  <svg className={className} fill='none' height={height} viewBox='0 0 12 8' width={width}
       xmlns='http://www.w3.org/2000/svg'>
    <path d='M11 1.5L6 6.5L1 1.5' stroke={color} strokeLinecap='round' strokeWidth='1.5' />
  </svg>
);

export default ChevronIcon;
