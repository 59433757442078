import React from 'react';

import useTranslate from 'hooks/useTranslate.hook';

import { ThemeType } from 'types/baseTypes';

import Button from '../../Simple/Button';
import Typography from '../../Simple/Typography';
import Translated from '../Translated';
import s from './WinRequest.module.scss';

interface WinRequestProps {
  theme?: ThemeType;
  prize?: string | number;
  requestFunc?: () => void;
  backFunc?: () => void;
  title?: string;
  subTitle?: string;
}

const WinRequest = ({
                      title = '',
                      subTitle = '',
                      theme = 'default',
                      prize = '',
                      requestFunc,
                      backFunc,
                    }: WinRequestProps) => {
  const win = useTranslate('portfolio.win');

  return (
    <div className={s[theme]}>
      <div className={s.mb_10}>
        <Typography variant='h1'>{title}</Typography>
      </div>
      <div className={s.mb_25}>
        <Typography color='grey'>{subTitle}</Typography>
      </div>
      <div className={s.divider} />
      <div className={s.rowBlock}>
        <div className={s.prizeBlock}>
          <div>
            <Typography color='grey' variant='subtitle'>
              {win.toLocaleLowerCase()}
            </Typography>
          </div>
          <div>
            <Typography>{prize}</Typography>
          </div>
        </div>
        <div className={s.buttonGroup}>
          <Button onClick={backFunc} size='sm'>
            <Translated id='app.back' />
          </Button>
          <Button color='secondary' onClick={requestFunc} size='sm'>
            <Translated id='app.request' />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WinRequest;
