import React, { useId } from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';
import { Children } from 'types/children';

import s from './TableBody.module.scss';

interface TableBodyProps {
  theme?: ThemeType;
  columnNames: string[];
  children?: Children;
}

const TableBody = ({ theme = 'default', columnNames, children }: TableBodyProps) => {
  const id = useId();
  return (
    <table className={s.table}>
      <thead
        className={classNames(
          s.thead,
          columnNames && columnNames.length === 3 && s.centerTwoColumn,
        )}>
      <tr>
        {columnNames &&
          columnNames.map((columnName: string, idx: number) => (
            <th key={`${id}${columnName}${idx}`}>{columnName}</th>
          ))}
      </tr>
      </thead>
      <tbody className={classNames(s.tbody, s[`tbody-${theme}`])}>{children}</tbody>
    </table>
  );
};

export default TableBody;
