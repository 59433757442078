import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import useColor from 'theme/useColor';

import Menu from 'components/Containers/Menu';
import Translated from 'components/Containers/Translated';
import { CrumbsIcon } from 'components/Icons';
import Button from 'components/Simple/Button';
import Label from 'components/Simple/Label';
import Switcher from 'components/Simple/Switcher';
import Typography from 'components/Simple/Typography';
import formatAddress from 'helpers/formatAddress';
import { ThemeType } from 'types/baseTypes';

import { useConnectWallet } from '../../../hooks/web3/useConnectWallet';
import { Web3ConnectWalletMob } from '../../../module/web3/wallet';
import CircleIconMob from '../../Icons/CircleIconMob';
import { LoginLoader } from '../../Simple/Loader/LoginLoader';
import s from './HeaderBlock.module.scss';

interface HeaderBlockProps {
  theme?: ThemeType;
  changeTheme: () => void;
  handleModal?: (value: boolean) => void;
}

interface IsConnectWalletBlockProps {
  menuFunction?: () => void;
  theme?: ThemeType;
}

const IsConnectWalletBlock = ({ theme }: IsConnectWalletBlockProps) => {
  const navigate = useNavigate();
  const { fullAddress, logoutWallet } = useConnectWallet();
  return (
    <>
      <div className={s.menuLabel} onClick={() => navigate('/portfolio')}>
        <Label
          nextElement
          theme={theme}
          title={
            <Typography variant='title'>
              <Translated id='header.burger.my-deals' />
            </Typography>
          }
        />
      </div>
      <div className={s.menuLabel} onClick={() => navigator.clipboard.writeText(fullAddress || '')}>
        <Label
          theme={theme}
          nextElement
          title={
            <Typography variant='title'>
              <Translated id='header.burger.copy-address' />
            </Typography>
          }
        />
      </div>
      <div
        className={s.menuLabel}
        onClick={() => {
          logoutWallet();
        }}>
        <Label
          theme={theme}
          title={
            <Typography variant='title'>
              <Translated id='header.burger.log-out' />
            </Typography>
          }
        />
      </div>
    </>
  );
};

const HeaderBlock = ({ theme = 'default', changeTheme, handleModal }: HeaderBlockProps) => {
  const { address } = useConnectWallet();
  const { lowDark, white, blue800, primary } = useColor();
  const isDarkTheme = theme === 'dark';
  const getColor = (isDarkTh: boolean) => (isDarkTh ? white : lowDark);

  const [openModalWalletConnect, setOpenModalWalletConnect] = useState(false);

  useEffect(() => {
    setOpenModalWalletConnect(false);
  }, [address]);

  const handleOpenModalWalletConnect = () => {
    handleModal && handleModal(!openModalWalletConnect);
    setOpenModalWalletConnect((prevState) => !prevState);
  };

  return (
    <div className={classNames(s.innerHeader, s[`innerHeader-${theme}`])}>
      {address ? (
        <div className={s.connectBlockMobile}>
          <CircleIconMob stopColor={isDarkTheme ? blue800 : primary} />
          <div className={s.textMobile}>{formatAddress(address)}</div>
        </div>
      ) : (
        <div>
          <Button onClick={handleOpenModalWalletConnect} size='sm'>
            <span>Connect wallet</span>
          </Button>
          {openModalWalletConnect && (
            <Web3ConnectWalletMob
              openModal={openModalWalletConnect}
              setOpen={handleOpenModalWalletConnect}
              theme={theme}
            />
          )}
        </div>
      )}
      {address && (
        <Menu
          anchor='bottom'
          button={
            <Button align='end' iconButton>
              <CrumbsIcon color={getColor(isDarkTheme)} className={s.crumb} />
            </Button>
          }
          theme={theme}>
          {/* switcher dark theme   */}

          {/* <Label */}
          {/*   nextElement={!!address} */}
          {/*   rightElement={<Switcher onChange={changeTheme} value={isDarkTheme} />} */}
          {/*   theme={theme} */}
          {/*   title={ */}
          {/*     <Typography variant="title"> */}
          {/*       <Translated id="header.burger.dark-theme" /> */}
          {/*     </Typography> */}
          {/*   } */}
          {/* /> */}
          {address ? <IsConnectWalletBlock theme={theme} /> : <span />}
        </Menu>
      )}
    </div>
  );
};

export default HeaderBlock;
