import React from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';

import { SearchIcon } from '../../Icons';
import s from './TextField.module.scss';

interface TextFieldProps {
  theme?: ThemeType;
  label?: string;
  isSearch?: boolean;
  placeholder?: string;
  value?: string;
  setValue?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  background?: string;
  border?: string;
  className?: string;
}

const TextField = ({
                     theme = 'default',
                     label = '',
                     isSearch = false,
                     placeholder = '12 USDT',
                     value,
                     setValue,
                     readOnly = false,
                     background,
                     border,
                     className,
                   }: TextFieldProps) => {

  const handleClick = () => console.log('handleClick');

  const readOnlyStyle = readOnly ? 'readOnly' : '';

  return (
    <>
      {label ? <div className={classNames(s.label)}>{label}</div> : null}
      <div className={classNames(s.wrapper, className, s[theme])} style={{ background, border }}>
        <input className={classNames(s.input, s[theme], s[readOnlyStyle])}
               id='input'
               name='input'
               onChange={setValue}
               placeholder={placeholder}
               readOnly={readOnly}
               style={{ background }}
               type='text'
               value={value}
        />
        {isSearch ? (
          <div className={s.icon} onClick={handleClick}>
            <SearchIcon />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TextField;
