import { useContext, useRef, useState } from 'react';

import { CryptoContext } from 'context';
import useWrapAccount from 'hooks/useWrapAccount';

import { hooks } from 'metamask/connector';
import { AUCTION_ADDRESS, BINARY_ADDRESS } from 'metamask/constants';

const { useProvider, useAccount } = hooks;

const useLottery = () => {
  const { state } = useContext(CryptoContext);
  const [isLoading, setIsLoading] = useState(false);
  const prizeTakenBets = useRef<number[]>([]);

  const { lottery_company_fee, lottery_allowance, token_contract, lottery_contract } = state;

  const provider = useProvider();
  const account = useWrapAccount();

  const getPrize = async (betId: number) => {
    setIsLoading(true);

    try {
      await lottery_contract
        // @ts-ignore
        ?.takeAuctionPrize(betId, account, false);
      prizeTakenBets.current.push(betId);
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const joinMass = async (betId: any, bet: any, values: any) => {
    setIsLoading(true);
    try {
      const finalAmount = Number(bet);

      const diff = lottery_allowance - finalAmount;

      if (diff < 0) {
        const approveTransaction = await token_contract
          // @ts-ignore
          ?.approve(
            AUCTION_ADDRESS,
            (Number(Math.abs(finalAmount)) * 10 ** 18).toLocaleString('fullwide', {
              useGrouping: false,
            }),
          );
        await provider?.waitForTransaction(approveTransaction.hash);
      }

      // @ts-ignore
      window.lottery_contract = lottery_contract;
      const joinTransaction = await lottery_contract
        // @ts-ignore
        ?.massJoinAuctionBet([betId, false, values]);

      await provider?.waitForTransaction(joinTransaction.hash);
    } catch (e) {
      console.log(e);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const join = async (betId: any, bet: any, value: any) => {
    setIsLoading(true);
    try {
      const finalAmount = Number(bet);

      const diff = lottery_allowance - finalAmount;

      if (diff < 0) {
        const approveTransaction = await token_contract
          // @ts-ignore
          ?.approve(
            AUCTION_ADDRESS,
            (Number(Math.abs(finalAmount)) * 10 ** 18).toLocaleString('fullwide', {
              useGrouping: false,
            }),
          );
        await provider?.waitForTransaction(approveTransaction.hash);
      }

      const joinTransaction = await lottery_contract
        // @ts-ignore
        ?.joinAuctionBet([betId, value]);

      await provider?.waitForTransaction(joinTransaction.hash);
    } catch (e) {
      console.log(e);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return {
    prizeTakenBets: prizeTakenBets.current,
    join,
    joinMass,
    getPrize,
    isLoading,
  };
};

export default useLottery;
