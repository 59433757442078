import React, { useState } from 'react';

import classNames from 'classnames';
import useTranslate from 'hooks/useTranslate.hook';

import Pagination from 'components/Simple/Pagination';
import { ThemeType } from 'types/baseTypes';
import { Children } from 'types/children';

import { ChevronIcon } from '../../../Icons';
import Button from '../../../Simple/Button';
import TableBody from '../TableBody/TableBody';
import s from './TableMain.module.scss';

interface TableMainProps {
  theme?: ThemeType;
  children?: Children;
  totalPages?: number;
  handlePage?: (page: number) => void;
  columnNames: string[];
  page?: number;
}

const TableMain = ({
  theme = 'default',
  children,
  columnNames = [],
  handlePage,
  page,
  totalPages,
}: TableMainProps) => {
  return (
    <div className={classNames(s.wrapper, s[theme])}>
      <TableBody columnNames={columnNames} theme={theme}>
        {children}
      </TableBody>
      {totalPages! > 1 && children && (
        <Pagination
          onPageChange={({ selected }) => {
            handlePage && handlePage(selected);
          }}
          totalPages={totalPages || 0}
          page={page || 0}
        />
      )}
    </div>
  );
};

export default TableMain;
