import React, { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from 'hooks/store';
import useTranslate from 'hooks/useTranslate.hook';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IClientInfo, useClientInfoQuery } from 'store/services/clientService';
import { formatDate } from 'store/utils/formatDate';
import useMediaQuery from 'theme/useMediaQuery';

import Layout from 'components/Containers/Layout';
import Modal from 'components/Containers/Modal';
import ReportRequest from 'components/Containers/ReportRequest';
import Translated from 'components/Containers/Translated';
import WinRequest from 'components/Containers/WinRequest';
import Counter from 'components/Simple/Counter/Counter';
import { MainLoader } from 'components/Simple/MainLoader/MainLoader';
import MyDealCard from 'components/Simple/MyDealCard';
import { Card } from 'components/Simple/MyProfile/Card';
import { FilterContainer } from 'components/Simple/MyProfile/Filter/FilterButtons';
import { MobileCards } from 'components/Simple/MyProfile/MobileCards/MobileCards';
import { ProfileStats } from 'components/Simple/MyProfile/ProfileStats';
import { PropsOfCards } from 'components/Simple/MyProfile/PropsOfCards';
import Tabs from 'components/Simple/Tabs';
import TransactionCard from 'components/Simple/TransactionCard/TransactionCard';
import Typography from 'components/Simple/Typography';
import { hooks } from 'metamask/connector';
import { AppLangs } from 'types/baseTypes';

import { Loader } from '../../components/Simple/Loader/Loader';
import { ProfileTitle } from './Title';
import PortfolioPageLeftBlock from './portfolio-page';
import PortfolioPageRightBlock from './portfolio-right';
import s from './portfolio.module.scss';

interface CeilProps {
  title?: string;
  subTitle?: string;
}

export const Ceil = ({ title = '', subTitle = '' }: CeilProps) => (
  <div>
    <Typography color='grey' variant='small'>
      {title}
    </Typography>
    <Typography variant='h5'>{subTitle}</Typography>
  </div>
);

const { useAccount } = hooks;

const defaultClientInformation: IClientInfo = {
  registrationDate: '',
  totalWonAmount: '...',
  onBetAmount: '...',
  profitAmount: '...',
  bestWon: '...',
};

const Portfolio = () => {
  const theme = useSelector((state: RootState) => state.app.theme);
  const lang = useSelector((state: RootState) => state.app.lang);
  const [prize, setPrize] = useState(0);
  const [getReportModal, setGetReportModal] = useState<any>(null);

  const isLaptop = useMediaQuery(1240);
  const [openModalWinRequest, setOpenModalWinRequest] = useState(false);

  const handleOpenModalWinRequest = () => {
    setOpenModalWinRequest((prevState) => !prevState);
  };

  const handleGetReportModal = (item: any) => {
    if (item) {
      setGetReportModal(item);
    } else {
      setGetReportModal(null);
    }
  };

  const requestFunc = () => console.log('requestFunc');
  const allDeals = useTranslate('portfolio.all-deals');
  const finance = useTranslate('portfolio.finance');
  const options = useTranslate('portfolio.binary-options');
  const lottery = useTranslate('portfolio.lottery');
  const winRequest = useTranslate('portfolio.win-request');
  const account = useAppSelector((state) => state.wallet.address);
  const { data: clientInformation, isLoading: isClientInfoLoading } = useClientInfoQuery(account);
  const transactions = useAppSelector((state) => state.app.portfolio.transactions);
  const deals = useAppSelector((state) => state.app.portfolio.deals);

  if (isClientInfoLoading) {
    return (
      <Layout>
        <div style={{ position: 'relative', height: '100%' }}>
          <MainLoader />
        </div>
      </Layout>
    );
  }

  return (
    <>
      <Layout>
        {isClientInfoLoading ? (
          <Loader />
        ) : (
          <>
            {/*<ProfileTitle>*/}
            {/*  <Typography variant='h1'>My Profile</Typography>*/}
            {/*  <Typography color='grey' variant='h4'>*/}
            {/*    Joined {formatDate(clientInformation?.registrationDate!)}*/}
            {/*  </Typography>*/}
            {/*</ProfileTitle>*/}
            {/*<ProfileStats data={clientInformation ?? defaultClientInformation} />*/}
          </>
        )}

        <Tabs
          className={s.tabsWrapper}
          pageTabsToChangeStyles
          tabsName={[
            <Typography style={{ display: 'flex', alignItems: 'center' }} variant='h1'>
              <Translated id='header.my-deals' />
              <Counter amount={deals} />
            </Typography>,
            <Typography style={{ display: 'flex', alignItems: 'center' }} variant='h1'>
              <Translated id='portfolio.transactions' />
              <Counter amount={transactions} />
            </Typography>,
          ]}
          theme={theme}>
          <PortfolioPageLeftBlock
            handleGetReportModal={handleGetReportModal}
            isLaptop={isLaptop}
            onClick={handleOpenModalWinRequest}
            theme={theme}
          />
          <PortfolioPageRightBlock isLaptop={isLaptop} />
        </Tabs>
        {openModalWinRequest && (
          <Modal open={openModalWinRequest} setOpen={handleOpenModalWinRequest} theme={theme}>
            <WinRequest
              backFunc={handleOpenModalWinRequest}
              prize={`${prize} USDT`}
              requestFunc={requestFunc}
              subTitle={
                lang === AppLangs.ru
                  ? 'Будет ли цена BTC выше $20 000?'
                  : 'Will be BTC price go above $20,000?'
              }
              theme={theme}
              title={winRequest}
            />
          </Modal>
        )}
      </Layout>
      {!!getReportModal && (
        <Modal
          open={!!getReportModal}
          setOpen={() => {
            handleGetReportModal(null);
          }}
          theme={theme}>
          <ReportRequest theme={theme} item={getReportModal} />
        </Modal>
      )}
    </>
  );
};

export default Portfolio;
