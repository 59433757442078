import plural from 'plural-ru';

import { AppLangs } from 'types/baseTypes';

const formatLeft = (left: number, lang = AppLangs.en) => {
  const s = left / 1000;

  const days = Math.floor(s / (3600 * 24));
  const hours = Math.floor((s % (3600 * 24)) / 3600);
  const minutes = Math.floor((s % 3600) / 60);
  const seconds = Math.floor(s % 60);
  const years = Math.round(days / 365);

  let daysString;
  let hoursString;
  let minutesString;
  let secondsString;
  let leftString;
  let yearStrig;

  // переводы и склонение слов
  if (lang === AppLangs.en) {
    yearStrig = plural(years, 'year', 'years');
    daysString = plural(days, 'day', 'days');
    hoursString = plural(hours, 'hour', 'hours');
    minutesString = plural(minutes, 'minute', 'minutes');
    secondsString = plural(seconds, 'second', 'seconds');
    leftString = 'left';
  }

  if (lang === AppLangs.ru) {
    yearStrig = plural(years, 'год', 'года', 'лет');
    daysString = plural(days, 'день', 'дня', 'дней');
    hoursString = plural(hours, 'час', 'часа', 'часов');
    minutesString = plural(minutes, 'минута', 'минуты', 'минут');
    secondsString = plural(seconds, 'секунда', 'секунды', 'секунд');
    leftString = 'осталось';
  }

  // вывод только в годах
  if (days > 365) {
    if (lang === AppLangs.ru) return `${leftString} ${years} ${yearStrig}`;
    if (lang === AppLangs.en) return `${years} ${yearStrig} ${leftString}`;
  } else {
    if (days > 0) {
      return `${leftString} ${days} ${daysString}`;
    } else {
      return `${hours > 0 ? hours + ' ' + hoursString : ''} ${
        minutes > 0 ? minutes + ' ' + minutesString : ''
      } ${seconds > 0 ? seconds + ' ' + secondsString : ''} `;
    }
  }

  return '';
};

export default formatLeft;
