import React from 'react';

import useTranslate from 'hooks/useTranslate.hook';
import useWrapAccount from 'hooks/useWrapAccount';
import { Link } from 'react-router-dom';

import { ThemeType } from 'types/baseTypes';

import Typography from '../../Simple/Typography';
import s from './navigateBlock.module.scss';

interface NavigateBlockProps {
  theme?: ThemeType;
}

interface IncomeNavigateLink {
  id: number;
  link: string;
  text: string;
  auth?: boolean;
}

const NavigateBlock = ({ theme = 'default' }: NavigateBlockProps) => {
  const account = useWrapAccount();
  const mainText = useTranslate('header.main');
  const myDeals = useTranslate('header.my-deals');
  const community = useTranslate('header.community');
  const leaderboard = useTranslate('leaderboard.title');

  const navigateLinks = [
    { id: 2, link: '/portfolio', text: myDeals, auth: true },
  ];

  return (
    <div className={`${s.navigateBlock} ${s[theme]}`}>
      {navigateLinks
        .filter(({ auth }) => (auth && account) || !auth)
        .map(({ link, text, id }) =>
          link.indexOf('http') === 0 ? (
            <a href={link} key={id} target={'_blank'}>
              <Typography>{text}</Typography>
            </a>
          ) : (
            <Link key={id} to={link}>
              <Typography>{text}</Typography>
            </Link>
          ),
        )}
    </div>
  );
};

export default NavigateBlock;
