import WalletConnectProvider from '@walletconnect/web3-provider';

export class WalletConnect {
  constructor(RPC_URL, NETWORK_ID, INFURA_ID) {
    this.RPC_URL = RPC_URL;
    this.NETWORK_ID = NETWORK_ID;
    this.INFURA_ID = INFURA_ID;

    this.address = null;
    this.provider = null;
  }

  async connect() {
    try {
      //Здесь запрос в WalletConnect
      const provider = new WalletConnectProvider({
        infuraId: this.INFURA_ID,
        rpc: {
          [Number(this.NETWORK_ID)]: this.RPC_URL,
        },
        qrcodeModalOptions: {
          mobileLinks: [
            'rainbow',
            'metamask',
            'argent',
            'trust',
            'imtoken',
            'pillar',
          ],
        },
      });

      const account = await provider.enable();

      if (account) {
        this.provider = provider;
        this.address = account[0];

        return {
          address: this.address,
          provider: this.provider,
          type: 'WalletConnect',
        };
      }
    } catch (e) {
      return e;
    }
  }
}
