import {
  MetaMaskIcon,
  WalletConnectIcon,
  TrustWalletIcon,
  BitKeepIcon,
} from '../assets/images';

import { useConnectWallet } from './useConnectWallet';

const useWallets = () => {

  const {
    onMetaMask,
    onWalletConnect,
    onTrustWallet,
    onBitKeep,
  } = useConnectWallet();

  const handler = async (type: string, handler: () => void): Promise<void> => {
    if (type === 'MetaMask') {
      const res = await onMetaMask();
      if (!!res.linkToOpen) handler();
    }
    if (type === 'WalletConnect') {
      const res = await onWalletConnect();
      if (!!res.linkToOpen) handler();
    }
    if (type === 'TrustWallet') {
      const res = await onTrustWallet();
      if (!!res.linkToOpen) handler();
    }
    if (type === 'BitKeep') {
      const res = await onBitKeep();
      if (!!res.linkToOpen) handler();
    }
  };

  const btnsConnectWallet: any = [
    {
      id: 1,
      type: 'MetaMask',
      text: 'MetaMask',
      handler: handler,
      icon: MetaMaskIcon,
    },
    {
      id: 2,
      type: 'WalletConnect',
      text: 'WalletConnect',
      handler: handler,
      icon: WalletConnectIcon,
    },
    {
      id: 3,
      type: 'TrustWallet',
      text: 'TrustWallet',
      handler: handler,
      icon: TrustWalletIcon,
    },
    {
      id: 4,
      type: 'BitKeep',
      text: 'BitKeep',
      handler: handler,
      icon: BitKeepIcon,
    },
  ];

  const btnsInstallWallet: any = [
    {
      id: 1,
      text: 'MetaMask',
      icon: MetaMaskIcon,
      link: `https://metamask.app.link/dapp/${window.location.host}`,
    },
    {
      id: 2,
      text: 'WalletConnect',
      icon: WalletConnectIcon,
      link: `https://walletconnect.com/`,
    },
    {
      id: 3,
      text: 'TrustWallet',
      icon: TrustWalletIcon,
      link: `https://trustwallet.com/download/`,
    },
    {
      id: 4,
      text: 'BitKeep',
      icon: BitKeepIcon,
      link: `https://bitkeep.com/en/download`,
    },
  ];

  return {
    btnsConnectWallet,
    btnsInstallWallet,
  };
};

export {
  useWallets,
};
