import React, { memo, useEffect } from 'react';

import useTranslate from 'hooks/useTranslate.hook';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useLazyGetLastWiningQuery } from 'store/services/clientService';
import useColor from 'theme/useColor';
import useMediaQuery from 'theme/useMediaQuery';

import SwiperSlider from 'components/Containers/SwiperSlider';
import { CircleIcon, TetherIcon } from 'components/Icons';
import CurrencyPair from 'components/Simple/CurrencyPair';
import formatAddress from 'helpers/formatAddress';
import { formatDate } from 'helpers/formatDate';

import s from './Winners.module.scss';
import copy from './images/copy.svg';
import tooltip from './images/tooltip.svg';

const Winners = (props: any) => {
  const { event } = props;
  const lastWinners = useTranslate('jackpot.last-winners');
  const wonAmount = useTranslate('jackpot.won-amount');
  const predictedRate = useTranslate('jackpot.predicted-rate');
  const finalRate = useTranslate('jackpot.final-rate');
  const dateTime = useTranslate('portfolio.date-time');

  const bet = useTranslate('my-deals.bet');

  const isLaptop = useMediaQuery(480);

  const [getLastWinning, { data }] = useLazyGetLastWiningQuery();

  useEffect(() => {
    getLastWinning(null);
  }, [getLastWinning]);

  const theme = useSelector((state: RootState) => state.app.theme);

  const { blue800, primary } = useColor();
  const isDarkTheme = theme === 'dark';

  if (!data) {
    return null;
  }

  const filteredWinners = data?.filter((winner: any) => winner.finalValue > 0) || [];

  if (filteredWinners.length === 0) return null;

  return (
    <div className={s.wrapper}>
      <div className={s.title}>{lastWinners}</div>
      <div style={{ padding: '0px 2px', position: 'relative', overflow: 'hidden' }}>
        <SwiperSlider
          variant={'lgg'}
          loop={false}
          theme={theme}
          hideNavigation
          slidesPerView={isLaptop ? 1 : 'auto'}>
          {filteredWinners.map((winner: any) => (
            <div className={s.card}>
              <div className={s.cardTop}>
                <div className={s.avatar}>
                  <CircleIcon size="60" stopColor={isDarkTheme ? blue800 : primary} />
                </div>
                <div>
                  <div className={s.address}>
                    {formatAddress(winner.clientAddress)}{' '}
                    <div className={s.tooltipWrapper}>
                      <img src={tooltip} className={s.tooltipIcon} />
                      <div className={s.tooltip}>
                        <div>
                          <span>{bet}:</span> <span>{winner.joinAmount}$</span>
                        </div>
                        <div>
                          <span>{dateTime}:</span> <span>{formatDate(winner.date)}</span>
                        </div>
                        <div>
                          <span>Txid:</span>{' '}
                          <span style={{ display: 'flex', alignItems: 'center' }}>
                            {formatAddress(winner.joinHash)}{' '}
                            <img
                              src={copy}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `https://bscscan.com/tx/${winner.joinHash}`,
                                );
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={s.sum}>
                    {winner.wonAmount?.toFixed(2)} <b>USDT</b>
                  </div>
                  <div className={s.grey}>{wonAmount}</div>
                </div>
              </div>
              <div className={s.cardBottom}>
                <div style={{ marginRight: 22 }}>
                  <div className={s.row}>
                    <CurrencyPair
                      small
                      iconLeft={<img alt="" src={event?.iconBase64} width={13} />}
                      iconRight={<TetherIcon size="13" />}
                      theme={theme}
                    />
                    <span className={s.green}>${winner.targetValue}</span>
                  </div>
                  <div className={s.grey}>{predictedRate}</div>
                </div>
                <div>
                  <div className={s.row}>
                    <svg
                      width="13"
                      height="14"
                      viewBox="0 0 13 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.50132 2.9614L10.2549 4.97021V9.00758L6.51191 11.0287L2.76095 9.02485V4.98995L6.50132 2.9614ZM6.50132 0L5.12482 0.740349L1.3765 2.76891L0 3.50925V10.5105L1.3765 11.2508L5.13012 13.2597L6.50662 14L7.88312 13.2597L11.6235 11.236L13 10.4957V3.48458L11.6235 2.74423L7.87253 0.740349L6.49603 0H6.50132Z"
                        fill="#7997FA"
                      />
                    </svg>

                    <span className={s.white}>${winner.finalValue}</span>
                  </div>
                  <div className={s.grey}>{finalRate}</div>
                </div>
              </div>
            </div>
          ))}
        </SwiperSlider>
      </div>
    </div>
  );
};

export default memo(Winners);
