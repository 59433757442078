import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApi, baseApiPriceFeed } from 'store/consts';

export const API_NAME = 'chainLinkService';

export const chainLinkService = createApi({
  reducerPath: API_NAME,
  baseQuery: fetchBaseQuery({ baseUrl: `${baseApiPriceFeed}/` }),

  endpoints: (builder) => ({
    getHistoricalPrices: builder.query({
      query: ({ eventId, page, frame, size }) => ({
        url: `historical-prices?eventId=${eventId}&sort=created,desc&page=${page || 0}&frame=${
          frame || 'PT1M'
        }&size=${size || 20}`,
      }),
    }),
  }),
});

export const { useLazyGetHistoricalPricesQuery } = chainLinkService;
