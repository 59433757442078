import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { AppLangs, ThemeType } from 'types/baseTypes';
import { NotifyType } from 'types/notifyType';

interface IAppState {
  loading: boolean;
  logout: boolean;
  theme: ThemeType;
  cookieNotificationShown: boolean;
  isOpenSideBar: boolean;
  rates: any;
  lastHide: number;
  now: number;
  viewedBets: number[];
  viewedStories: number[];
  lang: AppLangs;
  portfolio: {
    deals: number;
    transactions: number;
  };
}

const themeInStorage = localStorage.getItem('theme');
const defaultLang = <AppLangs>localStorage.getItem('lang') ?? AppLangs.en;

let initialState: IAppState = {
  loading: true,
  logout: false,
  lastHide: Date.now(),
  theme: themeInStorage === 'default' ? 'default' : 'dark',
  isOpenSideBar: window.innerWidth >= 1024,
  rates: {},
  cookieNotificationShown: false,
  now: Date.now(),
  viewedBets: [],
  viewedStories: [],
  lang: defaultLang,
  portfolio: {
    deals: 0,
    transactions: 0,
  },
};

const viewedBets = localStorage.getItem('viewedBets');

if (viewedBets) {
  try {
    initialState.viewedBets = JSON.parse(viewedBets);
  } catch (e) {
    console.error('viewedBets parse error');


  }
}

const viewedStories = localStorage.getItem('viewedStories');

if (viewedStories) {
  try {
    initialState.viewedStories = JSON.parse(viewedStories);
  } catch (e) {

    console.error('viewedStories parse error');

  }
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setLastHide(state, action) {
      state.lastHide = action.payload;
    },
    setCookieNotificationShown(state, action) {
      state.cookieNotificationShown = action.payload;
    },
    changeTheme(state, action) {
      localStorage.setItem('theme', action.payload);
      state.theme = action.payload;
    },
    setOpenSideBar(state, action) {
      state.isOpenSideBar = action.payload;
    },
    setRates(state, action) {
      state.rates = action.payload;
    },
    setNow(state, action) {
      state.now = action.payload;
    },
    setLogout(state, action) {
      state.logout = action.payload;
    },
    changeLang(state, action) {
      state.lang = action.payload;
      localStorage.setItem('lang', action.payload);
    },
    addViewedBet(state, action) {
      let newViewedBets = state.viewedBets.filter((id) => id !== action.payload);
      newViewedBets.unshift(action.payload);
      localStorage.setItem('viewedBets', JSON.stringify(newViewedBets));
      state.viewedBets = newViewedBets;
    },
    addViewedStory(state, action) {
      let newViewedStories = state.viewedStories.filter((id) => id !== action.payload);
      newViewedStories.unshift(action.payload);
      localStorage.setItem('viewedStories', JSON.stringify(newViewedStories));
      state.viewedStories = newViewedStories;
    },

    setPortfolio(state, action: PayloadAction<{ deals?: number; transactions?: number }>) {
      if (action.payload.deals)
        state.portfolio.deals = action.payload.deals;
      if (action.payload.transactions)
        state.portfolio.transactions = action.payload.transactions;
    },
  },
});

export const {
  addViewedBet,
  setLastHide,
  addViewedStory,
  setLoading,
  changeTheme,
  setOpenSideBar,
  setLogout,
  setRates,
  setNow,
  changeLang,
  setPortfolio,
  setCookieNotificationShown,
} = appSlice.actions;

export default appSlice.reducer;
