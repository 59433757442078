import React from 'react';

import { AssetsType } from 'types/baseTypes';
import { BadgeVariant } from 'types/typings';

import { AvaxIcon, BtcIcon, MaticIcon, TetherIcon, XrpIcon } from '../components/Icons/Crypto';

export const tableMocTabFirstTab = [
  ['0x32f1518....d67ac', '10.000 USDT', '1 мая, 22:15'],
  ['0x32f1518....d67ac', '10.000 USDT', '2 мая, 22:15'],
  ['0x32f1518....d67ac', '10.000 USDT', '3 мая, 22:15'],
  ['0x32f1518....d67ac', '10.000 USDT', '4 мая, 22:15'],
  ['0x32f1518....d67ac', '10.000 USDT', '5 мая, 22:15'],
  ['0x32f1518....d67ac', '10.000 USDT', '6 мая, 22:15'],
  ['0x32f1518....d67ac', '10.000 USDT', '7 мая, 22:15'],
  ['0x32f1518....d67ac', '10.000 USDT', '8 мая, 22:15'],
  ['0x32f1518....d67ac', '10.000 USDT', '9 мая, 22:15'],
];

export const tableMocTabSecondTab = [
  ['0x32f1518....d67ac', '99.999 USDT', '1 мая, 22:15'],
  ['0x32f1518....d67ac', '99.999 USDT', '2 мая, 22:15'],
  ['0x32f1518....d67ac', '99.999 USDT', '3 мая, 22:15'],
  ['0x32f1518....d67ac', '99.999 USDT', '4 мая, 22:15'],
  ['0x32f1518....d67ac', '99.999 USDT', '5 мая, 22:15'],
  ['0x32f1518....d67ac', '99.999 USDT', '6 мая, 22:15'],
  ['0x32f1518....d67ac', '99.999 USDT', '7 мая, 22:15'],
  ['0x32f1518....d67ac', '99.999 USDT', '8 мая, 22:15'],
  ['0x32f1518....d67ac', '99.999 USDT', '9 мая, 22:15'],
];

export const optionArray = [
  { id: 1, label: 'Категория', value: 'Финансовые ставки' },
  { id: 2, label: 'Валютная пара', value: 'XRP · USDT' },
  { id: 3, label: 'Исход сделки', value: 'Выше 10 USDT' },
  { id: 4, label: 'Время события', value: '18:00, 31.05.2022' },
  { id: 5, label: 'Конец приема ставок', value: '12:00, 15.12.2022' },
  { id: 6, label: 'Ваша ставка', value: '1000USDT' },
  { id: 7, label: 'Коэффициент', value: '2X' },
];

export const currencyPairsArray: Array<AssetsType> = [
  { id: '1', name: 'XRP', currency: 'USDT', mainIcon: <XrpIcon />, secondaryIcon: <TetherIcon /> },
  { id: '2', name: 'BTC', currency: 'USDT', mainIcon: <BtcIcon />, secondaryIcon: <TetherIcon /> },
  {
    id: '3',
    name: 'AVAX',
    currency: 'USDT',
    mainIcon: <AvaxIcon />,
    secondaryIcon: <TetherIcon />,
  },
  {
    id: '4',
    name: 'MATIC',
    currency: 'USDT',
    mainIcon: <MaticIcon />,
    secondaryIcon: <TetherIcon />,
  },
  { id: '5', name: 'XRP', currency: 'USDT', mainIcon: <XrpIcon />, secondaryIcon: <TetherIcon /> },
  { id: '6', name: 'BTC', currency: 'USDT', mainIcon: <BtcIcon />, secondaryIcon: <TetherIcon /> },
  {
    id: '7',
    name: 'AVAX',
    currency: 'USDT',
    mainIcon: <AvaxIcon />,
    secondaryIcon: <TetherIcon />,
  },
  {
    id: '8',
    name: 'MATIC',
    currency: 'USDT',
    mainIcon: <MaticIcon />,
    secondaryIcon: <TetherIcon />,
  },
  { id: '9', name: 'XRP', currency: 'USDT', mainIcon: <XrpIcon />, secondaryIcon: <TetherIcon /> },
  { id: '10', name: 'BTC', currency: 'USDT', mainIcon: <BtcIcon />, secondaryIcon: <TetherIcon /> },
  {
    id: '11',
    name: 'AVAX',
    currency: 'USDT',
    mainIcon: <AvaxIcon />,
    secondaryIcon: <TetherIcon />,
  },
  {
    id: '12',
    name: 'MATIC',
    currency: 'USDT',
    mainIcon: <MaticIcon />,
    secondaryIcon: <TetherIcon />,
  },
];

export const str =
  'Это рынок того, установится ли цена BTC выше 20.000 USDT 31 мая 2022 года в 18:00 по восточноевропейскому времени. Рынок примет решение «ДА», если источник разрешения сообщит о сумме, превышающей 20.000 USDT Это рынок того, установится ли цена BTC выше 20.000 USDT 31 мая 2022 года в 18:00 по восточноевропейскому времени. Рынок примет решение «ДА», если источник разрешения сообщит о сумме, превышающей 20.000 USDT Это рынок того, установится ли цена BTC выше 20.000 USDT 31 мая 2022 года в 18:00 по восточноевропейскому времени. Рынок примет решение «ДА», если источник разрешения сообщит о сумме, превышающей 20.000 USDT Это рынок того, установится ли цена BTC выше 20.000 USDT 31 мая 2022 года в 18:00 по восточноевропейскому времени. Рынок примет решение «ДА», если источник разрешения сообщит о сумме, превышающей 20.000 USDT';

export const options = [
  { value: `0.7`, label: 'Списать со ставкой', customAbbreviation: `0.7`, currency: 'USDT' },
  { value: `0.223`, label: 'Оплатить в Betcoin', customAbbreviation: `0.223`, currency: 'BET' },
];

/* Array for New Card */
export const newCardArray = [
  {
    id: 1,
    iconLeft: <BtcIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена BTC выше $20 000?',
    subTitle: 'в 16:30, 3 июля 2022',
    money: '$25.000',
    bet: '127 ставок',
    badgeTitle: 'Новая',
    badgeVariant: 'new' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 2,
    iconLeft: <AvaxIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена AVAX выше $10?',
    subTitle: 'в 12:00, 1 августа 2022',
    money: '$128.350',
    bet: '256 ставок',
    badgeTitle: 'Популярная',
    badgeVariant: 'popular' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 3,
    iconLeft: <MaticIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена MATIC выше $100?',
    subTitle: 'в 16:30, 3 июля 2022',
    money: '$15.500',
    bet: '84 ставки',
    badgeTitle: 'Приватная',
    badgeVariant: 'private' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 4,
    iconLeft: <XrpIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена XRP выше $1? ',
    subTitle: 'в 12:00, 1 сентября 2022',
    money: '$350',
    bet: '7 ставок',
    badgeTitle: '',
    badgeVariant: '' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 5,
    iconLeft: <XrpIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена XRP выше $1? ',
    subTitle: 'в 12:00, 1 сентября 2022',
    money: '$350',
    bet: '7 ставок',
    badgeTitle: '',
    badgeVariant: '' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 6,
    iconLeft: <BtcIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена BTC выше $20 000?',
    subTitle: 'в 16:30, 3 июля 2022',
    money: '$25.000',
    bet: '127 ставок',
    badgeTitle: '',
    badgeVariant: '' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 7,
    iconLeft: <AvaxIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена AVAX выше $10?',
    subTitle: 'в 12:00, 1 августа 2022',
    money: '$128.350',
    bet: '256 ставок',
    badgeTitle: '',
    badgeVariant: '' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 8,
    iconLeft: <MaticIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена MATIC выше $100?',
    subTitle: 'в 16:30, 3 июля 2022',
    money: '$15.500',
    bet: '84 ставки',
    badgeTitle: '',
    badgeVariant: '' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 9,
    iconLeft: <BtcIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена BTC выше $20 000?',
    subTitle: 'в 16:30, 3 июля 2022',
    money: '$25.000',
    bet: '127 ставок',
    badgeTitle: '',
    badgeVariant: '' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 10,
    iconLeft: <AvaxIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена AVAX выше $10?',
    subTitle: 'в 12:00, 1 августа 2022',
    money: '$128.350',
    bet: '256 ставок',
    badgeTitle: '',
    badgeVariant: '' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 11,
    iconLeft: <MaticIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена MATIC выше $100?',
    subTitle: 'в 16:30, 3 июля 2022',
    money: '$15.500',
    bet: '84 ставки',
    badgeTitle: '',
    badgeVariant: '' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 12,
    iconLeft: <XrpIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена XRP выше $1? ',
    subTitle: 'в 12:00, 1 сентября 2022',
    money: '$350',
    bet: '7 ставок',
    badgeTitle: '',
    badgeVariant: '' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 13,
    iconLeft: <BtcIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена BTC выше $20 000?',
    subTitle: 'в 16:30, 3 июля 2022',
    money: '$25.000',
    bet: '127 ставок',
    badgeTitle: '',
    badgeVariant: '' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 14,
    iconLeft: <AvaxIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена AVAX выше $10?',
    subTitle: 'в 12:00, 1 августа 2022',
    money: '$128.350',
    bet: '256 ставок',
    badgeTitle: '',
    badgeVariant: '' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 15,
    iconLeft: <MaticIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена MATIC выше $100?',
    subTitle: 'в 16:30, 3 июля 2022',
    money: '$15.500',
    bet: '84 ставки',
    badgeTitle: '',
    badgeVariant: '' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
  {
    id: 16,
    iconLeft: <XrpIcon />,
    iconRight: <TetherIcon />,
    title: 'Будет ли цена XRP выше $1? ',
    subTitle: 'в 12:00, 1 сентября 2022',
    money: '$350',
    bet: '7 ставок',
    badgeTitle: '',
    badgeVariant: '' as BadgeVariant,
    cardTooltipDate: 'до 17:30, 18 мая 2022',
    cardTooltipTitle: 'Ставки принимаются',
    disabled: false,
  },
];
