import { useContext, useRef, useState } from 'react';

import { CryptoContext } from 'context';
import { ethers } from 'ethers';
import useWrapAccount from 'hooks/useWrapAccount';

import { hooks } from 'metamask/connector';
import { CUSTOM_ADDRESS } from 'metamask/constants';

const { useProvider, useAccount } = hooks;

const useCustom = () => {
  const { state, dispatch } = useContext(CryptoContext);
  const [isLoading, setIsLoading] = useState(false);
  const prizeTakenBets = useRef<number[]>([]);

  const { custom_company_fee, custom_allowance, token_contract, custom_contract } = state;
  const provider = useProvider();
  const account = useWrapAccount();

  const getPrize = async (betId: number) => {
    setIsLoading(true);

    try {
      await custom_contract
        // @ts-ignore
        ?.takeCustomPrize(betId, account, false);
      prizeTakenBets.current.push(betId);
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const newBet = async ({
                          eventId,
                          targetSide,
                          targetValue,
                          lockTime,
                          expTime,
                          bet,
                          betSide,
                          coefficient,
                          hidden,
                        }: any) => {
    setIsLoading(true);
    try {
      const finalAmount = Number(bet);

      const diff = custom_allowance - finalAmount;

      if (diff < 0) {
        const approveTransaction = await token_contract
          // @ts-ignore
          ?.approve(
            CUSTOM_ADDRESS,
            (Number(Math.abs(finalAmount)) * 10 ** 18).toLocaleString('fullwide', {
              useGrouping: false,
            }),
          );
        await provider?.waitForTransaction(approveTransaction.hash);
      }

      const _allowance = await token_contract
        // @ts-ignore
        ?.allowance(account, CUSTOM_ADDRESS);

      if (parseFloat(ethers.utils.formatEther(_allowance)) >= finalAmount) {
        console.log(Number(coefficient) * 10 ** 9);
        const customBetTransaction = await custom_contract
          // @ts-ignore
          ?.createCustomBet(
            [
              eventId,
              hidden,
              lockTime,
              expTime,
              targetValue,
              targetSide === 'increase',
              Number(coefficient) * 10 ** 9,
            ],
            [betSide, (Number(bet) * 10 ** 18).toLocaleString('fullwide', { useGrouping: false })],
          );

        await provider?.waitForTransaction(customBetTransaction.hash);
      }
    } catch (e) {
    }
    setIsLoading(false);
  };

  const remove = async (betId: number, joinId: number) => {
    // @ts-ignore
    await custom_contract?.cancelCustomJoin(betId, joinId);
  };

  const join = async (betId: any, bet: any, side: any) => {
    setIsLoading(true);
    try {
      const finalAmount = Number(bet);

      const diff = custom_allowance - finalAmount;

      if (diff < 0) {
        const approveTransaction = await token_contract
          // @ts-ignore
          ?.approve(
            CUSTOM_ADDRESS,
            (Number(Math.abs(finalAmount)) * 10 ** 18).toLocaleString('fullwide', {
              useGrouping: false,
            }),
          );
        await provider?.waitForTransaction(approveTransaction.hash);
      }

      const joinRequest = [
        side,
        (Number(bet) * 10 ** 18).toLocaleString('fullwide', { useGrouping: false }),
      ];

      const joinTransaction = await custom_contract
        // @ts-ignore
        ?.joinCustomBet(betId, joinRequest);

      await provider?.waitForTransaction(joinTransaction.hash);
    } catch (e) {
      console.log(e);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return {
    prizeTakenBets: prizeTakenBets.current,
    newBet,
    join,
    isLoading,
    getPrize,
    remove,
  };
};

export default useCustom;
