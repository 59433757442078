import { FC, useState } from 'react';
import { ThemeType } from 'types/baseTypes';

import s from './style.module.scss';

import { WalletDropDown } from './components/walletDropDown';
import { WalletModalDownload } from './components/walletModalDownload';

interface IWeb3ConnectWalletDesktop {
  theme: ThemeType,
  handlerDropDown: () => void
}

export const Web3ConnectWalletDesktop: FC<IWeb3ConnectWalletDesktop> = (props) => {
  const {
    theme = 'default',
    handlerDropDown,
  } = props;

  const [
    openModalWalletDownload,
    setOpenModalWalletDownload,
  ] = useState<boolean>(false);

  const handleOpenModalWalletDownload = () => setOpenModalWalletDownload((prevState) => !prevState);

  return (
    <div className={s[theme]}>
      <WalletDropDown
        theme={theme}
        handleOpenModalWalletDownload={handleOpenModalWalletDownload}
        isOpen={openModalWalletDownload}
        closeDropDown={handlerDropDown}
      />

      {
        openModalWalletDownload
        &&
        <WalletModalDownload
          theme={theme}
          openModal={openModalWalletDownload}
          setOpen={handleOpenModalWalletDownload}
        />
      }
    </div>
  );
};
