import { configureStore } from '@reduxjs/toolkit';

import { services, servicesMiddleware } from './services';
import appReducer from './slices/appSlice';
import wallet from './web3/wallet';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
  reducer: {
    ...services,
    app: appReducer,
    wallet,
  },
  middleware: (getDefaultMiddleware) => {
    const additionalMiddleware = [...servicesMiddleware];

    return getDefaultMiddleware({ serializableCheck: false }).concat(additionalMiddleware);
  },
});
