import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { getDay } from 'date-fns';
import { useAppDispatch } from 'hooks/store';
import useBinary from 'hooks/useBinary';
import { useClientJoins } from 'hooks/useClientJoins';
import useCustom from 'hooks/useCustom';
import useJackpot from 'hooks/useJackpot';
import useLottery from 'hooks/useLottery';
import useTranslate from 'hooks/useTranslate.hook';
import useWrapAccount from 'hooks/useWrapAccount';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  BetType,
  useClientInfoQuery,
  useLazyJoinsQuery,
  useLazyTransactionsQuery,
} from 'store/services/clientService';
import { useEventsListQuery } from 'store/services/eventsService';
import { setPortfolio } from 'store/slices/appSlice';

import Translated from 'components/Containers/Translated';
import { ChevronIcon, TetherIcon } from 'components/Icons';
import Button from 'components/Simple/Button';
import { Loader } from 'components/Simple/Loader/Loader';
import MyDealCard from 'components/Simple/MyDealCard';
import { FilterContainer } from 'components/Simple/MyProfile/Filter/FilterButtons';
import Pagination from 'components/Simple/Pagination';
import Typography from 'components/Simple/Typography';
import { formatDate } from 'helpers/formatDate';
import getEventById from 'helpers/getEventById';
import { AppLangs, ThemeType } from 'types/baseTypes';

import { CryptoContext } from '../../context';
import s from './portfolio.module.scss';

interface PortfolioPageContentProps {
  theme?: ThemeType;
  isLaptop?: boolean;
  handleGetReportModal?: (item: any) => void;
  onClick?: () => void;
}

const PortfolioPageLeftBlock = ({
  theme = 'default',
  isLaptop,
  onClick,
  handleGetReportModal,
}: PortfolioPageContentProps) => {
  const dispatch = useAppDispatch();
  const { getPrize: getCustomPrize, prizeTakenBets: prizeTakenCustomBets } = useCustom();
  const { getPrize: getLotteryPrize, prizeTakenBets: prizeTakenLotteryBets } = useLottery();
  const {
    getPrize: getJackpotPrize,
    prizeTakenBets: prizeTakenJackpotBets,
    jackpot_company_fee,
  } = useJackpot();
  const {
    getPrize: getBinaryPrize,
    prizeTakenBets: prizeTakenBinaryBets,
    refundBinaryBet,
  } = useBinary();
  const [page, setPage] = useState(0);
  const [getJoins, { data: joins, isLoading: isJoinsLoading }] = useLazyJoinsQuery();

  const [getData, { data: transactions, isLoading: isTransactionsLoading }] =
    useLazyTransactionsQuery();
  const { data: events } = useEventsListQuery(null);
  const account = useWrapAccount();
  const lang = useSelector((state: RootState) => state.app.lang);
  const [tab, setTab] = useState<BetType | null>(BetType.Jackpot);
  useEffect(() => {
    if (account) {
      getJoins({
        address: account.toLowerCase(),
        type: tab,
      });

      getData({ account });
    }
  }, [account, tab, getJoins]);

  useEffect(() => {
    if (joins) dispatch(setPortfolio({ deals: joins.totalElements }));
  }, [joins]);

  useEffect(() => {
    if (transactions) dispatch(setPortfolio({ transactions: transactions.totalElements }));
  }, [transactions]);

  const win = useTranslate('portfolio.win');
  const betamount = useTranslate('portfolio.bet-amount');
  const higher = useTranslate('states.higher').toLowerCase();
  const lower = useTranslate('states.lower').toLowerCase();
  const all = useTranslate('app.view-all');

  return (
    <div className={s.leftBlock}>
      <div className={classNames(s.content, s[`content-${theme}`])}>
        {/*<FilterContainer*/}
        {/*  setTab={(_tab) => {*/}
        {/*    if (_tab !== tab) {*/}
        {/*      setPage(0);*/}
        {/*      setTab(_tab);*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  tab={tab}*/}
        {/*/>*/}
        {joins?.result.map((item: any) => {
          const event = getEventById(item.betInfo.eventId, events?.result);
          let prizeTaken = item.joinStatus === 'PRIZE_TAKEN';

          if (!prizeTaken) {
            if (item.betType === 'CUSTOM') {
              prizeTaken = prizeTakenCustomBets.indexOf(item.betId) > -1;
            } else if (item.betType === 'JACKPOT') {
              prizeTaken = prizeTakenJackpotBets.indexOf(item.betId) > -1;
            } else if (item.betType === 'BINARY') {
              prizeTaken = prizeTakenBinaryBets.indexOf(item.betId) > -1;
            } else if (item.betType === 'AUCTION') {
              prizeTaken = prizeTakenLotteryBets.indexOf(item.betId) > -1;
            }
          }

          let title;
          if (lang === AppLangs.ru) {
            title = item.betInfo.customBetAdditionInfo
              ? `Будет ли цена ${event?.name} ${
                  item.betInfo.customBetAdditionInfo?.targetSide ? higher : lower
                } $${item.betInfo.customBetAdditionInfo?.targetValue}?`
              : 'Спрогнозируйте рост или падение курса BTC';
          } else {
            title = item.betInfo.customBetAdditionInfo
              ? `Will the ${event?.name} price be ${
                  item.betInfo.customBetAdditionInfo?.targetSide ? higher : lower
                } than ${item.betInfo.customBetAdditionInfo?.targetValue}$?`
              : 'Predict increase or decrease of the BTC rate';
          }

          let joinAmount = item.totalJoinAmount;

          if (item.betType === 'JACKPOT') {
            joinAmount = item.totalJoinAmount * ((100 + jackpot_company_fee) / 100);
          }

          return (
            <MyDealCard
              key={item.betId}
              choose={`${lang === AppLangs.ru ? 'Будет' : 'Will'} ${
                item.betInfo.customBetAdditionInfo?.targetSide ? higher : lower
              }`}
              item={item}
              handleGetReportModal={handleGetReportModal}
              finalValue={item.betInfo?.finalValue}
              iconIn={<img alt={''} width={25} src={event?.iconBase64} />}
              iconOut={<TetherIcon />}
              disableBtn={
                item.betInfo?.status === 'SKIPPED_CLOSING'
                  ? item.joinStatus !== 'JOINED'
                  : prizeTaken || item.amountTaken >= item.expectedWonAmount
              }
              onClick={() => {
                if (item.betType === 'CUSTOM') {
                  getCustomPrize(item.betId);
                } else if (item.betType === 'JACKPOT') {
                  getJackpotPrize(item.betId);
                } else if (item.betType === 'BINARY') {
                  if (item.betInfo?.status === 'SKIPPED_CLOSING') {
                    refundBinaryBet(item.betId);
                  } else {
                    getBinaryPrize(item.betId);
                  }
                } else if (item.betType === 'AUCTION') {
                  getLotteryPrize(item.betId);
                }
              }}
              prize={item.expectedWonAmount?.toFixed(2)}
              sumBets={`${joinAmount?.toFixed(2)} USDT`}
              theme={theme}
              timeTitle={formatDate(item.betInfo.expirationDate)}
              title={title}
              reloadPage={() => getData({ account })}
            />
          );
        })}
        {isJoinsLoading && (
          <div className={s.empty}>
            <Typography variant="h1">
              <Loader />
            </Typography>
          </div>
        )}

        {joins?.totalPages > 1 && (
          <Pagination
            onPageChange={({ selected }) => {
              getJoins({
                address: account?.toLowerCase(),
                type: tab,
                page: selected,
              });
              setPage(selected);
            }}
            page={page}
            totalPages={joins?.totalPages || 0}
          />
        )}

        {!joins?.totalElements && !isJoinsLoading && (
          <div className={s.empty}>
            <Typography variant="h1">No Deals</Typography>
          </div>
        )}
      </div>
    </div>
  );
};
export default PortfolioPageLeftBlock;
