import React, { ReactNode, useId } from 'react';

import classNames from 'classnames';
import useTranslate from 'hooks/useTranslate.hook';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import LeaderIcon from 'components/Icons/LeaderIcon';
import { AppLangs, ThemeType } from 'types/baseTypes';

import useColor from '../../../../theme/useColor';
import { CircleIcon, CrumbsIcon } from '../../../Icons/index';
import Button from '../../../Simple/Button';
import Label from '../../../Simple/Label';
import Menu from '../../Menu';
import s from './TableRow.module.scss';

interface TableRowProps {
  namesTd: (string | ReactNode)[];
  menu?: boolean;
  theme?: ThemeType;
  isOwner?: boolean;
  free?: any;
  total?: any;
  isLeader?: boolean;
  handleClick?: () => Promise<void>;
  canDeleteBet?: boolean;
}

type ArrType = (string | React.ReactNode | JSX.Element)[];

const TableRow = ({
                    namesTd,
                    menu,
                    theme = 'default',
                    isOwner,
                    isLeader,
                    free,
                    total,
                    handleClick,
                    canDeleteBet,
                  }: TableRowProps) => {
  const isDarkTheme = theme === 'dark';
  const { white, dark, blue800, primary } = useColor();
  const id = useId();
  // const handleClick = () => console.log('delete this deal111');
  const lang = useSelector((state: RootState) => state.app.lang);

  const getUnclaimed = useTranslate('my-deals.get-unclaimed');

  let newArr: ArrType = [];
  if (menu) {
    newArr = [
      ...namesTd,
      canDeleteBet ? (
        <Menu
          anchor='left'
          noMobile
          button={
            <Button iconButton>
              <CrumbsIcon className={s.iconCrumbs} color={isDarkTheme ? white : dark} />
            </Button>
          }
          className={classNames(s[`menuTableRow-${theme}`])}
          theme={theme}>
          {free < total ? (
            <Label
              theme={theme}
              title={
                <div className={s.removeDeal} onClick={handleClick}>
                  {getUnclaimed}
                </div>
              }
            />
          ) : (
            <Label
              theme={theme}
              title={
                <div className={s.removeDeal} onClick={handleClick}>
                  {lang === AppLangs.ru ? 'Удалить ставку' : 'Delete bet'}
                </div>
              }
            />
          )}
        </Menu>
      ) : null,
    ];
  }

  return (
    <tr>
      {(menu ? (newArr as []) : (namesTd as [])).map((nameTd, index) => (
        <td key={`${nameTd}${id}`}>
          <div style={index === 0 ? { display: 'flex', alignItems: 'center' } : {}}>
            {nameTd}
            {isLeader && index === 0 && (
              <div style={{ marginLeft: 10 }}>
                <LeaderIcon />
              </div>
            )}
            {isOwner && index === 0 && (
              <div className={s[`circleIcon-${theme}`]}>
                <CircleIcon stopColor={isDarkTheme ? blue800 : primary} size='15' />
              </div>
            )}
          </div>
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
