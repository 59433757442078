import { useEffect } from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';
import { Children } from 'types/children';

import { CloseIcon } from '../../Icons';
import s from './Modal.module.scss';

interface BottomSheetProps {
  theme?: ThemeType;
  open: boolean;
  setOpen: () => void;
  className?: string;
  children?: Children;
}

const Modal = ({ theme = 'default', open, setOpen, className, children }: BottomSheetProps) => {
  const root: any = document.querySelector('#root');

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      root.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
      root.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
      root.style.overflow = '';
    };
  }, [open]);

  return (
    <div className={s[theme]}>
      <div className={s.overlay} />

      <div className={classNames(s.content, className)}>
        <div className={s.closeBtn} onClick={setOpen}>
          <CloseIcon />
        </div>

        <div className={s.swipe} />
        {children}
      </div>
    </div>
  );
};

export default Modal;
