import React from 'react';
import { IconsTypes } from 'types/baseTypes';

const LoadingCircleIcon = ({ height = '20', width = '19', color = '#101319', className }: IconsTypes) => (
  <svg className={className} fill='none' height={height} viewBox='0 0 19 20' width={width}
       xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.8346 9.99935C17.8346 14.6017 14.1037 18.3327 9.5013 18.3327M1.16797 9.99935C1.16797 5.39698 4.89893 1.66602 9.5013 1.66602'
      stroke='#898A8E' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
  </svg>

);

export default LoadingCircleIcon;
