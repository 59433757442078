const chars = [
  '2',
  'n',
  'v',
  'u',
  '9',
  'm',
  'h',
  'k',
  's',
  'p',
  'c',
  '3',
  'r',
  'o',
  'z',
  'a',
  'w',
  'b',
  'y',
  '7',
  'g',
  '5',
  'q',
  'x',
  'l',
  'i',
  't',
  '8',
  '6',
  'j',
  '1',
  'e',
  'f',
  'd',
  '4',
];
const defaultArray = [20, 2, 17, 34, 3, 8, 12, 14];

export const encode = (value: number) => {
  const rounds = Math.floor(value / defaultArray[0]);

  const newArray = defaultArray.map((item) => {
    const index = item - Math.abs(value % item);
    return chars[index];
  });

  return newArray.join('') + rounds.toString(16);
};

export const decode = (value: string) => {
  const array = value.split('');
  const rounds = parseInt(array.slice(8).join(''), 16);
  array.pop();

  const newArray = array.map((item) => {
    return chars.findIndex((c) => c === item);
  });

  const v = newArray[0];
  const v2 = defaultArray[0] * rounds - v;
  return v2 + defaultArray[0];
};
