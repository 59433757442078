import { useContext, useRef, useState } from 'react';

import { CryptoContext } from 'context';
import useWrapAccount from 'hooks/useWrapAccount';

import { ceil } from 'helpers/decimalsAdjust';
import { hooks } from 'metamask/connector';
import { JACKPOT_ADDRESS } from 'metamask/constants';

const { useProvider, useAccount } = hooks;

const useJackpot = () => {
  const { state } = useContext(CryptoContext);
  const [isLoading, setIsLoading] = useState(false);
  const prizeTakenBets = useRef<number[]>([]);

  const { jackpot_company_fee, jackpot_allowance, token_contract, jackpot_contract } = state;

  const provider = useProvider();
  const account = useWrapAccount();

  const getPrize = async (betId: number) => {
    setIsLoading(true);

    try {
      await jackpot_contract
        // @ts-ignore
        ?.takeJackpotPrize(betId, account);
      prizeTakenBets.current.push(betId);
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const join = async (betId: any, requestAmount: any, bets: any[], button: any) => {
    setIsLoading(true);
    try {
      const finalAmount = ceil(Number(bets.length * requestAmount * button) * ((100 + jackpot_company_fee) / 100), 2);

      const diff = jackpot_allowance - finalAmount;

      if (diff < 0) {
        const approveTransaction = await token_contract
          // @ts-ignore
          ?.approve(
            JACKPOT_ADDRESS,
            (Number(Math.abs(finalAmount)) * 10 ** 18).toLocaleString('fullwide', {
              useGrouping: false,
            }),
          );
        await provider?.waitForTransaction(approveTransaction.hash);
      }

      let joinTransaction;

      if (button === 1 && bets.length === 1) {
        joinTransaction = await jackpot_contract
          // @ts-ignore
          ?.joinJackpotBet([betId, false, Number(bets[0]).toFixed(2)]);
      } else {
        let values = [];
        for (let i = 0; i < bets.length; i++) {
          const value = parseFloat(parseFloat(bets[i]).toFixed(1));

          for (let j = 0; j < button; j++) {
            values.push((value + 0.01 * j).toFixed(2));
          }
        }

        joinTransaction = await jackpot_contract
          // @ts-ignore
          ?.massJoinJackpotBet([false, betId, values]);
      }

      await provider?.waitForTransaction(joinTransaction.hash);
    } catch (e) {
      console.log(e);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return {
    jackpot_company_fee,
    prizeTakenBets: prizeTakenBets.current,
    join,
    isLoading,
    getPrize,
  };
};

export default useJackpot;
