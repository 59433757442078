import React from 'react';

import { IconsTypes } from 'types/baseTypes';

const LeaderIcon = ({ height = '15', width = '15' }: IconsTypes) => (
  <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_462_10507)'>
      <path
        d='M0.884478 3.14059L2.71137 11.0806C2.74987 11.2606 2.92291 11.4006 3.09595 11.4006L7.49979 11.4005H11.8844C12.0768 11.4005 12.2305 11.2604 12.269 11.0805L14.0959 3.14045C14.1344 2.98044 13.9612 2.84051 13.8267 2.9404L10.4806 5.20058C10.4037 5.26057 10.3075 5.24062 10.2498 5.16054L7.63446 2.1205C7.55759 2.04056 7.44209 2.04056 7.36523 2.1205L4.74985 5.16054C4.69217 5.22053 4.59598 5.24048 4.51912 5.20058L1.17293 2.94054C1.03825 2.84051 0.845892 2.96049 0.884392 3.1406L0.884478 3.14059Z'
        fill='#E9D111'
      />
      <path
        d='M1.5 1.68039C1.5 2.11118 1.16411 2.46039 0.75 2.46039C0.335783 2.46039 0 2.1112 0 1.68039C0 1.24958 0.335759 0.900391 0.75 0.900391C1.16411 0.900391 1.5 1.24958 1.5 1.68039Z'
        fill='#E9D111'
      />
      <path
        d='M8.25 0.78C8.25 1.21079 7.91424 1.56 7.5 1.56C7.08576 1.56 6.75 1.21081 6.75 0.78C6.75 0.349189 7.08576 0 7.5 0C7.91424 0 8.25 0.349189 8.25 0.78Z'
        fill='#E9D111'
      />
      <path
        d='M15 1.69992C15 2.1306 14.6642 2.47992 14.25 2.47992C13.8359 2.47992 13.5 2.13059 13.5 1.69992C13.5 1.26914 13.8359 0.919922 14.25 0.919922C14.6642 0.919922 15 1.26911 15 1.69992Z'
        fill='#E9D111'
      />
      <path
        d='M11.6348 15.0002C11.8463 15.0002 12.1155 14.8203 12.1155 14.6003V12.8004C12.1155 12.5803 11.8463 12.4004 11.6348 12.4004H3.36553C3.15399 12.4004 2.88477 12.5804 2.88477 12.8004V14.6003C2.88477 14.8203 3.15399 15.0002 3.36553 15.0002H11.6348Z'
        fill='#E9D111'
      />
    </g>
    <defs>
      <clipPath id='clip0_462_10507'>
        <rect width='15' height='15' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default LeaderIcon;
