export type Primitive = string | boolean | number;

export type TypographyVariantType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'body1'
  | 'body2'
  | 'title'
  | 'subtitle'
  | 'smallBold'
  | 'small'
  | 'extraSmall'
  | 'ssm';

export type NavigateLinks = {
  id: number;
  link: string;
  text: string;
  type?: string;
};

export type IconsTypes = {
  height?: string;
  width?: string;
  color?: string;
  className?: string;
  size?: string;
};

export type ThemeType = 'default' | 'dark';
export type CryptoIconType = { size?: string };

export type AssetsType = {
  id: string;
  name: string;
  currency: string;
  mainIcon: JSX.Element;
  secondaryIcon: JSX.Element;
};

export type SelectOptionsType = {
  value: string;
  label: string;
  customAbbreviation: string;
  currency: string;
};

export enum AppLangs {
  en = 'en',
  ru = 'ru'
}
