import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';
import { Children } from 'types/children';

import s from './Tooltip.module.scss';

interface TooltipProps {
  theme?: ThemeType;
  position?: 'top' | 'bottom' | 'left' | 'right';
  toolTipText?: string;
  children?: Children;
}

const Tooltip = ({
                   theme = 'default',
                   position = 'top',
                   toolTipText = '',
                   children,
                 }: TooltipProps) => {
  const ref = useRef(null);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    let rect;

    if (ref.current) {
      //@ts-ignore
      rect = ref.current.getBoundingClientRect();
      if (rect.x <= 10) {
        setLeft(Math.abs(rect.x - 20));
      }
    }
  }, [ref?.current]);

  return (
    <div className={classNames(s.tooltip, s[`tooltip-${position}`])}>
      <span
        className={classNames(s.tooltipText, s[theme], s[`tooltipText-${position}`])}
        ref={ref}
        style={left ? { marginLeft: left + 'px' } : {}}>
        {toolTipText}
      </span>
      {children}
    </div>
  );
};

export default Tooltip;
