import { memo, FC, useEffect, useRef, useCallback, MouseEvent } from 'react';
import { ThemeType } from 'types/baseTypes';
import { Children } from 'types/children';
import DropDownDarkIcon from './DropDownIconDark.png';
import DropDownWhiteIcon from './DropDownIconWhite.png';
import s from './DropDown.module.scss';

interface BottomSheetProps {
  theme?: ThemeType;
  children?: Children;
  closeDropDown?: any;
}

const DropDown: FC<BottomSheetProps> = memo((props) => {
  const {
    theme = 'default',
    children,
    closeDropDown,
  } = props;

  const DropDown = useRef<null | HTMLDivElement>(null);

  const mountEvent = useCallback((e: any): void => {
    if (DropDown.current?.contains(e.target)) return;
    else closeDropDown();
  }, []);

  useEffect(() => {
    document.addEventListener('click', mountEvent, true);
    return () => document.removeEventListener('click', mountEvent, true);
  }, []);

  return (
    <div
      className={s[theme]}
      ref={DropDown}
    >
      <img src={theme == 'dark' ? DropDownDarkIcon : DropDownWhiteIcon} className={s.tooltipImage} />
      <div className={s.content}>
        {children}
      </div>
    </div>
  );
});

export default DropDown;
