import React from 'react';
import { ThemeType } from 'types/baseTypes';
import s from './Label.module.scss';

interface LabelProps {
  leftIcon?: string | React.ReactNode;
  title?: string | React.ReactNode;
  rightElement?: string | React.ReactNode;
  nextElement?: boolean;
  className?: string;
  theme?: ThemeType;
}

const Label = ({
                 leftIcon,
                 title = '',
                 rightElement,
                 nextElement = false,
                 className = '',
                 theme = 'default',
               }: LabelProps) => (
  <>
    <div className={`${s.wrapper} ${className}`}>
      {leftIcon ? <div className={s.iconBlock}>
        {leftIcon}
      </div> : null}
      <div className={s.textBlock}>
        {title}
      </div>
      <div className={s.rightBlock}>
        {rightElement}
      </div>
    </div>
    {nextElement ? <div className={`${s.divider} ${s[theme]}`} /> : null}
  </>
);

export default Label;
