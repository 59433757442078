import { FC, memo } from 'react';

import { ThemeType } from 'types/baseTypes';
import { useWallets } from '../../../../../hooks/useWalletsData';

import s from './style.module.scss';

import Modal from 'components/Containers/Modal';
import { CardDesctop } from '../../../../childrens/card-desktop';
import Typography from 'components/Simple/Typography';
import Translated from 'components/Containers/Translated';

interface IWalletModalDownload {
  theme: ThemeType,
  openModal: boolean,
  setOpen: () => void
}

export const WalletModalDownload: FC<IWalletModalDownload> = memo((props) => {
  const {
    theme = 'default',
    openModal,
    setOpen,
  } = props;

  const {
    btnsInstallWallet,
  } = useWallets();

  return (
    <Modal
      theme={theme}
      open={openModal}
      setOpen={setOpen}
    >
      <>
        <div className={s['text']}>
          <Typography variant='title'>
            <Translated id='header.dropdown.download.headline' />
          </Typography>
        </div>

        <div className={s['text']} style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Typography color='grey' variant='small' style={{ lineHeight: '18px' }}>
            <Translated id='header.dropdown.download.text' />
          </Typography>
        </div>

        {
          btnsInstallWallet
            .map((btn: any, index: number) => {
              return (
                <div
                  className={s['btn']}
                  key={index}
                >
                  <CardDesctop
                    click={() => window.open(btn.link)}
                    theme={theme}
                    type={btn.text}
                    icon={btn.icon}
                    install

                    border='install'
                    background='install'
                  />
                </div>
              );
            })
        }
      </>
    </Modal>
  );
});
