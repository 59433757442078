import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import useJackpot from 'hooks/useJackpot';
import useTranslate from 'hooks/useTranslate.hook';
import useWrapAccount from 'hooks/useWrapAccount';

import TextField from 'components/Simple/TextField';
// import RangeSlider from "components/Containers/RangeSlider";
// import { TooltipIcon } from "components/Icons";
// import AccordionSwitcher from "components/Simple/AccordionSwitcher";
// import Button from "components/Simple/Button";
// import SelectComponentWithCommission from "components/Simple/SelectComponentWithCommission";
// import Tooltip from "components/Simple/Tooltip";
// import Typography from "components/Simple/Typography";
import { SelectOptionsType, ThemeType } from 'types/baseTypes';

import { ChevronIcon, CloseIcon, TooltipIcon } from '../../Icons';
import AccordionSwitcher from '../../Simple/AccordionSwitcher';
import Button from '../../Simple/Button';
import Tooltip from '../../Simple/Tooltip';
import Typography from '../../Simple/Typography';
import RangeSlider from '../RangeSlider';
import s from './PlaceYourBet.module.scss';

interface PlaceYourBetProps {
  theme?: ThemeType;
  coefficient?: string;
  maxPrize?: string;
  tooltipCommission?: string;
  tooltipDeal?: string;
  tooltipPrize?: string;
  targetValue?: any;
  className?: string;
  bid?: boolean;
  bets?: any[];
  iconBid?: React.ReactNode;
  inValue?: number;
  currentTitle?: string;
  min?: string | number;
  accordionOff?: boolean;
  labelRangeSlider?: string;
  selectLabel?: string;
  disabled?: boolean;
  tooltipDealLabel?: string;
  tooltipPrizeLabel?: string;
  onClickBet?: () => void;
  buttonGroup?: boolean;
  buttonGroupTitle?: string;
  tooltipButtonGroupTitle?: string;
  selectOptions?: SelectOptionsType[];
  onChangeSelect?: (value: any) => void;
  isLoading?: boolean;
  setSide?: any;
  setBet?: any;
  setBets?: any;
  setJackBets?: any;
  jackBets?: any[];
  left?: number;
  priceForBet?: number;
  buttonAmount?: number;
  bet?: any;
  type?: string;
  button?: number;
  buttonValues?: number[];
  setButton?: (value: number) => void;
}

const regexp = {
  1: /^([0-9]{1,5})(\.?)([0-9]{0,2})$/,
  10: /^([0-9]{1,5})(\.?)([0-9]{0,1})$/,
  100: /^([0-9]{1,5})$/,
};

const PlaceYourBet = ({
                        inValue,
                        currentTitle,
                        left,
                        min,
                        priceForBet,
                        iconBid,
                        setButton,
                        bid,
                        buttonAmount,
                        theme = 'default',
                        coefficient = '',
                        maxPrize = '',
                        bet,
                        tooltipDeal = '',
                        tooltipPrize = '',
                        buttonValues,
                        setBet,
                        button,
                        className,
                        accordionOff = false,
                        labelRangeSlider = 'Ваша ставка',
                        onClickBet,
                        buttonGroup = false,
                        buttonGroupTitle = '',
                        tooltipButtonGroupTitle = 'tooltip',
                        targetValue,
                        bets,
                        setBets,
                        jackBets,
                        setJackBets,
                        setSide,
                        disabled,
                        isLoading,
                        type,
                      }: PlaceYourBetProps) => {
  const [showMore, setShowMore] = useState(false);
  let title = useTranslate('states.make-bet');
  const higher = useTranslate('states.higher');
  const lower = useTranslate('states.lower');
  const dealRatio = useTranslate('states.deal-ratio');
  const processing = useTranslate('app.processing');
  const maxWin = useTranslate('states.maximum-win');
  const makeABet = useTranslate('states.make-bet');
  const nextBet = useTranslate('states.next-bet');
  const betText = useTranslate('my-deals.bet');
  const confirmBets = useTranslate('states.confirm-bets');
  const { jackpot_company_fee } = useJackpot();

  const account = useWrapAccount();

  const sum = priceForBet ? ((bets || jackBets) ? (bets?.length || jackBets?.length || 1) : 1) * priceForBet : Number(bet);
  const disabledNextBet =
    (jackBets && jackBets.find((b) => Number(b) === Number(bet))) ||
    (bets && bets.find((b) => Number(b) === Number(bet))) ||
    (priceForBet && inValue !== undefined && sum + priceForBet > inValue);

  useEffect(() => {
    if (!buttonValues && button === 1 && bet) setBet(Number(bet).toFixed(2));
    if (!buttonValues && button === 10 && bet) setBet(Number(bet).toFixed(1));
    if (!buttonValues && button === 100 && bet) setBet(Number(bet).toFixed(0));
  }, [button, buttonValues]);

  return (
    <div className={classNames(s[theme], className)}>
      <div className={s.mb_20}>
        <Typography variant='h1'>{title}</Typography>
      </div>
      {!accordionOff && (
        <div className={s.mb_20}>
          <AccordionSwitcher
            className={s.accordionSwitcherBlock}
            decreaseTitle={`${lower}  ${targetValue || ''}`}
            fullWidth
            increaseTitle={`${higher} ${targetValue || ''}`}
            theme={theme}
            handleTargetSide={setSide}
          />
        </div>
      )}
      {buttonGroup && buttonAmount && (
        <>
          <div className={classNames(s.mb_5, s.dealBlock, s.btnGroupTitle)}>
            <Typography>{buttonGroupTitle}</Typography>
            <Tooltip theme={theme} toolTipText={tooltipButtonGroupTitle}>
              <TooltipIcon theme={theme} />
            </Tooltip>
          </div>
          <div className={classNames(s.mb_10, s.btnGroup)}>
            <div
              className={classNames(button === 1 && s.active)}
              onClick={() => setButton && setButton(1)}>
              <Typography>
                {Math.ceil(buttonAmount * ((100 + jackpot_company_fee) / 100))} $
              </Typography>
            </div>
            <div
              className={classNames(button === 10 && s.active)}
              onClick={() => setButton && setButton(10)}>
              {Math.ceil(buttonAmount * 10 * ((100 + jackpot_company_fee) / 100))} $
            </div>
            <div
              className={classNames(button === 100 && s.active)}
              onClick={() => setButton && setButton(100)}>
              {Math.ceil(buttonAmount * 100 * ((100 + jackpot_company_fee) / 100))} $
            </div>
          </div>
        </>
      )}
      {buttonGroup && buttonValues && (
        <>
          <div className={classNames(s.mb_5, s.dealBlock, s.btnGroupTitle)}>
            <Typography>{buttonGroupTitle}</Typography>
            <Tooltip theme={theme} toolTipText={tooltipButtonGroupTitle}>
              <TooltipIcon theme={theme} />
            </Tooltip>
          </div>
          <div className={classNames(s.mb_10, s.btnGroup)}>
            {buttonValues.map((buttonAmount) => (
              <div
                className={classNames(button === buttonAmount && s.active)}
                onClick={() => setButton && setButton(buttonAmount)}>
                <Typography>{buttonAmount} $</Typography>
              </div>
            ))}
          </div>
        </>
      )}
      <div className={classNames(s.mb_5, s.dealBlock)}>
        <Typography>{labelRangeSlider}</Typography>
      </div>
      <div className={s.mb_20}>
        {type === 'input' ? (
          <TextField
            background={theme === 'dark' ? '#171A22' : '#FFFFFF'}
            placeholder='10 USDT'
            setValue={(e) => {
              let v = e.target.value;
              const splited = v.split('.');

              if (splited[0].length > 1 && v[0] === '0' && v !== '0.') {
                v = '0.' + v.slice(1);
              } else if (
                splited[0].length > 5 &&
                bet &&
                v.length > bet.length &&
                splited.length === 1
              ) {
                v = v.slice(0, 5) + '.' + v[v.length - 1];
              } else if (
                button !== 100 &&
                splited[0].length > 4 &&
                bet &&
                v.length > bet.length &&
                splited.length === 1
              ) {
                v += '.';
              }

              // @ts-ignore
              if (!buttonValues && v && !v.match(regexp[button || 1])) {
                return false;
              }

              if (buttonValues && v && !v.match(regexp[1])) {
                return false;
              }

              setBet(v);
            }}
            value={bet || ''}
            theme={theme}
          />
        ) : (
          <RangeSlider
            bid={bid}
            currentTitle={currentTitle}
            icon={iconBid}
            inValue={inValue}
            min={min}
            onValue={(value) => setBet(value)}
            theme={theme}
          />
        )}
      </div>
      {!!coefficient && (
        <div className={classNames(s.mb_10, s.row)}>
          <div className={s.commissionBlock}>
            <Typography>{dealRatio}</Typography>
            <Tooltip theme={theme} toolTipText={tooltipDeal}>
              <TooltipIcon theme={theme} />
            </Tooltip>
          </div>
          <div>{coefficient}</div>
        </div>
      )}
      <div className={s.betsWrapper}>
        {jackBets?.slice(0, showMore ? jackBets.length : 3).map((bet, index) => (
          <div className={s.betRow}>
            <span>
              {betText} #{index + 1}
            </span>
            <div className={s.betRight}>
              <span>$ {bet}</span>
              <div
                className={s.removeBet}
                onClick={() => {
                  setJackBets(jackBets.filter((b, i) => index !== i));
                }}>
                <CloseIcon color={'#FFF'} size={'5'} />
              </div>
            </div>
          </div>
        ))}
      </div>
      {jackBets && jackBets.length > 3 && (
        <div onClick={() => setShowMore(!showMore)} className={s.showMore}>
          <ChevronIcon color={'#FFF'} className={showMore ? s.rotated : ''} />
        </div>
      )}

      <div className={s.betsWrapper}>
        {bets?.slice(0, showMore ? bets.length : 3).map((bet, index) => (
          <div className={s.betRow}>
            <span>
              {betText} #{index + 1}
            </span>
            <div className={s.betRight}>
              <span>$ {bet}</span>
              <div
                className={s.removeBet}
                onClick={() => {
                  setBets(bets.filter((b, i) => index !== i));
                }}>
                <CloseIcon color={'#FFF'} size={'5'} />
              </div>
            </div>
          </div>
        ))}
      </div>
      {bets && bets.length > 3 && (
        <div onClick={() => setShowMore(!showMore)} className={s.showMore}>
          <ChevronIcon color={'#FFF'} className={showMore ? s.rotated : ''} />
        </div>
      )}
      {jackBets && jackBets.length > 3 && (
        <div onClick={() => setShowMore(!showMore)} className={s.showMore}>
          <ChevronIcon color={'#FFF'} className={showMore ? s.rotated : ''} />
        </div>
      )}
      {(typeof coefficient === 'number' || !!maxPrize) && (
        <>
          <div className={s.divider} />
          <div className={classNames(s.mb_25, s.row)}>
            <div className={s.commissionBlock}>
              <Typography>{maxWin}</Typography>
              <Tooltip theme={theme} toolTipText={tooltipPrize}>
                <TooltipIcon theme={theme} />
              </Tooltip>
            </div>

            <div>
              {maxPrize
                ? Number(maxPrize).toFixed(2)
                : bet && coefficient
                  ? /* @ts-ignore */
                  (bet * coefficient).toFixed(2)
                  : '-'}
            </div>
          </div>
        </>
      )}
      {jackBets && !isLoading && (
        <div style={{ marginBottom: 15 }}>
          <Button
            color='grey'
            disabled={!bet || !!disabledNextBet || (left !== undefined && left <= 0)}
            fullWidth
            onClick={() => {
              setJackBets([...jackBets, Number(bet).toString()]);
              setBet('');
            }}
            size='xl'
            theme={theme}>
            {nextBet}
          </Button>
        </div>
      )}
      {bets && !isLoading && (
        <div style={{ marginBottom: 15 }}>
          <Button
            color='grey'
            disabled={disabled || !bet || !!disabledNextBet || (left !== undefined && left <= 0)}
            fullWidth
            onClick={() => {
              setBets([...bets, Number(bet).toString()]);
              setBet('');
            }}
            size='xl'
            theme={theme}>
            {nextBet}
          </Button>
        </div>
      )}
      <div>
        <Button
          color='secondary'
          fullWidth
          isLoading={isLoading}
          onClick={onClickBet}
          size='xl'
          disabled={
            disabled ||
            (left !== undefined && left <= 0) ||
            !account ||
            (!bet && (!bets || bets?.length === 0) && (!jackBets || jackBets?.length === 0)) ||
            (!!priceForBet && inValue !== undefined && sum > inValue)
          }
          theme={theme}>
          {((jackBets && jackBets.length >= 1) || (bets && bets.length >= 1))
            ? !isLoading
              ? confirmBets + ` (${jackBets?.length || bets?.length})`
              : processing
            : !isLoading
              ? makeABet
              : processing}
        </Button>
      </div>
    </div>
  );
};

export default PlaceYourBet;
