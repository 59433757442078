import React from 'react';

import useTranslate from 'hooks/useTranslate.hook';
import { baseApi } from 'store/consts';
import { useGetReportMutation as useLotteryGetReportMutation } from 'store/services/auctionService';
import { useGetReportMutation as useJackpotGetReportMutation } from 'store/services/jackpotService';
import { getExecutionType } from 'store/utils/getExecutionType';

import { ThemeType } from 'types/baseTypes';

import Button from '../../Simple/Button';
import Typography from '../../Simple/Typography';
import Translated from '../Translated';
import s from './ReportRequest.module.scss';

interface WinRequestProps {
  theme?: ThemeType;
  item?: any;
}

const ReportRequest = ({ theme = 'default', item }: WinRequestProps) => {
  const [getLotteryReport] = useLotteryGetReportMutation();
  const [getJackpotReport] = useJackpotGetReportMutation();
  const storeData = useTranslate('portfolio.store-data');
  const results = useTranslate('portfolio.deal-result');
  const download = useTranslate('portfolio.download-csv');
  const dealId = useTranslate('portfolio.deal-id');

  return (
    <div className={s[theme]}>
      <div className={s.mb_10}>
        <Typography variant='h1'>{results}</Typography>
      </div>
      <div className={s.mb_25}>
        <Typography color='grey'>{storeData}</Typography>
      </div>
      <div className={s.divider} />
      <div className={s.rowBlock}>
        <div className={s.prizeBlock}>
          <div>
            <Typography color='grey' variant='subtitle'>
              {dealId}
            </Typography>
          </div>
          <div>
            <Typography>{item?.betId}</Typography>
          </div>
        </div>
        <div className={s.buttonGroup}>
          <Button
            onClick={() => {
              if (item.betType === 'JACKPOT') {
                getJackpotReport(item?.betId);
              } else {
                getLotteryReport(item?.betId);
              }
            }}
            color='secondary'
            size='sm'>
            {download}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReportRequest;
