import React, { createContext, useEffect, useReducer, useRef } from 'react';

import { ethers } from 'ethers';
import useWrapAccount from 'hooks/useWrapAccount';

import { hooks } from 'metamask/connector';
import {
  AUCTION_ADDRESS,
  AUCTION_CONTACT_ABI,
  BINARY_ADDRESS,
  BINARY_CONTACT_ABI,
  CUSTOM_ADDRESS,
  CUSTOM_CONTRACT_ABI,
  JACKPOT_ADDRESS,
  JACKPOT_CONTACT_ABI,
  TOKEN_ADDRESS,
  TOKEN_CONTRACT_ABI,
} from 'metamask/constants';

const initialState = {
  binary_company_fee: 0,
  binary_contract: null,
  binary_allowance: 0,
  custom_company_fee: 0,
  custom_contract: null,
  custom_allowance: 0,
  token_contract: null,

  binary_expiration_delay: 0,

  lottery_allowance: 0,
  lottery_company_fee: 0,
  lottery_contract: null,

  jackpot_allowance: 0,
  jackpot_company_fee: 11.1111,
  jackpot_contract: null,


  balance: 0,
};

const defaultDispatch: React.Dispatch<any> = () => initialState;

export const CryptoContext = createContext({
  state: initialState,
  dispatch: defaultDispatch,
});

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_BINARY_EXPIRATION_DELAY':
      return { ...state, binary_expiration_delay: action.payload };
    case 'SET_BINARY_COMPANY_FEE':
      return { ...state, binary_company_fee: action.payload };
    case 'SET_BINARY_CONTRACT':
      return { ...state, binary_contract: action.payload };
    case 'SET_CUSTOM_COMPANY_FEE':
      return { ...state, custom_company_fee: action.payload };
    case 'SET_CUSTOM_CONTRACT':
      return { ...state, custom_contract: action.payload };
    case 'SET_LOTTERY_COMPANY_FEE':
      return { ...state, lottery_company_fee: action.payload };
    case 'SET_LOTTERY_ALLOWANCE':
      return { ...state, lottery_allowance: action.payload };
    case 'SET_LOTTERY_CONTRACT':
      return { ...state, lottery_contract: action.payload };
    case 'SET_JACKPOT_COMPANY_FEE':
      return { ...state, jackpot_company_fee: action.payload };
    case 'SET_JACKPOT_ALLOWANCE':
      return { ...state, jackpot_allowance: action.payload };
    case 'SET_JACKPOT_CONTRACT':
      return { ...state, jackpot_contract: action.payload };
    case 'SET_TOKEN_CONTRACT':
      return { ...state, token_contract: action.payload };
    case 'SET_CUSTOM_ALLOWANCE':
      return { ...state, custom_allowance: action.payload };
    case 'SET_BINARY_ALLOWANCE':
      return { ...state, binary_allowance: action.payload };
    case 'SET_BALANCE':
      return { ...state, balance: action.payload };
    default:
      return { ...state };
  }
};

type CryptoProviderProps = {
  children?: React.ReactNode;
};

const { useAccount, useProvider, useChainId } = hooks;

export const CryptoProvider: React.FC<CryptoProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const account = useWrapAccount();
  const provider = useProvider();
  const chainId = useChainId();

  useEffect(() => {
    if (!state.token_contract && provider && account /* && chainId === 97 */) {
      const address = TOKEN_ADDRESS;
      const signer = provider?.getSigner();

      const contract = new ethers.Contract(address, TOKEN_CONTRACT_ABI, signer);

      contract.balanceOf(account).then((r: any) => {
        dispatch({
          type: 'SET_BALANCE',
          payload: parseFloat(ethers.utils.formatEther(r)),
        });
      });

      provider.on('block', () => {
        contract.balanceOf(account).then((r: any) => {
          dispatch({
            type: 'SET_BALANCE',
            payload: parseFloat(ethers.utils.formatEther(r)),
          });
        });
      });

      dispatch({
        type: 'SET_TOKEN_CONTRACT',
        payload: contract,
      });

      contract.on('Approval', (from, to, value, event) => {
        const data = event.decode(event.data);

        if (from === account) {
          if (to === CUSTOM_ADDRESS) {
            dispatch({
              type: 'SET_CUSTOM_ALLOWANCE',
              payload: parseFloat(ethers.utils.formatEther(data.value)),
            });
          } else if (to === BINARY_ADDRESS) {
            dispatch({
              type: 'SET_BINARY_ALLOWANCE',
              payload: parseFloat(ethers.utils.formatEther(data.value)),
            });
          } else if (to === AUCTION_ADDRESS) {
            dispatch({
              type: 'SET_LOTTERY_ALLOWANCE',
              payload: parseFloat(ethers.utils.formatEther(data.value)),
            });
          } else if (to === JACKPOT_ADDRESS) {
            dispatch({
              type: 'SET_JACKPOT_ALLOWANCE',
              payload: parseFloat(ethers.utils.formatEther(data.value)),
            });
          }
        }
      });

      contract.allowance(account, CUSTOM_ADDRESS).then((r: any) => {
        dispatch({
          type: 'SET_CUSTOM_ALLOWANCE',
          payload: parseFloat(ethers.utils.formatEther(r)),
        });
      });

      contract.allowance(account, BINARY_ADDRESS).then((r: any) => {
        dispatch({
          type: 'SET_BINARY_ALLOWANCE',
          payload: parseFloat(ethers.utils.formatEther(r)),
        });
      });

      contract.allowance(account, AUCTION_ADDRESS).then((r: any) => {
        dispatch({
          type: 'SET_LOTTERY_ALLOWANCE',
          payload: parseFloat(ethers.utils.formatEther(r)),
        });
      });

      contract.allowance(account, JACKPOT_ADDRESS).then((r: any) => {
        dispatch({
          type: 'SET_JACKPOT_ALLOWANCE',
          payload: parseFloat(ethers.utils.formatEther(r)),
        });
      });
    }
  }, [state.token_contract, provider, chainId, account]);

  useEffect(() => {
    if (!state.lottery_contract && provider && account /* && chainId === 97 */) {
      const address = AUCTION_ADDRESS;
      const signer = provider?.getSigner();

      const contract = new ethers.Contract(address, AUCTION_CONTACT_ABI, signer);

      dispatch({
        type: 'SET_LOTTERY_CONTRACT',
        payload: contract,
      });

      contract.getCompanyFee().then((r: any) => {
        dispatch({
          type: 'SET_LOTTERY_COMPANY_FEE',
          payload: Number(r.toString() / 10000),
        });
      });
    }
  }, [state.lottery_contract, provider, chainId, account]);

  useEffect(() => {
    if (!state.jackpot_contract && provider && account /* && chainId === 97 */) {
      const address = JACKPOT_ADDRESS;
      const signer = provider?.getSigner();

      const contract = new ethers.Contract(address, JACKPOT_CONTACT_ABI, signer);

      dispatch({
        type: 'SET_JACKPOT_CONTRACT',
        payload: contract,
      });

      contract.getCompanyFee().then((r: any) => {
        dispatch({
          type: 'SET_JACKPOT_COMPANY_FEE',
          payload: Number(r.toString() / 10000),
        });
      });
    }
  }, [state.jackpot_contract, provider, chainId, account]);

  useEffect(() => {
    if (!state.custom_contract && provider && account /* &&  chainId === 97 */) {
      const address = CUSTOM_ADDRESS;
      const signer = provider?.getSigner();

      const contract = new ethers.Contract(address, CUSTOM_CONTRACT_ABI, signer);

      dispatch({
        type: 'SET_CUSTOM_CONTRACT',
        payload: contract,
      });

      contract.getCompanyFee().then((r: any) => {
        dispatch({
          type: 'SET_CUSTOM_COMPANY_FEE',
          payload: Number(r.toString() / 10000),
        });
      });
    }
  }, [state.binary_contract, provider, chainId, account]);

  useEffect(() => {
    if (!state.custom_contract && provider && account /* && chainId === 97 */) {
      const address = BINARY_ADDRESS;
      const signer = provider?.getSigner();

      const contract = new ethers.Contract(address, BINARY_CONTACT_ABI, signer);

      dispatch({
        type: 'SET_BINARY_CONTRACT',
        payload: contract,
      });

      contract.getCompanyFee().then((r: any) => {
        dispatch({
          type: 'SET_BINARY_COMPANY_FEE',
          payload: Number(r.toString() / 10000),
        });
      });

      contract.getTimestampExpirationDelay().then((r: any) => {
        dispatch({
          type: 'SET_BINARY_EXPIRATION_DELAY',
          payload: Number(r.toString()),
        });
      });
    }
  }, [state.binary_contract, provider, chainId, account]);

  return <CryptoContext.Provider value={{ state, dispatch }}>{children}</CryptoContext.Provider>;
};
