import TransactionsWrapper from 'components/Containers/TransactionsWrapper';
import TransactionCard from 'components/Simple/TransactionCard';
import Typography from 'components/Simple/Typography';
import React from 'react';

export const TransactionRow = ({ item, date }: { item: any, date: any }) => {
  return (
    <div>
      <TransactionsWrapper date={date}>
        {item}
      </TransactionsWrapper>
    </div>
  );
};
