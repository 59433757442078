import React, { createContext, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import { setRates } from '../store/slices/appSlice';

interface WebSocketContext {
  ws: null | WebSocket;
  ready: boolean;
}

export const WebsocketContext = createContext<WebSocketContext>({
  ws: null,
  ready: false,
});

type WebsocketProviderProps = {
  children?: React.ReactNode;
};

export const WebsocketProvider: React.FC<WebsocketProviderProps> = ({ children }) => {
  const dispatch = useDispatch();
  const ws = useRef<null | WebSocket>(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const websocket = new WebSocket(
      document.location.hostname.indexOf('localhost') > -1
        ? `ws://${document.location.hostname}:8080`
        : `wss://${document.location.hostname}/proxy`,
    );

    websocket.onopen = () => setReady(true);
    websocket.onclose = () => setReady(false);

    websocket.addEventListener('message', (event: MessageEvent) => {
      const parsed = JSON.parse(event.data);

      if (parsed?.rates) {
        dispatch(setRates(parsed?.rates));
      }
    });
    console.log(websocket, 'websocket');

    ws.current = websocket;
  }, [ws]);

  return (
    <WebsocketContext.Provider value={{ ws: ws.current, ready }}>
      {children}
    </WebsocketContext.Provider>
  );
};
