import React from 'react';

import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';

import { connector, hooks } from 'metamask/connector';

import App from './app';

const connectors: [MetaMask, Web3ReactHooks][] = [[connector, hooks]];

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <Web3ReactProvider connectors={connectors}>
      <BrowserRouter basename='/'>
        <App />
      </BrowserRouter>
    </Web3ReactProvider>
  </Provider>,
);
