const en = {
  'app.lang': 'Русский',
  'app.lange.en': 'Английский',
  'app.lange.ru': 'Русский',
  'app.hide': 'Скрыть',
  'app.show-bets': 'Показать ставки',
  'app.your-email': 'Ваш e-mail',
  'app.username': 'Никнейм',
  'app.wallet': 'Кошелёк',
  'app.bet': 'Ставка',
  'app.new-password': 'Новый пароль',
  'app.go': 'Перейти',
  'app.view-all': 'Показать ещё',
  'app.processing': 'Обработка',
  'app.request': 'Запросить',
  'app.back': 'Назад',
  'app.show-all': 'Смотреть все',
  'app.deal': 'Сделка',

  'app.deal-details': 'Детали сделки',
  'sidebar.finance-deals': 'Финансовые сделки',
  'sidebar.finance-deals.crypto': 'Криптовалюта',
  'sidebar.finance-deals.stocks': 'Акции',
  'sidebar.finance-deals.bonds': 'Облигации',
  'sidebar.finance-deals.currency': 'Валюты',
  'sidebar.finance-deals.metals': 'Металлы',
  'sidebar.finance-deals.product': 'Продукты',
  'sidebar.finance-deals.binary-options': 'Выше / Ниже',
  'sidebar.finance-deals.lottery': 'Дартс',
  'sidebar.finance-deals.jackpot': 'Джек Пул',
  'sidebar.finance-deals.make-bet': 'Добавить сделку',

  'header.main': 'Главная',
  'header.my-deals': 'Мои сделки',
  'header.community': 'Комьюнити',
  'header.auth': 'Авторизация',

  'header.burger.dark-theme': 'Тёмная тема',
  'header.burger.my-deals': 'Мои сделки',
  'header.burger.my-portfolio': 'Моё портфолио',
  'header.burger.log-out': 'Выйти',
  'header.burger.copy-id': 'Copy ID',
  'header.burger.copy-address': 'Скопировать мой адрес',
  'header.dropdown.headline': 'Выбрать кошелек',
  'header.dropdown.download.headline': 'Поддерживаемые кошельки',
  'header.dropdown.download.text':
    'Чтобы использовать нашу платформу, вы должны подключить свой кошелек. Вы можете воспользоваться любым доступным способом.',
  'header.dropdown.download.action': 'Загрузить',
  'header.dropdown.text.first': 'Подключая свой кошелек, вы соглашаетесь с нашими ',
  'header.dropdown.text.second': ' и нашей ',
  'header.dropdown.text.terms_of_services': 'Условиями использования',
  'header.dropdown.text.privacy_policy': 'Политикой Конфиденциальности',
  'header.dropdown.connect.button': 'У меня нет кошелька',

  'my-deals': 'My deals',
  'my-deals.finance-deals': 'Finance deals',
  'my-deals.search-for-deals': 'Search for a deal',
  'my-deals.high-liquidity': 'High liquidity',
  'my-deals.new': 'New',
  'my-deals.private': 'Private',
  'my-deals.will-the-btc': 'Будет ли цена ${btcName}',
  'my-deals.common--pool': 'общий пулл',
  'my-deals.bets': 'bets',
  'my-deals.bet': 'Ставка',
  'my-deals.remove-bet': 'Удалить ставку',
  'my-deals.get-unclaimed': 'Забрать непринятые средства',

  portfolio: 'Portfolio',
  'portfolio.my-deals': 'Мои сделки',
  'portfolio.win': 'Выигрыш',
  'portfolio.all-deals': 'Все сделки',
  'portfolio.finance': 'Финансовые',
  'portfolio.lottery': 'Дартс',
  'portfolio.binary-options': 'Опционы',
  'portfolio.bet-amount': 'Сумма ставок',
  'portfolio.bet-amount1': 'Сумма ставки',
  'portfolio.bet-side': 'Выбранный результат',
  'portfolio.chose-outcome': 'Выбранный исход',
  'portfolio.will-be-higher': 'Will be higher',
  'portfolio.lower': 'Ниже',
  'portfolio.get': 'Забрать',
  'portfolio.outcome': 'Исход',
  'portfolio.received': 'Принято',
  'portfolio.date-time': 'Дата и время',
  'portfolio.transactions': 'Транзакции',
  'portfolio.adding-bet': 'Adding a bet',
  'portfolio.will-the-btc': 'Will the BTC price be higher than $xx.xxxx',
  'portfolio.deal-id': 'ID сделки',
  'portfolio.deleting-bet': 'Deleting a bet',
  'portfolio.predict-decrease-increase': 'Predict decrease or increase of the exchange rate',
  'portfolio.return-bet': 'Return of the bet',
  'portfolio.export-report': 'При экспорте отчёта возникла ошибка',
  'portfolio.deal-cancel-error': 'При отмене ставки возникла ошибка',
  'portfolio.store-data':
    'Мы всегда храним все данные любой закрытой сделки. В результатах вы сможете найти список ставок всех пользователей участвующих в данной сделке',
  'portfolio.download-csv': 'Скачать файл csv',
  'portfolio.transactions-result': 'Результаты сделки',
  'portfolio.deal-result': 'Результаты сделки',
  'portfolio.final-rate': 'Итоговый курс',
  'portfolio.predict-btc': 'Спрогнозируйте курс BTC',
  'portfolio.predict': 'Спрогнозируйте курс',
  'portfolio.win-request': 'Запрос выигрыша',
  'portfolio.report-tooltip': 'Показать список всех ставок',

  'states.make-bet': 'Make a bet',
  'states.next-bet': 'Next bet',
  'states.confirm-bets': 'Confirm bets',
  'states.go-to-deal': 'Go to deal',
  'states.back-to-deal': 'Вернуться в сделку',
  'states.higher': 'Выше',
  'states.lower': 'Ниже',
  'states.fee': 'Комиссия',
  'states.write-off-bet': 'Write off with the bet',
  'states.deal-ratio': 'Коэффициент сделки',
  'states.maximum-win': 'Максимальный выигрыш',
  'states.bets-accepted': 'Ставки приняты',
  'states.bet-amount': 'Сумма ставки',
  'states.funds-pending': 'Funds pending',
  'states.will-be-accepted': 'Ваша ставка принята к сделке.',
  'states.your-bet': 'Ваша ставка',
  'states.deal-done': 'Сделка завершена',
  'states.error': 'Ошибка',
  'states.error-occured': 'Произошла ошибка при размещении ставки',
  'states.show-results': 'Показать результаты',

  'private-deal': 'Данная сделка приватная.',
  'private-deal.only': 'Просматривать и участвовать в сделке могут все, у кого есть ссылка.',
  'private-deal.cop-link': 'Copy the link',

  'options.five-minute-game': '5-ти минутная игра',
  'options.sixty-minute-game': '60-ти минутная игра',
  'options.weekly-game': 'Еженедельная',
  'options.currency-pair-accepting': 'Currency pair accepting bets 12 minutes left ',
  'options.predict-increase-decrease': 'Спрогнозируйте рост или падение курса BTC',
  'options.account': 'Аккаунт',
  'options.last-bets': 'Последние ставки',
  'options.my-bets': 'Мои ставки',
  'options.predict-long-text':
    'Спрогнозируйте рост или падение курса BTC. Курс валютной пары будет зафиксирован в указанное время, победителем считается та сторона, которая угадала рост или падение курса валютной пары по истечению срока сделки.',

  'options.predict-on-date': 'Спрогнозируйте курс BTC на',
  'options.accepting-bets': 'Прием ставок',
  'options.currency-pair': 'Валютная пара',
  'options.price-bet': 'цена 1 ставки',
  'options.waiting-result': 'Ждем результат',
  'options.waiting-next': 'Ожидание следующей игры',
  'options.waiting': 'Ожидает',
  'options.deal-closed': 'Сделка закрыта',
  'options.pool-distributed': 'Пулл распределен',
  'options.meaning': 'Значение',
  'options.make-bet': 'Сделайте ставку',
  'options.price-will': 'Цена будет',
  'options.data-source': 'Источник данных',
  'options.dinamyc': 'Динамический',

  'notification.deal-completed': 'Deal completed',
  'notification.update-page': 'Чтобы увидеть новую – обновите страницу.',
  'notification.update': 'Обновить',
  'notification.collection-cookie': 'Уведомление о сборе cookie',
  'notification.we-use-cookie': 'Мы используем файлы cookie для улучшения работы сайта',
  'notification.cookie-ok': 'Понятно',
  'lottery.predict-price': 'Спрогнозируйте курс BTC через 5 минут',
  'jackpot.raffle-date': 'Дата розыгрыша',
  'jackpot.last-winners': 'Последние победители',
  'jackpot.won-amount': 'сумма выигрыша',
  'jackpot.pool': 'Общий Джек пул',
  'jackpot.predicted-rate': 'спрогнозированный курс',
  'jackpot.final-rate': 'финальный рейт',
  'jackpot.long-text':
    'Джек пул — это финансовый инструмент, генерируемый платформой p2pbet. Участник, максимально точно угадавший курс BTC, получает 77.7% призового фонда джек пула. В случае отсутствия правильного ответа призовой фонд переносится на следующую неделю и приз накапливается, а часть приза забирают участники, оказавшиеся ближе всех к правильному ответу.\n' +
    'Как распределяется выигрыш? Пользователь, угадавший 7 цифр, получает 70% джек пула. Пользователь, угадавший 6 цифр, получает 7% джек пула; пользователь, угадавший 5 цифр, получит 0,7% джек пула.',

  'dashboard.popular-assets': 'Популярные активы',
  'dashboard.popular-deals': 'Популярные сделки',
  'dashboard.new-deals': 'Новые сделки',
  'dashboard.view-deals': 'Просмотренные сделки',
  'dashboard.bets-acception': 'Последние транзакции',
  'dashboard.last-transactions': 'Последние транзакции',
  'dashboard.until': 'до',
  //   FREEGAME
  'freegame.be-part.header': 'Участвуй в ежедневном розыгрыше 100$ от P2PBET!',
  'freegame.be-part.text1':
    'Каждый день мы разыгрываем 100$ среди участников Free game. Используй свои 3 попытки,чтобы спрогнозировать курс BTC в заданное время. Участник, результат которого будет наиболее приближен или соответствовать курсу BTC на момент экспирации сделки заберет приз!',
  'freegame.be-part.text2':
    ' Но есть одно условие – чтобы забрать приз, нужно быть подписанным на одну из наших социальных сетей. И не забудь добавить адрес кошелька TRC20 в разделе редактирования  профиля.',

  'freegame.daily-prize': 'FREE GAME с ежедневным призом',
  'freegame.five-minute': 'FREE GAME на 5 минут',

  'freegame.what-is': 'Что такое Free Game?',
  'freegame.desc':
    'Фри гейм - это платформа, полностью имитирующая функционал P2PBet, но без использования пользовательского кошелька метамаск. Помимо всех доступных на п2пбет финансовых инструментов, раз в неделю на платформе FreeGame среди зарегистрировавшихся пользователей будут разыгрываться реальные вознагржадения! Мы создали FreeGame для того, что бы вы могли поучавствовать в финансовых парии заработать деньги без вложений, ориентируясь только на свой расчет и интуицию. Удачи!',
  'freegame.amount-attempts': 'Кол-во попыток:',
  'freegame.predict-btc':
    'Спрогнозируйте курс BTC в заданное время. Участник, результат которого будет наиболее приближен или соответствовать фактическому курсу BTC/USDT на момент экспирации по бирже Binance, станет победителем.',
  'freegame.view-list': 'Показать список всех ставок',
  'freegame.accrual': 'Accrual',
  'freegame.charging': 'Charging',
  'freegame.main-data': 'Основные данные',
  'freegame.payment-details': 'Платёжные данные',
  'freegame.login': 'Log in',
  'freegame.username-email': 'Username or Email',
  'freegame.do-have-account': 'Do you have an account? Log in',
  'freegame.signup': 'Авторизуйтесь',
  'freegame.leave': 'Покинуть режим Free Game',
  'freegame.back': 'Вернуться назад',
  'freegame.save-settings': 'Сохранить настройки',
  'freegame.repeat-password': 'Повторите пароль',
  'freegame.safety': 'Безопасность',
  'freegame.setting-saved': 'Настройки сохранены',
  'freegame.date-updated': 'Информация успешно обновлена.',
  'freegame.eroor-create': 'При сохранении данных произошла ошибка',
  'freegame.social-networks': 'Социальные сети',
  'freegame.loss': 'Проигрыш',

  'bets.option-search': 'Поиск актива',
  'bets.option-search-name': 'Поиск актива по названию',

  'bets.open': 'Открытые',
  'bets.completed': 'Завершенные',
  'bets.most-bets': 'Больше всего ставок',
  'bets.largest-pool': 'Наибольший пул',

  'bets.clear': 'Очистить все',
  'bets.private': 'Приватная',
  'bets.popular': 'Популярная',
  'bets.new': 'Новая',
  'bets.accepted': 'Ставки принимаются',
  'bets.accepted-not': 'Ставки не принимаются',
  'bets.higher': 'выше',
  'bets.lower': 'ниже',
  'bets.done': 'сделано',
  'bets.quick': 'Quick bet',
  'bets.current-rate': 'Текущий курс',
  'bets.starting-rate': 'Стартовый курс',
  'bets.win-amount': 'Сумма выигрыша',

  'new-bet.ratio': 'Коэффициент',
  'new-bet.point-ratio': 'Укажите коэффициент выйгрыша и ставку',
  'new-bet.event-date': 'Время события',
  'new-bet.get-with-bet': 'Списать со ставкой',
  'new-bet.get-without-bet': 'Списать без ставки',
  'new-bet.deal-outcome': 'Исход сделки',
  'new-bet.end-accepting': 'Конец приема ставок',
  'new-bet.current-rate': 'Текущий курс',
  'new-bet.new-deal': 'Новая сделка',
  'new-bet.currency-pair': 'Валютная пара',
  'new-bet.select-currency-pair': 'Выберите валютную пару сделки',
  'new-bet.popular-pairs': 'Популярные пары',
  'new-bet.price-condition': 'Ценовое условие',
  'new-bet.outcome-price': 'Выберите исход и значение цены для сделки',
  'new-bet.specify-beginning': 'Укажите начало и окончание сделки',
  'new-bet.price-value': 'Значение цены',
  'new-bet.end-bets': 'Окончание приема ставок',
  'new-bet.event-end': 'Завершение события',
  'new-bet.private-deal': 'Приватная сделка',
  'new-bet.create-deal': 'Создать сделку',
  'new-bet.get': 'Получить',

  'tooltip.private':
    'Приватная сделка – это сделка, доступ к которой будет только у вас и у пользователей, у которых есть ссылка на данную сделку.',
  'tooltip.binary':
    'Коэффициент бинарных опционов является динамическим и фиксируется в момент окончания приема ставок.',
  'tooltip.deal-ratio':
    'Коэффициент пользовательских ставок устанавливается пользователем, который создает сделку.',
  'tooltip.maximum-win':
    'Максимальная сумма выигрыша определяется коэффициентом, с которым вы вступили в сделку и суммой вашей ставки.',
  'tooltip.your-bet':
    'Участвуя в дартсе, вы можете присоединяться к разным пулам, для каждой суммы ставки свой пул выигрыша.',
  'tooltip.lottery.maximum-win':
    'Максимальная сумма выигрыша определяется суммой ставок всех пользователей в данной игре, в случае если Ваша ставка будет ближе всего к курсу BTC в момент экспирации, то вы заработаете максимальную сумму выигрыша, если же будут еще пользователи с такой же ставкой как у вас, то сумма выигрыша разделится поровну между всеми участниками.',
  'tooltip.data-source': 'Данные об этой валютной пары берутся в реальном времени с данной биржи.',
  'tooltip.data-source.chainlink':
    'Данные этой валютной пары берутся в соответствии с Heartbeat Chain.link для выбранной валютной пары,  результат сделки определяется первым значением после окончания сделки.\n',

  'tooltip.jackpot.your-bet':
    'Совершая ставку в 10$ вы указываете ставку в формате xxxxx.xx, совершая ставку в 100$ вы указываете ставку в формате xxxxx.х, все сотые значения проставляются платформой автоматически, совершая ставку в 1000$ вы указываете ставку в формате xxxxx, все сотые и десятые значения проставляются платформой автоматически.',
  'tooltip.jackpot.maximum-win':
    'Максимальная сумма выигрыша составляет 77.7% от общего Джек Пула, в случае того если вы угадали все 7 цифр валютной пары BTC/USDT то вы получаете 77.7% от джек пула, если вы угадали 6 цифр, то вы получаете 7.7% от джек пула, если вы угадали 5 цифр, то вы получаете 0.7% от джек пула. Если есть другие пользователи которые вместе с вами угадали стоимость валютной пары, то выигрыш делится между всеми участниками поровну. ',

  'notification.delete-bet.title': 'Ваша ставка удалена',
  'notification.delete-bet.description': 'Ваша ставка успешно удалена',

  'notification.deal-created.title': 'Сделка создана',
  'notification.deal-created.description': 'Ваша сделка успешно создана',

  'notification.join-bet.title': 'Присоединение к сделке',
  'notification.join-bet.description': 'Вы успешно присоединились к сделке',

  'notification.join-bet-mass.title': 'Ваши ставки приняты',
  'notification.join-bet-mass.description': 'Ваши ставки успешно приняты в Дартс',
  'notification.join-bet-mass-jackpot.description': 'Ваши ставки успешно приняты в Джек Пул',

  'notification.money-accepted.title': 'Ваши средства успешно приняты к ставке',
  'notification.money-accepted.description': 'Ваши средства успешно приняты к ставке в сделке',

  'notification.expiration.title': 'Окончание приема ставок',
  'notification.expiration.description':
    'В сделке осталось менее 5 минут до окончания приема ставок',

  'notification.completed.won.title': 'Deal completed',
  'notification.completed.won.description':
    'Сделка, в которой вы разместили ставку, успешно завершена. Чтобы забрать выигрыш, нажмите на кнопку или перейдите в портфолио.',

  'notification.completed.fail.title': 'Сделка завершена',
  'notification.completed.fail.description':
    'Сделка, в которой вы разместили ставку, была успешно завершена. Чтобы узнать результат, нажмите на кнопку или перейдите в портфолио.',

  'leaderboard.title': 'Leaderboard',
  'leaderboard.description':
    'The best players will always be on the leaderboard. Play and win to become one of the best!',
  'leaderboard.table.title': 'Player List',
  'leaderboard.toggle.monthly': 'Monthly',
  'leaderboard.toggle.inyear': 'In a year',
  'leaderboard.useraddress': 'User address',
  'leaderboard.bestbet': 'Best win',
  'leaderboard.totalbets': 'Total bets',
  'leaderboard.won': 'Won',
  'leaderboard.lost': 'Lost',
  'leaderboard.result': 'Total winnings',
  'leaderboard.you': 'You',

  'chainlink.datetime': 'Date time',
  'chainlink.roundid': 'Round Id',
  'chainlink.value': 'Value',
  'chainlink.link': 'Link',
};

export default en;
