import React from 'react';
import { IconsTypes } from 'types/baseTypes';

const ChevronTopIcon = ({ height = '11', width = '13', color = '#0075FF', className }: IconsTypes) => (
  <svg className={className} fill='none' height={height} viewBox='0 0 13 11' width={width}
       xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.63398 0.499999C6.01888 -0.166667 6.98113 -0.166667 7.36603 0.5L12.1292 8.75C12.5141 9.41667 12.0329 10.25 11.2631 10.25H1.73686C0.967059 10.25 0.485935 9.41667 0.870835 8.75L5.63398 0.499999Z'
      fill={color} />
  </svg>
);

export default ChevronTopIcon;
