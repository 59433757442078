import { ColorType } from 'lightweight-charts';

import binance from './images/binance.svg';
import bitfinex from './images/bitfinex.svg';
import bittrex from './images/bittrex.svg';
import chainlink from './images/chainlink.svg';
import coinbase from './images/coinbase.svg';
import gemini from './images/gemini.svg';
import hitbtc from './images/hitbtc.svg';
import huobi from './images/huobi.svg';
import kraken from './images/kraken.svg';
import kucoin from './images/kucoin.svg';
import okex from './images/okex.svg';
import poloniex from './images/poloniex.svg';
import zbcom from './images/zbcom.svg';

export const darkTheme = {
  chart: {
    layout: {
      backgroundColor: '#171A22',
      lineColor: '#2B2B43',
      textColor: '#D9D9D9',
    },
    rightPriceScale: {
      borderVisible: false,
    },
    timeScale: {
      borderColor: '#363C4E',
    },
    watermark: {
      color: 'rgba(0, 0, 0, 0)',
    },
    grid: {
      vertLines: {
        visible: false,
      },
      horzLines: {
        color: '#363C4E',
      },
    },
  },
  series: {
    upColor: '#08BD50',
    downColor: '#FA3E2C',
  },
};

export const lightTheme = {
  chart: {
    layout: {
      backgroundColor: '#FFFFFF',
      lineColor: '#2B2B43',
      textColor: '#191919',
    },
    watermark: {
      color: 'rgba(0, 0, 0, 0)',
    },
    rightPriceScale: {
      position: 'left',
      borderVisible: false,
    },
    leftPriceScale: {
      visible: true,
    },
    timeScale: {
      borderColor: '#f0f3fa',
    },
    grid: {
      vertLines: {
        visible: false,
      },
      horzLines: {
        color: '#f0f3fa',
      },
    },
  },
  series: {
    upColor: '#08BD50',
    downColor: '#FA3E2C',
  },
};

export const themesData = {
  dark: darkTheme,
  default: lightTheme,
};

export const sources = {
  CHAINLINK: {
    url: 'https://chain.link/',
    image: chainlink,
  },
  BINANCE: {
    url: 'https://binance.com/',
    image: binance,
  },
  KRAKEN: {
    url: 'https://binance.com/',
    image: kraken,
  },
  HITBTC: {
    url: 'https://binance.com/',
    image: hitbtc,
  },
  COINBASE: {
    url: 'https://binance.com/',
    image: coinbase,
  },
  GEMINI: {
    url: 'https://binance.com/',
    image: gemini,
  },
  POLONIEX: {
    url: 'https://binance.com/',
    image: poloniex,
  },
  ZBCOM: {
    url: 'https://binance.com/',
    image: zbcom,
  },
  BITTREX: {
    url: 'https://binance.com/',
    image: bittrex,
  },
  KUCOIN: {
    url: 'https://binance.com/',
    image: kucoin,
  },
  OKEX: {
    url: 'https://binance.com/',
    image: okex,
  },
  BITFINEX: {
    url: 'https://binance.com/',
    image: bitfinex,
  },
  HUOBI: {
    url: 'https://binance.com/',
    image: huobi,
  },
};

export const chartOptions = (backgroundColor: string, textColor: string) => ({
  layout: {
    background: { type: ColorType.Solid, color: backgroundColor },
    textColor,
  },
  timeScale: {
    rightOffset: 1,
    barSpacing: 10,
    fixLeftEdge: true,
    lockVisibleTimeRangeOnResize: true,
    rightBarStaysOnScroll: true,
    borderVisible: true,
    visible: true,
    timeVisible: true,
    secondsVisible: true,
  },
  width: 600,
  height: 300,
});

export function timeToLocal(originalTime: number) {
  const d = new Date(originalTime * 1000);
  return (
    Date.UTC(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds(),
      d.getMilliseconds(),
    ) / 1000
  );
}
