import en from 'lang/en';
import ru from 'lang/ru';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { AppLangs } from 'types/baseTypes';

const useTranslate = (id: keyof typeof en) => {
  const lang = useSelector((state: RootState) => state.app.lang);

  if (lang === AppLangs.ru) return ru[id];
  return en[id];
};
export default useTranslate;
