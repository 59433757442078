import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApi } from 'store/consts';
import { getExecutionType } from 'store/utils/getExecutionType';

export const API_NAME = 'leaderboardService';

export const leaderboardService = createApi({
  reducerPath: API_NAME,
  baseQuery: fetchBaseQuery({ baseUrl: `${baseApi}/leaderboard/` }),

  endpoints: (builder) => ({
    getLeaderByClientAddress: builder.query({
      query: ({ account, period, betType, page, sort }) => ({
        url: `${account}?period=${period}&executionType=${getExecutionType()}${
          betType === 'ALL'
            ? '&betType=CUSTOM&betType=BINARY&betType=AUCTION'
            : `&betType=${betType}`
        }&sort=${sort}`,
      }),
    }),
    getLeaderboard: builder.query({
      query: ({ sort, period, betType, page }) => ({
        url: `?period=${period}&executionType=${getExecutionType()}${
          betType === 'ALL'
            ? '&betType=CUSTOM&betType=BINARY&betType=AUCTION'
            : `&betType=${betType}`
        }&page=${page}&size=10&sort=${sort}`,
      }),
    }),
  }),
});

export const { useLazyGetLeaderByClientAddressQuery, useLazyGetLeaderboardQuery } =
  leaderboardService;
