import React from 'react';
import { CryptoIconType } from 'types/baseTypes';

const XrpIcon = ({ size = '25' }: CryptoIconType) => (
  <svg fill='none' height={size} viewBox='0 0 25 25' width={size} xmlns='http://www.w3.org/2000/svg'>
    <rect fill='white' height='25' rx='12.5' width='25' />
    <path
      d='M17.8024 6.25H19.9718L15.4579 10.7452C13.8238 12.3726 11.175 12.3726 9.54093 10.7452L5.0276 6.25H7.19695L10.6259 9.66486C11.6605 10.6957 13.3383 10.6957 14.3735 9.66486L17.8024 6.25ZM7.16935 18.75H5L9.54153 14.2271C11.1756 12.5996 13.8244 12.5996 15.4585 14.2271L20 18.75H17.8307L14.3735 15.3074C13.3389 14.2766 11.6611 14.2766 10.6259 15.3074L7.16935 18.75Z'
      fill='#23292F' />
  </svg>
);

export default XrpIcon;
