import React from 'react';

import classNames from 'classnames';

import Translated from 'components/Containers/Translated';
import { ThemeType } from 'types/baseTypes';

import Typography from '../Typography';
import s from './TransactionCard.module.scss';

interface TransactionCardProps {
  theme?: ThemeType;
  actionsBetTitle?: string;
  actionValue?: string;
  content?: string;
  dateContent?: string;
  idDeal?: string;
  date?: string;
}

const TransactionCard = ({
                           theme = 'default',
                           date = '',
                           dateContent = '',
                           content = '',
                           idDeal = '',
                           actionsBetTitle = '',
                           actionValue = '',
                         }: TransactionCardProps) => (
  <div className={classNames(s.wrapper, s[theme])}>
    <div className={s.main}>
      <div className={s.header}>
        <Typography color='grey' variant='small'>
          {actionsBetTitle}
        </Typography>
        <div className={s.actionValueBlock}>{actionValue}</div>
      </div>
      <div className={s.content}>
        <Typography variant='h4'>{content}</Typography>
        <Typography color='grey'>{dateContent}</Typography>
      </div>
    </div>
    <div className={s.divider} />
    <div className={s.footer}>
      <div className={s.idBlock}>
        <Typography color='grey' variant='small'>
          <Translated id='portfolio.deal-id' />: {idDeal}
        </Typography>
      </div>
      <div>
        <Typography color='grey' variant='small'>
          {date}
        </Typography>
      </div>
    </div>
  </div>
);

export default TransactionCard;
