import { useCallback } from 'react';

import useTranslate from 'hooks/useTranslate.hook';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { changeLang } from 'store/slices/appSlice';
import useColor from 'theme/useColor';

import Label from 'components/Simple/Label';
import RadioButton from 'components/Simple/RadioButton';
import Typography from 'components/Simple/Typography';
import { AppLangs } from 'types/baseTypes';

import { EngFlagIcon, RusFlagIcon } from '../../Icons';
import ChevronRight from '../../Icons/ChevronRight';
import Menu from '../Menu';
import s from './index.module.scss';

const locales = Object.values(AppLangs);
const LangSelect = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state: any) => state.app.lang);
  const theme = useSelector((state: any) => state.app.theme);
  const { white, dark } = useColor();

  const setCurrentLang = useCallback(
    (val: AppLangs) => {
      dispatch(changeLang(val));
    },
    [dispatch],
  );

  const en = useTranslate('app.lange.en');
  const ru = useTranslate('app.lange.ru');

  const getCurrentLocale = (th: string) => {
    const isTh = th === 'default';

    if (lang === AppLangs.ru) {
      return (
        <Label
          className={s.label}
          leftIcon={<RusFlagIcon />}
          rightElement={<ChevronRight color={isTh ? dark : white} />}
          title={<Typography>{ru}</Typography>}
        />
      );
    }
    return (
      <Label
        className={s.label}
        leftIcon={<EngFlagIcon />}
        rightElement={<ChevronRight color={isTh ? dark : white} />}
        title={<Typography>{en}</Typography>}
      />
    );
  };

  return (
    <Menu button={<div>{getCurrentLocale(theme)}</div>} right='200px' theme={theme}>
      {locales.map((locale, index) => (
        <div key={locale} onClick={() => setCurrentLang(locale)} style={{ cursor: 'pointer' }}>
          <Label
            leftIcon={locale === AppLangs.en ? <EngFlagIcon /> : <RusFlagIcon />}
            nextElement={index !== locales.length - 1}
            rightElement={
              <RadioButton
                checked={lang === locale}
                onChange={() => {
                }}
                theme={theme}
                value={locale}
              />
            }
            theme={theme}
            title={
              <Typography style={{ textTransform: 'capitalize' }}>
                {locale === AppLangs.en ? en : ru}
              </Typography>
            }
          />
        </div>
      ))}
    </Menu>
  );
};

export default LangSelect;
