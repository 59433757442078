import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import Slider from 'rc-slider';

import { ThemeType } from 'types/baseTypes';

import useColor from '../../../theme/useColor';
import Button from '../../Simple/Button';
import s from './RangeSlider.module.scss';
import './index.scss';

interface RangeSliderProps {
  theme?: ThemeType;
  inValue?: number;
  currentTitle?: string;
  bid?: boolean;
  ignoreMaxValue?: boolean;
  val?: string;
  hideSlider?: boolean;
  placeholder?: string;
  icon?: React.ReactNode;
  min?: string | number;
  onValue?: (value: string) => void;
}

function cutString(input: string) {
  const [beforeDot, afterDot] = input.split('.');
  if (afterDot !== undefined) {
    const afterDotTrimmed = afterDot.slice(0, 2);
    return beforeDot + '.' + afterDotTrimmed;
  } else {
    return beforeDot;
  }
}

const RangeSlider = ({
                       theme = 'default',
                       inValue = 0,
                       currentTitle = '',
                       bid,
                       val,
                       placeholder = '10 USDT',
                       icon,
                       hideSlider,
                       onValue,
                       min = 0,
                     }: RangeSliderProps) => {
  const isDarkTheme = theme === 'dark';
  const { dark, primary, white200, white300, grey600 } = useColor();

  const [value, setValue] = useState<string | undefined>();

  const handleValue = (value: string) => {
    setValue(cutString(value));
    onValue && onValue(cutString(value));
  };

  useEffect(() => {
    setValue(val);
  }, [val]);

  const handleTarget = (num: number | string) => {
    handleValue((+inValue * (+num / 100)).toString());
  };

  return (
    <div className={classNames(s.wrapper, s[theme])}>
      {!hideSlider && (
        <Slider
          className={s.slider}
          handleStyle={{
            backgroundColor: primary,
            opacity: 1,
            border: `2px solid ${!isDarkTheme ? white200 : dark}`,
          }}
          max={+inValue}
          min={+min}
          onChange={(val) => handleValue(val.toString())}
          railStyle={{
            background: !isDarkTheme ? white300 : grey600,
            borderRadius: '0 0 10px 5px',
          }}
          step={0.01}
          trackStyle={{
            background: primary,
            borderRadius: '0 0 0 5px',
          }}
          value={Number(value) || 0}
        />
      )}
      <div className={classNames(s.content, s[`content-${theme}`])}>
        {/*<div className={s.title}>*/}
        {/*  {value} {currentTitle}*/}
        {/*</div>*/}
        <input
          type='text'
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            let v = e.target.value;
            if (v[0] === '0' && v.length === 1 && !value) {
              v += '.';
            }

            if (!v.match(/^([0-9]{0,14})(\.?)([0-9]{0,2})$/)) {
              return false;
            }

            if (Number(v) > inValue) {
              handleValue(inValue.toFixed(2));
            } else {
              handleValue(v);
            }
          }}
          className={s.input}
        />
        {!bid ? (
          <div className={s.kit}>
            <Button
              className={s.btn}
              color='grey'
              onClick={() => handleTarget(25)}
              size='xs'
              theme={theme}>
              25 %
            </Button>
            <Button
              className={s.btn}
              color='grey'
              onClick={() => handleTarget(50)}
              size='xs'
              theme={theme}>
              50 %
            </Button>
            <Button
              className={s.btn}
              color='grey'
              onClick={() => handleTarget(75)}
              size='xs'
              theme={theme}>
              75 %
            </Button>
          </div>
        ) : (
          <div className={s.iconWrapper}>{icon}</div>
        )}
      </div>
    </div>
  );
};

export default RangeSlider;
