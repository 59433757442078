import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { store } from 'store';
import { baseApi } from 'store/consts';
import { getExecutionType } from 'store/utils/getExecutionType';

export const API_NAME = 'customService';

export interface IRTKQueryResponse<T> {
  totalElements: number;
  totalPages: number;
  result: T[];
}

export interface IJoin {
  id: string;
  customBetId: number;
  client: string;
  joinId: number;
  joinIdClientRef: number;
  status: string;
  side: boolean;
  joinAmount: number;
  freeAmount: number;
  lockedAmount: number;
  joinHash: string;
  cancelHash?: any;
  refundHash?: any;
  prizeTakenHash?: any;
  createdDate: Date;
  modifiedDate: Date;
  deal?: string;
  temp?: Promise<IBet>;
}

export interface IBet {
  id: number;
  status: string;
  createdBlockNumber: number;
  targetValue: string;
  targetSide: boolean;
  finalValue?: any;
  targetSideWon?: any;
  eventId: string;
  coefficient: number;
  lockDate: Date;
  expirationDate: Date;
  createdDate: Date;
  hidden: boolean;
  matchingInfo: {
    leftFreeAmount: number;
    leftLockedAmount: number;
    rightFreeAmount: number;
    rightLockedAmount: number;
    totalPool: number;
    totalBetCount: number;
  };
  executionType: string;
}

export const customService = createApi({
  reducerPath: API_NAME,
  baseQuery: fetchBaseQuery({ baseUrl: `${baseApi}/custom/` }),

  endpoints: (builder) => ({
    betList: builder.query({
      query: (arg) => {
        console.log(arg);
        const reduxStore: any = store.getState();
        let sort,
          ids,
          statusList,
          filter = {};

        if (arg === 'popular') {
          sort = {
            direction: 'DESC',
            property: 'matchingInfo.totalJoinCount',
          };
          statusList = {
            list: ['CREATED'],
          };
        } else if (arg === 'new') {
          sort = {
            direction: 'DESC',
            property: 'createdDate',
          };
          statusList = {
            list: ['CREATED'],
          };
        } else if (arg === 'viewed') {
          ids = reduxStore.app.viewedBets;
        } else {
          filter = arg;
        }

        return {
          url: `bet`,
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: {
            executionType: getExecutionType(),
            page: arg?.page || 0,
            size: 30,
            ids: ids?.length ? ids : null,
            sort,
            statusList,
            ...filter,
          },
        };
      },
    }),
    joinList: builder.query({
      query: ({ id, client, page, size }) => ({
        url: `bet/joins`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: {
          executionType: getExecutionType(),
          page: page || 0,
          size: size || 20,
          client,
          customBet: id,
          statusList: {
            list: ['JOINED', 'LOST', 'PRIZE_TAKEN', 'WON'],
          },
          sort: {
            direction: 'DESC',
            property: 'createdDate',
          },
        },
      }),
    }),
    betById: builder.query({
      query: (id) => ({
        url: `bet/${getExecutionType()}/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useBetListQuery,
  useJoinListQuery,
  useLazyBetByIdQuery,
  useLazyBetListQuery,
  useLazyJoinListQuery,
} = customService;
