import React, { useState } from 'react';

import classNames from 'classnames';
import useTranslate from 'hooks/useTranslate.hook';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useEventsListQuery } from 'store/services/eventsService';

import AccordionSwitcher from 'components/Simple/AccordionSwitcher';
import { formatDate } from 'helpers/formatDate';
import getEventById from 'helpers/getEventById';
import { ThemeType } from 'types/baseTypes';

import Typography from '../../Simple/Typography';
import s from './BetStatus.module.scss';

interface PlaceYourBetProps {
  theme?: ThemeType;
  item?: any;
  coefficient?: string;
  withRates?: boolean;
  current?: number;
  joinAmount?: any;
  leftPool?: number;
  rightPool?: number;
  jackpot?: boolean;
}

const BetStatus = ({
                     theme = 'default',
                     item = {},
                     withRates,
                     current,
                     joinAmount,
                     jackpot,
                     leftPool,
                     rightPool,
                   }: PlaceYourBetProps) => {
  const [side, setSide] = useState('increase');
  const createdDate = formatDate(item.createdDate);
  const expirationDate = new Date(item.expirationDate + 'Z');
  const lockDate = new Date(item.lockDate + 'Z').getTime();
  const dealResult = useTranslate('portfolio.transactions-result');
  const waitingNext = useTranslate('options.waiting-next');
  const betsAccepted = useTranslate('states.bets-accepted');
  const finalRate = useTranslate('portfolio.final-rate');
  const betAmount = useTranslate('portfolio.bet-amount');
  const dealRatio = useTranslate('states.deal-ratio');
  const currentRate = useTranslate('bets.current-rate');
  const startingRate = useTranslate('bets.starting-rate');
  const winAmount = useTranslate('bets.win-amount');
  const higher = useTranslate('states.higher');
  const lower = useTranslate('states.lower');

  const hasJoins = joinAmount?.leftTotalAmount + joinAmount?.rightTotalAmount;

  let leftCoef = 0,
    rightCoef = 0;

  if (leftPool && rightPool) {
    leftCoef = Number(((leftPool + rightPool) / leftPool)?.toFixed(2));
    rightCoef = Number(((leftPool + rightPool) / rightPool)?.toFixed(2));
  }

  return (
    <div className={classNames(s[theme])}>
      {withRates && (
        <div className={s.rates}>
          <div className={s.rate}>
            <div>{currentRate}</div>
            <div
              style={{
                color: current && current >= Number(item?.lockedValue) ? '#08BD50' : '#FA3E2C',
              }}>
              ${current}
            </div>
          </div>
          <div className={s.rate}>
            <div>{startingRate}</div>
            <div style={{ color: '#0075FF' }}>${item?.lockedValue}</div>
          </div>
        </div>
      )}

      <div
        className={s.mb_20}
        style={{ borderBottom: `1px solid ${theme === 'dark' ? '#2E3138' : '#CFD0D1'}` }}>
        <div className={s.mb_5}>
          {jackpot ? (
            <Typography variant='h1'>
              {Date.now() > lockDate && Date.now() < expirationDate.getTime()
                ? betsAccepted
                : waitingNext}
            </Typography>
          ) : (
            <Typography variant='h1'>
              {Date.now() > lockDate && Date.now() < expirationDate.getTime()
                ? betsAccepted
                : dealResult}
            </Typography>
          )}
        </div>
        {(!jackpot || Date.now() < expirationDate.getTime()) && (
          <Typography color={'grey'}>
            {createdDate} - {formatDate(item.expirationDate)}
          </Typography>
        )}
      </div>

      {!!hasJoins && withRates && (
        <div style={{ marginBottom: 20 }}>
          <AccordionSwitcher
            className={s.accordionSwitcherBlock}
            decreaseTitle={lower}
            fullWidth
            increaseTitle={higher}
            theme={theme}
            handleTargetSide={setSide}
          />
        </div>
      )}

      {!!hasJoins && (
        <div
          className={classNames(s.mb_10, s.row)}
          style={{ borderBottom: `1px solid ${theme === 'dark' ? '#2E3138' : '#CFD0D1'}` }}>
          <div className={s.commissionBlock}>
            <Typography>{betAmount}</Typography>
          </div>
          <div>
            {(side === 'increase'
              ? joinAmount.leftTotalAmount?.toFixed(2)
              : joinAmount.rightTotalAmount?.toFixed(2)) || 0}{' '}
            USDT
          </div>
        </div>
      )}
      {!!hasJoins && leftCoef > 0 && (
        <div
          className={classNames(s.mb_10, s.row)}
          style={{ borderBottom: `1px solid ${theme === 'dark' ? '#2E3138' : '#CFD0D1'}` }}>
          <div className={s.commissionBlock}>
            <Typography>{dealRatio}</Typography>
          </div>
          <div>{side === 'increase' ? leftCoef : rightCoef}x</div>
        </div>
      )}
      {!!hasJoins && leftCoef > 0 && (
        <div
          className={classNames(s.mb_10, s.row)}
          style={{ borderBottom: `1px solid ${theme === 'dark' ? '#2E3138' : '#CFD0D1'}` }}>
          <div className={s.commissionBlock}>
            <Typography>{winAmount}</Typography>
          </div>
          <div>
            {side === 'increase'
              ? (leftCoef * joinAmount.leftTotalAmount)?.toFixed(2)
              : (rightCoef * joinAmount.rightTotalAmount)?.toFixed(2)}{' '}
            USDT
          </div>
        </div>
      )}

      {!jackpot && item.finalValue && (
        <div
          className={classNames(s.mb_10, s.row)}
          style={{ borderBottom: `1px solid ${theme === 'dark' ? '#2E3138' : '#CFD0D1'}` }}>
          <div className={s.commissionBlock}>
            <Typography>{finalRate}</Typography>
          </div>
          <div>{item.finalValue}</div>
        </div>
      )}

      {item.matchingInfo && (
        <div
          className={classNames(s.mb_10, s.row)}
          style={
            item.coefficient
              ? { borderBottom: `1px solid ${theme === 'dark' ? '#2E3138' : '#CFD0D1'}` }
              : {}
          }>
          <div className={s.commissionBlock}>
            <Typography>{betAmount}</Typography>
          </div>
          <div>{item.matchingInfo?.totalPool?.toFixed(2)} USDT</div>
        </div>
      )}
      {item.coefficient && (
        <div className={classNames(s.mb_10, s.row)}>
          <div className={s.commissionBlock}>
            <Typography>{dealRatio}</Typography>
          </div>
          <div>{item.coefficient}x</div>
        </div>
      )}
    </div>
  );
};

export default BetStatus;
