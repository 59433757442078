import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import cn from 'classnames';
import useTranslate from 'hooks/useTranslate.hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from 'store';

import Notification from 'components/Containers/Notification';
import { Children } from 'types/children';

import {
  changeTheme,
  setCookieNotificationShown,
  setOpenSideBar,
} from '../../../store/slices/appSlice';
import '../../../styles/notification.css';
import CookieMonkeyIcon from '../../Icons/CookieMonkeyIcon';
import Header from '../Header';
import SideBar from '../SideBar';
import s from './Layout.module.scss';

interface LayoutProps {
  children: Children;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state: RootState) => state.app.theme);
  const cookieNotificationShown = useSelector(
    (state: RootState) => state.app.cookieNotificationShown,
  );
  const isOpenSideBar = false // useSelector((state: RootState) => state.app.isOpenSideBar);
  const acceptCookies = () => localStorage.setItem('cookiesNotificationClose', 'true');

  useEffect(() => {
    if (!localStorage.getItem('cookiesNotificationClose') && !cookieNotificationShown) {
      dispatch(setCookieNotificationShown(true));
      notifyCokie();
    }
  }, []);
  const [currentLang, setCurrentLang] = useState('en');
  const navigate = useNavigate();
  const locales = ['en', 'ru'];
  const notification = true;
  const [isConnectWallet, setIsConnectWallet] = useState(false);
  const [isPortfolio, setIsPortfolio] = useState(false);
  const customTextToConnect = 'asdasdiaghsfiuagsfitg178fg782';
  const location = useLocation();

  const leaderboard = location.pathname === '/leaderboard';

  const handleOpenSideBar = () => {
    dispatch(setOpenSideBar(!isOpenSideBar));
  };

  const handleChangeTheme = () => {
    if (theme === 'default') {
      dispatch(changeTheme('dark'));
    } else {
      dispatch(changeTheme('default'));
    }
  };

  useEffect(() => {
    if (window.innerWidth < 1024) {
      document.body.style.overflow = isOpenSideBar ? 'hidden' : 'auto';
    }
  }, [isOpenSideBar]);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      dispatch(setOpenSideBar(false));
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === '/portfolio') setIsPortfolio(true);
    else setIsPortfolio(false);
  }, [location]);

  const handleAddDeal = () => {
    navigate('/new-bet');
  };
  const handleSideBarFinanceButton = () => navigate('/bets');
  const handleSideBarJackpotButton = () => navigate('/jack-pool');
  const handleSideBarOptionalButton = () => navigate('/up-down');
  const handleSideBarLoteryButton = () => navigate('/darts/10');

  const contentClasses = classNames(s.content, s[theme], isOpenSideBar && s[`content-open`]);
  const cookiesNotificationTitle = useTranslate('notification.we-use-cookie');
  const cookiesNotificationOkButtonText = useTranslate('notification.cookie-ok');
  const cookiesNotificationText = useTranslate('notification.collection-cookie');
  const notifyCokie = () =>
    toast(
      () => (
        <div className="cookies-notification">
          <div className="cookies-notification-center">
            <div style={{ marginRight: 15 }}>
              <CookieMonkeyIcon />
            </div>
            <p>
              <span>{cookiesNotificationText}</span>
              <br />
              {cookiesNotificationTitle}
            </p>
          </div>
          <button onClick={acceptCookies} className="accept-cookies-button">
            {cookiesNotificationOkButtonText}
          </button>
        </div>
      ),
      {
        className: 'black-background notification-notif',
        closeButton: false,
      },
    );
  return (
    <div className={s.layout + ' ' + (isPortfolio ? s.portfolio : '')}>
      <Header
        changeTheme={handleChangeTheme}
        connectText={customTextToConnect}
        isConnectWallet={isConnectWallet}
        isOpenSideBar={isOpenSideBar}
        notification={notification}
        setOpenSideBar={handleOpenSideBar}
        theme={theme}
      />
      <div className={`${s.wrapper} ${s[theme]}`}>
        {isOpenSideBar ? (
          <SideBar
            addDealFunction={handleAddDeal}
            currentLang={currentLang}
            financeBtnFunction={handleSideBarFinanceButton}
            locales={locales}
            changeTheme={handleChangeTheme}
            lotteryBtnFunction={handleSideBarLoteryButton}
            optionalBtnFunction={handleSideBarOptionalButton}
            jackbotBtnFunction={handleSideBarJackpotButton}
            setCurrentLang={setCurrentLang}
            setOpenSideBar={handleOpenSideBar}
            theme={theme}
          />
        ) : null}
        <div className={cn(contentClasses, leaderboard && s.leaderboard)}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
