import { IconsTypes } from 'types/baseTypes';

const OptionalIcon = ({ size = '25', className, color = '#898A8E' }: IconsTypes) => (
  <svg width='176' height='30' viewBox='0 0 176 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='122.065' y='9.3418' width='52.9346' height='11.8833' rx='1' fill='#0075FF' />
    <path
      d='M127.564 17.9842V14.0203H125.482V12.8302H131.371V14.0203H129.262V17.9842H127.564Z'
      fill='white'
    />
    <path
      d='M132.245 17.9842V12.839H137.863V14.029H133.951V14.7553H136.848V15.9716H133.951V16.7854H137.723L138.248 17.8879C138.248 17.9113 138.169 17.9346 138.012 17.9579C137.854 17.9754 137.583 17.9842 137.198 17.9842H132.245Z'
      fill='white'
    />
    <path
      d='M140.113 17.9842C139.716 17.9842 139.424 17.9667 139.238 17.9317C139.051 17.8967 138.958 17.8559 138.958 17.8092L139.483 16.6804C139.617 16.7037 139.894 16.7271 140.314 16.7504C140.734 16.7679 141.279 16.7766 141.95 16.7766H142.642C142.846 16.7766 142.983 16.7475 143.053 16.6891C143.123 16.6308 143.158 16.5462 143.158 16.4354V16.3479C143.158 16.2079 143.123 16.1116 143.053 16.0591C142.983 16.0066 142.846 15.9803 142.642 15.9803H140.638C140.049 15.9803 139.62 15.8724 139.351 15.6566C139.083 15.4349 138.949 15.0761 138.949 14.5803V14.2653C138.949 14.0086 138.996 13.7723 139.089 13.5565C139.188 13.3406 139.354 13.1685 139.588 13.0402C139.821 12.906 140.142 12.839 140.55 12.839H143.455C143.811 12.839 144.106 12.8594 144.339 12.9002C144.578 12.941 144.698 12.9848 144.698 13.0315L144.435 14.1165C144.272 14.099 143.963 14.0815 143.508 14.064C143.059 14.0407 142.487 14.029 141.793 14.029L141.163 14.0203C140.982 14.0203 140.854 14.0465 140.778 14.099C140.702 14.1457 140.661 14.2361 140.655 14.3703V14.4315C140.655 14.5657 140.696 14.659 140.778 14.7115C140.859 14.764 140.991 14.7903 141.172 14.7903H143.097C143.47 14.7903 143.788 14.8282 144.05 14.904C144.313 14.9799 144.514 15.1199 144.654 15.3241C144.794 15.5224 144.864 15.817 144.864 16.2079V16.5229C144.864 16.9604 144.75 17.3133 144.523 17.5817C144.301 17.85 143.913 17.9842 143.359 17.9842H140.113Z'
      fill='white'
    />
    <path
      d='M147.646 17.9842V14.0203H145.563V12.8302H151.452V14.0203H149.344V17.9842H147.646Z'
      fill='white'
    />
    <path
      d='M152.414 17.9842V13.539L152.326 12.839H153.937L156.404 15.3416L156.745 15.7266H156.842V12.839H158.522V17.9842H157.06L154.523 15.4553L154.182 15.0441H154.094V17.9842H152.414Z'
      fill='white'
    />
    <path
      d='M159.923 17.9842V12.839H165.541V14.029H161.63V14.7553H164.526V15.9716H161.63V16.7854H165.401L165.926 17.8879C165.926 17.9113 165.847 17.9346 165.69 17.9579C165.532 17.9754 165.261 17.9842 164.876 17.9842H159.923Z'
      fill='white'
    />
    <path
      d='M168.36 17.9842V14.0203H166.277V12.8302H172.166V14.0203H170.057V17.9842H168.36Z'
      fill='white'
    />
    <path
      d='M11.2123 1.3549L0.680824 7.36986C0.136165 7.68093 0 8.28122 0 8.54244V10.4316C0 11.0396 0.453882 10.9021 0.680824 10.7573C4.09203 8.72338 11.0421 4.5817 11.5527 4.28638C12.0633 3.99106 12.6165 4.16333 12.8293 4.28638C15.6377 5.92945 21.3608 9.28074 21.7863 9.54132C22.2118 9.8019 22.3324 10.3737 22.3395 10.6271V14.0363C22.3395 14.5748 21.8715 14.9555 21.6374 15.0786C20.9069 15.4549 19.3013 16.2815 18.7226 16.5768C18.1439 16.8722 17.517 16.6999 17.2759 16.5768C14.0846 14.818 7.59545 11.2525 7.16991 11.0614C6.74443 10.8703 6.34018 10.9817 6.19124 11.0614L0.659544 14.4923C0.14893 14.7702 0.0070919 15.4477 0 15.7517V22.0272C0 22.7916 0.524801 23.1853 0.787201 23.2867L11.8719 29.7794C12.5016 30.1789 13.1413 29.9459 13.3825 29.7794C16.7653 27.8396 23.6756 23.8816 24.2543 23.569C24.833 23.2563 24.9919 22.5556 24.999 22.2444V20.5941C24.999 19.9166 24.5025 20.0512 24.2543 20.2032C21.0984 22.0779 14.5824 25.962 13.7654 26.5005C12.9484 27.039 12.1626 26.7248 11.8719 26.5005C9.21948 24.9659 3.78708 21.8318 3.27646 21.5712C2.76585 21.3107 2.62401 20.6375 2.61691 20.3335V17.2934C2.61691 16.616 3.05661 16.157 3.27646 16.0123C3.88636 15.701 5.40829 14.7323 6.19124 14.3328C6.97416 13.9333 7.57416 14.4631 7.85071 14.5934C10.8151 16.2002 16.8461 19.4953 17.5099 19.8775C18.1737 20.2597 18.8361 20.0367 19.0843 19.8775L24.2543 16.642C24.8841 16.4336 25.0132 15.5852 24.999 15.1871V9.30246C24.999 8.27754 24.5025 7.84758 24.2543 7.7607L23.616 7.36986C20.6587 5.63269 14.493 1.99765 13.4888 1.3549C12.4846 0.712144 11.5527 1.08708 11.2123 1.3549Z'
      fill='#00D355'
    />
    <path
      d='M18.3791 10.5875L13.4069 7.76923C12.778 7.41269 12.2948 7.56282 12.132 7.68248C11.9124 7.86316 11.367 8.31554 10.942 8.67977C10.517 9.04393 10.8216 9.40962 11.027 9.54692C13.0103 10.6598 17.0745 12.942 17.4655 13.1674C17.8564 13.3929 18.195 13.4203 18.3154 13.4059C19.1016 13.3625 19.0804 12.2785 19.0804 11.6281C19.0804 11.1079 18.6129 10.7176 18.3791 10.5875Z'
      fill='#00D355'
    />
    <path
      d='M6.75495 20.3734L11.7272 23.1918C12.3561 23.5482 12.8392 23.3981 13.0021 23.2785C13.2217 23.0978 13.7671 22.6455 14.1921 22.2812C14.6171 21.917 14.3125 21.5513 14.107 21.414C12.1238 20.3011 8.05959 18.019 7.66861 17.7936C7.27768 17.5681 6.93911 17.5406 6.81864 17.5551C6.03246 17.5984 6.05371 18.6824 6.05371 19.3328C6.05371 19.8531 6.52117 20.2433 6.75495 20.3734Z'
      fill='#00D355'
    />
    <path
      d='M34.7158 20.6826V11.6094V10.1627H43.0661C43.8895 10.1627 44.547 10.2519 45.0385 10.4305C45.5423 10.5973 45.9172 10.8294 46.163 11.1271C46.421 11.4129 46.5869 11.7522 46.6607 12.1452C46.7467 12.5262 46.7897 12.931 46.7897 13.3597V14.7707C46.7897 15.3422 46.6914 15.8721 46.4947 16.3603C46.3104 16.8366 45.9602 17.2235 45.4441 17.5212C44.9402 17.807 44.209 17.9499 43.2505 17.9499H41.9786C41.2044 17.9499 40.4668 17.9856 39.791 17.9499C38.9985 17.9499 38.6421 17.9737 38.3104 17.9499V20.6826H34.7158ZM38.3104 15.5208H42.1261C42.5316 15.5208 42.8142 15.4434 42.974 15.2887C43.1337 15.1338 43.2136 14.8838 43.2136 14.5385V13.6276C43.2136 13.2823 43.1399 13.0323 42.9924 12.8775C42.845 12.7108 42.5623 12.6274 42.1445 12.6274H38.3104V15.5208Z'
      fill='#302779'
    />
    <path
      d='M34.7158 20.6826V11.6094V10.1627H43.0661C43.8895 10.1627 44.547 10.2519 45.0385 10.4305C45.5423 10.5973 45.9172 10.8294 46.163 11.1271C46.421 11.4129 46.5869 11.7522 46.6607 12.1452C46.7467 12.5262 46.7897 12.931 46.7897 13.3597V14.7707C46.7897 15.3422 46.6914 15.8721 46.4947 16.3603C46.3104 16.8366 45.9602 17.2235 45.4441 17.5212C44.9402 17.807 44.209 17.9499 43.2505 17.9499H41.9786C41.2044 17.9499 40.4668 17.9856 39.791 17.9499C38.9985 17.9499 38.6421 17.9737 38.3104 17.9499V20.6826H34.7158ZM38.3104 15.5208H42.1261C42.5316 15.5208 42.8142 15.4434 42.974 15.2887C43.1337 15.1338 43.2136 14.8838 43.2136 14.5385V13.6276C43.2136 13.2823 43.1399 13.0323 42.9924 12.8775C42.845 12.7108 42.5623 12.6274 42.1445 12.6274H38.3104V15.5208Z'
      fill={color}
    />
    <path
      d='M48.6274 20.681V18.5913C48.8118 18.4722 49.0883 18.3055 49.457 18.0912C49.8256 17.865 50.2496 17.6089 50.7288 17.3231C51.2081 17.0374 51.6997 16.7397 52.2035 16.4301C52.7074 16.1205 53.1867 15.8229 53.6413 15.5371C54.096 15.2514 54.4831 14.9953 54.8026 14.7691C55.2082 14.4953 55.4847 14.2511 55.6321 14.0368C55.7796 13.8225 55.8533 13.5962 55.8533 13.3581V13.1081C55.8533 12.8699 55.7796 12.7032 55.6321 12.6079C55.4969 12.5008 55.2266 12.4472 54.821 12.4472H53.8994C52.6951 12.4472 51.7511 12.4472 50.8865 12.4472C50.0219 12.4472 49.4455 12.4472 48.8118 12.4472V9.98242C49.1574 9.98242 49.0132 9.98242 49.5896 9.98242C50.2381 9.98242 50.8885 9.98242 51.9672 9.98242C52.9038 9.98242 54.01 9.98242 55.1897 9.98242H55.3188C56.3756 9.98242 57.2051 10.1015 57.8073 10.3396C58.4094 10.5659 58.8334 10.8874 59.0791 11.3041C59.325 11.7209 59.4478 12.209 59.4478 12.7687V13.1438C59.4478 13.9773 59.1959 14.656 58.6921 15.1799C58.1882 15.7038 57.5431 16.186 56.7565 16.6266C56.1421 16.9719 55.5891 17.2636 55.0976 17.5018C54.6183 17.7399 54.2251 17.9423 53.9178 18.1091V18.2519H60.0008V20.681H48.6274Z'
      fill='#302779'
    />
    <path
      d='M48.6274 20.681V18.5913C48.8118 18.4722 49.0883 18.3055 49.457 18.0912C49.8256 17.865 50.2496 17.6089 50.7288 17.3231C51.2081 17.0374 51.6997 16.7397 52.2035 16.4301C52.7074 16.1205 53.1867 15.8229 53.6413 15.5371C54.096 15.2514 54.4831 14.9953 54.8026 14.7691C55.2082 14.4953 55.4847 14.2511 55.6321 14.0368C55.7796 13.8225 55.8533 13.5962 55.8533 13.3581V13.1081C55.8533 12.8699 55.7796 12.7032 55.6321 12.6079C55.4969 12.5008 55.2266 12.4472 54.821 12.4472H53.8994C52.6951 12.4472 51.7511 12.4472 50.8865 12.4472C50.0219 12.4472 49.4455 12.4472 48.8118 12.4472V9.98242C49.1574 9.98242 49.0132 9.98242 49.5896 9.98242C50.2381 9.98242 50.8885 9.98242 51.9672 9.98242C52.9038 9.98242 54.01 9.98242 55.1897 9.98242H55.3188C56.3756 9.98242 57.2051 10.1015 57.8073 10.3396C58.4094 10.5659 58.8334 10.8874 59.0791 11.3041C59.325 11.7209 59.4478 12.209 59.4478 12.7687V13.1438C59.4478 13.9773 59.1959 14.656 58.6921 15.1799C58.1882 15.7038 57.5431 16.186 56.7565 16.6266C56.1421 16.9719 55.5891 17.2636 55.0976 17.5018C54.6183 17.7399 54.2251 17.9423 53.9178 18.1091V18.2519H60.0008V20.681H48.6274Z'
      fill={color}
    />
    <path
      d='M62.0233 20.6826V11.6094V10.1627H70.3739C71.1971 10.1627 71.8544 10.2519 72.3458 10.4305C72.8501 10.5973 73.2246 10.8294 73.4706 11.1271C73.7282 11.4129 73.8942 11.7522 73.9684 12.1452C74.0543 12.5262 74.0969 12.931 74.0969 13.3597V14.7707C74.0969 15.3422 73.9988 15.8721 73.8025 16.3603C73.6178 16.8366 73.2679 17.2235 72.7513 17.5212C72.2477 17.807 71.5168 17.9499 70.5579 17.9499H69.2859C68.5117 17.9499 67.817 17.9499 67.0247 17.9499C66.3758 17.9499 65.9716 17.9499 65.6177 17.9499V20.6826H62.0233ZM65.6177 15.5208H69.4338C69.8393 15.5208 70.1214 15.4434 70.2815 15.2887C70.441 15.1338 70.5211 14.8838 70.5211 14.5385V13.6276C70.5211 13.2823 70.4475 13.0323 70.2996 12.8775C70.1524 12.7108 69.8696 12.6274 69.4518 12.6274H65.6177V15.5208Z'
      fill='#302779'
    />
    <path
      d='M62.0233 20.6826V11.6094V10.1627H70.3739C71.1971 10.1627 71.8544 10.2519 72.3458 10.4305C72.8501 10.5973 73.2246 10.8294 73.4706 11.1271C73.7282 11.4129 73.8942 11.7522 73.9684 12.1452C74.0543 12.5262 74.0969 12.931 74.0969 13.3597V14.7707C74.0969 15.3422 73.9988 15.8721 73.8025 16.3603C73.6178 16.8366 73.2679 17.2235 72.7513 17.5212C72.2477 17.807 71.5168 17.9499 70.5579 17.9499H69.2859C68.5117 17.9499 67.817 17.9499 67.0247 17.9499C66.3758 17.9499 65.9716 17.9499 65.6177 17.9499V20.6826H62.0233ZM65.6177 15.5208H69.4338C69.8393 15.5208 70.1214 15.4434 70.2815 15.2887C70.441 15.1338 70.5211 14.8838 70.5211 14.5385V13.6276C70.5211 13.2823 70.4475 13.0323 70.2996 12.8775C70.1524 12.7108 69.8696 12.6274 69.4518 12.6274H65.6177V15.5208Z'
      fill={color}
    />
    <path
      d='M76.6719 20.6826V10.1627H85.0775C86.2326 10.1627 87.0746 10.3889 87.6027 10.8414C88.1316 11.2819 88.3956 11.8177 88.3956 12.4488V12.9846C88.3956 13.4728 88.2852 13.8777 88.0638 14.1992C87.8552 14.5088 87.6273 14.729 87.3819 14.86C87.9224 15.0267 88.3524 15.3184 88.672 15.7352C88.9916 16.14 89.1511 16.6759 89.1511 17.3427V17.6641C89.1511 18.0213 89.1085 18.3785 89.0226 18.7357C88.9361 19.093 88.7701 19.4204 88.5248 19.7181C88.291 20.0039 87.9534 20.2361 87.5111 20.4147C87.081 20.5933 86.5154 20.6826 85.8148 20.6826H76.6719ZM80.2664 14.217H83.9533C84.2729 14.217 84.4937 14.1515 84.6171 14.0205C84.7398 13.8896 84.8011 13.7109 84.8011 13.4847V13.3061C84.8011 13.0561 84.7398 12.8775 84.6171 12.7703C84.4937 12.6632 84.2174 12.6095 83.7874 12.6095H80.2664V14.217ZM80.2664 18.2357H84.7088C85.0284 18.2357 85.2499 18.1821 85.3725 18.0749C85.4952 17.9558 85.5572 17.7713 85.5572 17.5212V17.1997C85.5572 16.9735 85.4771 16.8009 85.317 16.6818C85.1575 16.5508 84.8812 16.4853 84.4879 16.4853H80.2664V18.2357Z'
      fill='#302779'
    />
    <path
      d='M76.6719 20.6826V10.1627H85.0775C86.2326 10.1627 87.0746 10.3889 87.6027 10.8414C88.1316 11.2819 88.3957 11.8177 88.3957 12.4488V12.9846C88.3957 13.4728 88.2852 13.8777 88.0638 14.1992C87.8552 14.5088 87.6273 14.729 87.3819 14.86C87.9224 15.0267 88.3524 15.3184 88.672 15.7352C88.9916 16.14 89.1511 16.6759 89.1511 17.3427V17.6641C89.1511 18.0213 89.1085 18.3785 89.0226 18.7357C88.9361 19.093 88.7701 19.4204 88.5248 19.7181C88.291 20.0039 87.9534 20.2361 87.5111 20.4147C87.081 20.5933 86.5154 20.6826 85.8148 20.6826H76.6719ZM80.2664 14.217H83.9533C84.2729 14.217 84.4937 14.1515 84.6171 14.0205C84.7398 13.8896 84.8011 13.7109 84.8011 13.4847V13.3061C84.8011 13.0561 84.7398 12.8775 84.6171 12.7703C84.4937 12.6632 84.2174 12.6095 83.7874 12.6095H80.2664V14.217ZM80.2664 18.2357H84.7088C85.0284 18.2357 85.2499 18.1821 85.3725 18.0749C85.4952 17.9558 85.5572 17.7713 85.5572 17.5212V17.1997C85.5572 16.9735 85.4771 16.8009 85.317 16.6818C85.1575 16.5508 84.8812 16.4853 84.4879 16.4853H80.2664V18.2357Z'
      fill={color}
    />
    <path
      d='M91.7394 20.6813V10.1792H103.574V12.6082H95.334V14.0907H101.436V16.5733H95.334V18.2343H103.279L104.418 20.6813C104.201 20.6813 104.201 20.6813 103.841 20.6813C103.481 20.6813 102.984 20.6813 102.172 20.6813H91.7394Z'
      fill='#302779'
    />
    <path
      d='M91.7394 20.6813V10.1792H103.574V12.6082H95.334V14.0907H101.436V16.5733H95.334V18.2343H103.279L104.418 20.6813C104.201 20.6813 104.201 20.6813 103.841 20.6813C103.481 20.6813 102.984 20.6813 102.172 20.6813H91.7394Z'
      fill={color}
    />
    <path
      d='M109.512 20.6826V12.5917H105.125V10.1627H117.531V12.5917H113.088V20.6826H109.512Z'
      fill='#302779'
    />
    <path
      d='M109.512 20.6826V12.5917H105.125V10.1627H117.531V12.5917H113.088V20.6826H109.512Z'
      fill={color}
    />
  </svg>
);

export default OptionalIcon;
