import { forwardRef, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { WebsocketContext } from 'context/websocket';
import useTranslate from 'hooks/useTranslate.hook';
import { createChart } from 'lightweight-charts';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useLazyGetHistoricalPricesQuery } from 'store/services/сhainLinkService';

import Translated from 'components/Containers/Translated';
import { TooltipIcon } from 'components/Icons';
import Tooltip from 'components/Simple/Tooltip';

import s from './Chart.module.scss';
import ChartHeader from './chart_header';
import { chartOptions, sources, themesData, timeToLocal } from './mock_data';

const Chart2 = forwardRef(
  (
    props: {
      theme: 'default' | 'dark';
      colors: {
        backgroundColor?: 'white' | undefined;
        lineColor?: '#2962FF' | undefined;
        textColor?: 'black' | undefined;
        areaTopColor?: '#2962FF' | undefined;
        areaBottomColor?: 'rgba(41, 98, 255, 0.28)' | undefined;
      };
      handleCurrent?: (value: number) => void;
      type: string;
      scale?: number;
      name?: string;
      eventId?: string;
      loading?: boolean;
    },
    ref: any,
  ) => {
    const {
      eventId,
      colors: {
        backgroundColor = 'white',
        lineColor = '#2962FF',
        textColor = 'black',
        areaTopColor = '#2962FF',
        areaBottomColor = 'rgba(41, 98, 255, 0.28)',
      },
      handleCurrent,
      theme = 'default',
      type,
      name,
      scale = 2,
      loading,
    } = props;
    const [direction, setDirection] = useState('up');
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<any>(null);
    const [current, setCurrent] = useState(0);
    const source = 'CHAINLINK';
    const dataSourceTooltip = useTranslate('tooltip.data-source.chainlink');
    const isOpenSideBar = useSelector((state: RootState) => state.app.isOpenSideBar);

    const [timeFrame, setTimeFrame] = useState<number | string>('PT1M');

    const [getHistoricalPrices, { data: historicalPrices }] = useLazyGetHistoricalPricesQuery();

    const changeTimeFrame = useCallback((val: number | string) => {
      setTimeFrame(val);
    }, []);

    useEffect(() => {
      eventId &&
        getHistoricalPrices({
          eventId,
          frame: timeFrame,
          size: 500,
        });
    }, [timeFrame, eventId, getHistoricalPrices]);

    useEffect(() => {
      chartRef.current?.applyOptions({ width: chartContainerRef.current?.clientWidth });
    }, [isOpenSideBar]);

    useEffect(() => {
      const interval = setInterval(() => {
        getHistoricalPrices({
          eventId,
          frame: timeFrame,
          size: 500,
        });
      }, 15 * 1000);

      return () => {
        clearInterval(interval);
      };
    }, [getHistoricalPrices, eventId, timeFrame]);

    useEffect(() => {
      if (historicalPrices?.content) {
        ref.current.setData(
          historicalPrices.content
            .map((item: any) => ({
              time: new Date(item.created).getTime() / 1000,
              value: item.amount.toFixed(scale || 2),
            }))
            .reverse(),
        );
        handleCurrent && handleCurrent(historicalPrices.content[0].amount.toFixed(scale || 2));
        setCurrent(historicalPrices.content[0].amount.toFixed(scale || 2));
      }
    }, [historicalPrices]);

    useEffect(() => {
      chartRef.current?.applyOptions(themesData[theme].chart);
    }, [ref, chartRef, theme, scale, loading]);

    useEffect(() => {
      if (chartRef.current && chartContainerRef.current) {
        chartRef.current.applyOptions({ width: chartContainerRef.current.clientWidth });
      }
    }, [chartRef, chartContainerRef, loading]);

    useEffect(() => {
      const chart = createChart(
        // @typescript-eslint/ban-ts-comment
        // @ts-ignore
        chartContainerRef.current,
        chartOptions(backgroundColor, textColor),
      );

      chartRef.current = chart;

      const handleResize = () => {
        chart.applyOptions({ width: chartContainerRef.current?.clientWidth });
      };

      ref.current = chart.addAreaSeries({
        topColor: 'rgba(21, 80, 150, 0.1)',
        bottomColor: '#171A22',
        lineColor: 'rgba(0, 117, 255, 1)',
        lineWidth: 2,
      });
      window.addEventListener('resize', handleResize);
      handleResize();

      chart.applyOptions(themesData[theme].chart);

      return () => {
        window.removeEventListener('resize', handleResize);

        chart.remove();
      };
    }, [
      chartRef,
      ref,
      backgroundColor,
      lineColor,
      theme,
      textColor,
      areaTopColor,
      scale,
      areaBottomColor,
    ]);

    return (
      <>
        <ChartHeader
          onChange={changeTimeFrame}
          current={current}
          direction={direction}
          name={name}
          chainlink
        />
        <div ref={chartContainerRef} />
        {/*@ts-ignore*/}
        {sources[source] && (
          <div className={s.source}>
            <span className={s.sourceTitle}>
              <Translated id="options.data-source" />:{' '}
            </span>
            <img
              // @ts-ignore
              src={sources[source].image}
              height={26}
              onClick={() => {
                // @ts-ignore
                window.open(sources[source].url);
              }}
            />
            <div className={s.tooltip}>
              <Tooltip theme={theme} toolTipText={dataSourceTooltip}>
                <TooltipIcon theme={theme} />
              </Tooltip>
            </div>
          </div>
        )}
      </>
    );
  },
);

export default Chart2;
