import React from 'react';

import classNames from 'classnames';

import s from './index.module.scss';

interface PulseBulbProps {
  variant: 'open' | 'accepted' | 'waiting' | 'closed' | 'canceled';
  badge?: boolean;
  className?: string;
}

const PulseBulb = ({ variant, badge, className }: PulseBulbProps) => (
  <div className={classNames(s[variant], s.blob, badge && s.badge, className)} />
);

export default PulseBulb;
