import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApi } from 'store/consts';
import { getExecutionType } from 'store/utils/getExecutionType';

export const API_NAME = 'binaryService';

export const binaryService = createApi({
  reducerPath: API_NAME,
  baseQuery: fetchBaseQuery({ baseUrl: `${baseApi}/binary/` }),
  endpoints: (builder) => ({
    betList: builder.query({
      query: ({ period, id }) => {
        let body;

        if (id) {
          body = {
            executionType: getExecutionType(),
            page: 0,
            size: 1,
            ids: [Number(id)],
          };
        } else {
          body = {
            executionType: getExecutionType(),
            page: 0,
            size: 1,
            statusList: {
              list: ['CREATED'],
            },
            sort: {
              direction: 'DESC',
              property: 'createdDate',
            },
            periodFilter: [
              {
                period,
                condition: 'EQUALS',
              },
            ],
          };
        }

        return {
          url: `bet`,
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body,
        };
      },
    }),
    joinList: builder.query({
      query: ({ id, client, page, size }) => ({
        url: `bet/joins`,
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: {
          executionType: getExecutionType(),
          page: page || 0,
          size: size || 20,
          client,
          binaryBet: id,
          statusList: {
            list: ['JOINED', 'LOST', 'PRIZE_TAKEN', 'WON'],
          },
          sort: {
            direction: 'DESC',
            property: 'createdDate',
          },
        },
      }),
    }),
    joinAmount: builder.query({
      query: ({ client, betId, executionType }) => ({
        url: `bet/${executionType}/${betId}/joins/totalAmount?client=${client}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyBetListQuery, useLazyJoinListQuery, useLazyJoinAmountQuery } = binaryService;
