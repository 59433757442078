import React from 'react';

type CloseIconTypes = {
  size?: string;
  color?: string;
  className?: string
}

const CloseIcon = ({ size = '15', color = '#101319', className }: CloseIconTypes) => (
  <svg className={className} fill='none' height={size} viewBox='0 0 15 15' width={size}
       xmlns='http://www.w3.org/2000/svg'>
    <path clipRule='evenodd'
          d='M14.6464 1.85355C14.8417 1.65829 14.8417 1.34171 14.6464 1.14645L13.8536 0.353553C13.6583 0.158291 13.3417 0.158291 13.1464 0.353553L7.5 6L1.85355 0.353553C1.65829 0.158291 1.34171 0.158291 1.14645 0.353553L0.353553 1.14645C0.158291 1.34171 0.158291 1.65829 0.353553 1.85355L6 7.5L0.353553 13.1464C0.158291 13.3417 0.158291 13.6583 0.353553 13.8536L1.14645 14.6464C1.34171 14.8417 1.65829 14.8417 1.85355 14.6464L7.5 9L13.1464 14.6464C13.3417 14.8417 13.6583 14.8417 13.8536 14.6464L14.6464 13.8536C14.8417 13.6583 14.8417 13.3417 14.6464 13.1464L9 7.5L14.6464 1.85355Z'
          fill={color} fillRule='evenodd' />
  </svg>
);

export default CloseIcon;
