import React, { ReactElement, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import useTranslate from 'hooks/useTranslate.hook';
import { Link } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ThemeType } from 'types/baseTypes';
import { Children } from 'types/children';

import useMediaQuery from '../../../theme/useMediaQuery';
import { ChevronIcon } from '../../Icons';
import Typography from '../../Simple/Typography';
import s from './styles.module.scss';

interface SwiperSliderProps {
  theme?: ThemeType;
  children: Children | Children[];
  title?: string | ReactElement;
  link?: string;
  loop?: boolean;
  isLinkAll?: boolean;
  variant?: 'sm' | 'md' | 'lg' | 'lgg';
  hideNavigation?: boolean;
  slidesPerView?: 'auto' | number;
}

interface LinkCustomProps {
  to: string;
  label: string;
}

const LinkCustom = ({ to, label }: LinkCustomProps) => (
  <Link to={to}>
    <Typography color="primary">{label}</Typography>
  </Link>
);

const SwiperSlider = ({
  loop = true,
  theme = 'default',
  children,
  title = '',
  isLinkAll,
  variant = 'lg',
  slidesPerView = 'auto',
  link = '',
  hideNavigation,
}: SwiperSliderProps) => {
  const useSwiperRef = <T extends HTMLElement>(): [T | null, React.Ref<T>] => {
    const [wrapper, setWrapper] = useState<T | null>(null);
    const ref = useRef<T>(null);

    useEffect(() => {
      if (ref.current) {
        setWrapper(ref.current);
      }
    }, []);

    return [wrapper, ref];
  };
  const [nextEl, nextElRef] = useSwiperRef<HTMLDivElement>();
  const [prevEl, prevElRef] = useSwiperRef<HTMLDivElement>();
  const isLaptop = useMediaQuery(480);
  const isArrayChildren = Array.isArray(children);
  const viewAll = useTranslate('app.show-all');
  return (
    <div className={classNames(s.main, isLinkAll, hideNavigation && s.hideNavigation)}>
      <div className={title === '' ? s.emptyTitle : s.title}>
        <Typography variant="h1">{title}</Typography>
        {isLinkAll && !isLaptop && <LinkCustom label={viewAll} to={link} />}
      </div>
      {!isLaptop || variant !== 'lg' || hideNavigation ? (
        <div className={variant === 'lg' ? s.largeWrapper : s.wrapper}>
          <Swiper
            style={{ overflow: 'visible' }}
            breakpoints={{
              320: {
                spaceBetween: 15,
              },
              768: {
                spaceBetween: hideNavigation ? 15 : 25,
              },
            }}
            className="mySwiper"
            loop={loop}
            modules={[Pagination, Navigation]}
            navigation={
              hideNavigation
                ? false
                : {
                    prevEl,
                    nextEl,
                  }
            }
            slidesPerView={slidesPerView}
            spaceBetween={hideNavigation ? 15 : 25}>
            {isArrayChildren ? (
              (children as Children[]).map((reactNode) => (
                <SwiperSlide
                  className={classNames(s.item, {
                    [s.sm]: variant === 'sm',
                    [s.md]: variant === 'md',
                    [s.lg]: variant === 'lg',
                    [s.lgg]: variant === 'lgg',
                  })}
                  key={Math.random()}>
                  {reactNode}
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide
                className={classNames(s.item, {
                  [s.sm]: variant === 'sm',
                  [s.md]: variant === 'md',
                  [s.lg]: variant === 'lg',
                  [s.lgg]: variant === 'lgg',
                })}>
                {children}
              </SwiperSlide>
            )}
          </Swiper>
          {!hideNavigation && (
            <div className={s.arrowPrev} ref={prevElRef}>
              <ChevronIcon className={classNames(s.iconPrev, s[`iconPrev-${theme}`])} />
            </div>
          )}
          {!hideNavigation && (
            <div className={s.arrowNext} ref={nextElRef}>
              <ChevronIcon className={classNames(s.iconNext, s[`iconNext-${theme}`])} />
            </div>
          )}
        </div>
      ) : (
        <div className={s.laptopWrapper}>
          {isArrayChildren ? (
            <>
              <SwiperSlide className={s.item}>{children[0]}</SwiperSlide>
              <SwiperSlide className={s.item}>{children[1]}</SwiperSlide>
            </>
          ) : (
            <SwiperSlide className={s.item}>{children}</SwiperSlide>
          )}
          <div className={s.laptopLink}>
            <LinkCustom label={viewAll} to={link} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SwiperSlider;
