import { useCallback, useEffect, useRef } from 'react';

import cn from 'classnames';
import useClickOutside from 'hooks/useClickOutside';

import { CloseIcon } from 'components/Icons';
import { Children } from 'types/children';

import s from './Drawer.module.scss';

interface DrawerProps {
  visible: boolean;
  children?: Children;
  small?: boolean;
  onVisibleChange: (value: any) => void;
}

export default function Drawer({ visible, small, onVisibleChange, children }: DrawerProps) {
  const ref = useRef(null);

  const handleClickOutside = useCallback(() => {
    visible && onVisibleChange(null);
  }, [visible]);

  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'auto';
  }, [visible]);

  useClickOutside(ref, handleClickOutside);

  return (
    <div className={cn(s.wrapper, visible && s.visible, small && s.small)} ref={ref}>
      <div className={s.close} onClick={() => onVisibleChange(false)}>
        <CloseIcon color={'#FFF'} />
      </div>
      <div className={s.pane}>
        <div className={s.content}>{children}</div>
      </div>
    </div>
  );
}
