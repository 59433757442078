import React from 'react';

import cn from 'classnames';

import loader from 'components/Icons/Preloader.png';

import s from './MainLoader.module.scss';

interface MainLoaderProps {
  chartLoader?: boolean;
  text?: string;
}

export function MainLoader(props: MainLoaderProps) {
  const { chartLoader, text } = props;
  return (
    <div className={cn(s.wrapper, chartLoader && s.chartLoader)}>
      <div className={s.inner}>
        <img src={loader} width={60} />
        {text && <div className={s.text}>{text}</div>}
      </div>
    </div>
  );
}
