import React from 'react';
import { ThemeType } from 'types/baseTypes';

interface TooltipIconProps {
  size?: string;
  className?: string;
  theme?: ThemeType;
}

const TooltipIcon = ({ size = '17', className, theme }: TooltipIconProps) => (
  <svg className={className} fill='none' height={String(+size + 1)} viewBox='0 0 17 17' width={size}
       xmlns='http://www.w3.org/2000/svg'>
    {theme === 'dark' ? <>
        <circle cx='8.5' cy='9' fill='#2A2C33"' r='8.5' />
        <circle cx='8.5' cy='9' fill='#898A8E' r='7.5' />
      </>
      : <>
        <circle cx='8.5' cy='9' fill='#CFD0D1' r='8.5' />
        <circle cx='8.5' cy='9' fill='#898A8E' r='7.5' />
      </>
    }
    <path clipRule='evenodd'
          d='M16 9C16 13.1423 12.6423 16.5 8.5 16.5C4.35775 16.5 1 13.1423 1 9C1 4.85775 4.35775 1.5 8.5 1.5C12.6423 1.5 16 4.85775 16 9ZM7.5625 12.1823C7.5625 11.64 7.975 11.25 8.49475 11.25C9.02575 11.25 9.4375 11.64 9.4375 12.1823C9.4375 12.7238 9.025 13.125 8.49475 13.125C8.37101 13.1265 8.24822 13.1031 8.13373 13.0562C8.01923 13.0092 7.91537 12.9397 7.82836 12.8517C7.74134 12.7637 7.67296 12.659 7.62728 12.544C7.5816 12.429 7.55957 12.306 7.5625 12.1823ZM8.66875 4.875C7.051 4.875 5.8375 5.80725 5.8375 7.3125V7.33125C5.8375 7.43071 5.87701 7.52609 5.94734 7.59642C6.01766 7.66674 6.11304 7.70625 6.2125 7.70625H6.81475C6.99475 7.70625 7.141 7.56 7.141 7.38C7.141 6.53775 7.792 6.0885 8.66875 6.0885C9.52225 6.0885 10.084 6.53775 10.084 7.17825C10.084 7.785 9.769 8.0655 8.983 8.4255L8.758 8.526C8.09575 8.8185 7.84825 9.2565 7.84825 10.0208V10.125C7.84825 10.2245 7.88776 10.3198 7.95809 10.3902C8.02841 10.4605 8.12379 10.5 8.22325 10.5H8.77675C8.87621 10.5 8.97159 10.4605 9.04191 10.3902C9.11224 10.3198 9.15175 10.2245 9.15175 10.125V10.0995C9.15175 9.762 9.24175 9.627 9.51175 9.504L9.736 9.40275C10.6795 8.97525 11.3875 8.41425 11.3875 7.1895V7.122C11.3875 5.82975 10.264 4.875 8.66875 4.875Z'
          fill={theme === 'dark' ? '#101319' : '#F1F1F5'} fillRule='evenodd' />
  </svg>
);

export default TooltipIcon;

