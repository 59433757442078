import React from 'react';

import { useAppSelector } from 'hooks/store';
import { useSelector } from 'react-redux';

import TransactionCard from 'components/Simple/TransactionCard';
import { formatDate } from 'helpers/formatDate';
import getEventById from 'helpers/getEventById';
import { AppLangs } from 'types/baseTypes';

import { TransactionRow } from './TransactionRow';

export const TransactionTable = ({ transactions, events }: { transactions: any; events: any }) => {
  const lang = useAppSelector((state) => state.app.lang);
  const theme = useAppSelector((state) => state.app.theme);

  const groupedData = transactions?.result.reduce((acc: any, transaction: any) => {
    const event = getEventById(transaction.betInfo.eventId, events?.result);
    let content;
    let type;
    if (lang === AppLangs.ru) {
      type = 'Добавление ставки';

      if (transaction.logSc?.logType.indexOf('PRIZE_TAKEN') > -1) {
        type = 'Получение приза';
      }

      if (transaction.logSc?.logType.indexOf('REFUNDED') > -1) {
        type = 'Возврат';
      }

      content = `Будет ли цена ${event?.name} ${
        transaction.betInfo.customBetAdditionInfo?.targetSide ? 'выше' : 'ниже'
      } $${transaction.betInfo.customBetAdditionInfo?.targetValue}?`;

      if (transaction.betType === 'BINARY') {
        content = `Спрогнозируйте рост или падение курса BTC ${formatDate(
          transaction.betInfo.expirationDate,
        )}`;
      }

      content = transaction.betInfo.customBetAdditionInfo
        ? `Будет ли цена ${event?.name} ${
          transaction.betInfo.customBetAdditionInfo?.targetSide ? 'выше' : 'ниже'
        } $${transaction.betInfo.customBetAdditionInfo?.targetValue}?`
        : 'Спрогнозируйте рост или падение курса BTC';
    } else {
      type = 'Adding a bet';

      if (transaction.logSc?.logType.indexOf('PRIZE_TAKEN') > -1) {
        type = 'Reward';
      }

      if (transaction.logSc?.logType.indexOf('REFUNDED') > -1) {
        type = 'Refund';
      }

      content = `Will the  ${event?.name} price  be ${
        transaction.betInfo.customBetAdditionInfo?.targetSide ? 'higher' : 'lower'
      } than ${transaction.betInfo.customBetAdditionInfo?.targetValue}$?`;

      if (transaction.betType === 'BINARY') {
        content = `Predict increase or decrease of the BTC rate ${formatDate(
          transaction.betInfo.expirationDate,
        )}`;
      }

      content = transaction.betInfo.customBetAdditionInfo
        ? `Will the ${event?.name} price be ${
          transaction.betInfo.customBetAdditionInfo?.targetSide ? 'higher' : 'lower'
        } than ${transaction.betInfo.customBetAdditionInfo?.targetValue}$?`
        : 'Predict increase or decrease of the BTC rate';
    }
    content += ' ' + formatDate(transaction.betInfo.expirationDate);
    const date = new Date(transaction.createdDate + 'Z').toLocaleString().split(',')[0];
    if (!acc[date]) {
      acc[date] = [];
    }
    const amount =
      transaction.logSc?.mainAmount ||
      transaction.logSc?.amount ||
      transaction.logSc?.mainTokenRefunded;
    acc[date].push(
      <TransactionCard
        actionsBetTitle={type}
        actionValue={
          amount
            ? `${(amount / 10 ** 18).toFixed(2)} USDT`
            : transaction.betInfo?.requestAmount
              ? transaction.betInfo?.requestAmount.toFixed(2) + ' USDT'
              : ''
        }
        content={content}
        date={formatDate(transaction.createdDate)}
        dateContent={formatDate(transaction.betInfo.expirationDate)}
        idDeal={transaction.betId}
        theme={theme}
      />,
    );
    return acc;
  }, {});
  return (
    <>
      {Object.keys(groupedData).map((key: any, i: number) => (
        <TransactionRow key={i} date={key} item={groupedData[key]} />
      ))}{' '}
    </>
  );
};
