import React, { useEffect } from 'react';

import { CryptoProvider } from 'context';
import { WebsocketProvider } from 'context/websocket';
import CryptoJs from 'crypto-js';
import 'odometer/themes/odometer-theme-default.css';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { StompSessionProvider, useSubscription } from 'react-stomp-hooks';
import { ToastContainer } from 'react-toastify';
import { setNow } from 'store/slices/appSlice';

import JackPot from 'pages/jackPot';
import NotFound from 'pages/notFound';
import Portfolio from 'pages/portfolio';

import { NotificationProvider } from './context/NotificationProvider';
import { useAppSelector } from './hooks/store';
import { useConnectWallet } from './hooks/web3/useConnectWallet';

const App = React.memo(() => {
  const dispatch = useDispatch();
  const { autoConnect } = useConnectWallet();

  useEffect(() => {
    autoConnect();
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      dispatch(setNow(Date.now()));
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <>
      <WebsocketProvider>
        <CryptoProvider>
          <Routes>
            <Route element={<Portfolio />} path="/portfolio" />
            <Route element={<JackPot />} path="/id/:id" />
            <Route element={<JackPot />} path="/" />
            <Route element={<NotFound />} path="/*" />
          </Routes>
        </CryptoProvider>
      </WebsocketProvider>
      <ToastContainer position="bottom-right" hideProgressBar={true} icon={true} theme="colored" />
    </>
  );
});

export default App;
