import React, { useContext, useEffect, useRef, useState } from 'react';

import { options, str } from 'MocData/MocData';
import classNames from 'classnames';
import { CryptoContext } from 'context';
import useJackpot from 'hooks/useJackpot';
import { useJackpotJoins } from 'hooks/useJackpotJoins';
import useTranslate from 'hooks/useTranslate.hook';
import useWrapAccount from 'hooks/useWrapAccount';
import ReactOdometer from 'react-odometerjs';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useEventsListQuery } from 'store/services/eventsService';
import {
  useLazyBetListQuery,
  useLazyGetNextQuery,
  useLazyJoinListQuery,
  useLazyLeaderQuery,
} from 'store/services/jackpotService';
import { useLazyGetHistoricalPricesQuery } from 'store/services/сhainLinkService';
import useMediaQuery from 'theme/useMediaQuery';

import BetStatus from 'components/Containers/BetStatus';
import JackpotBetDrawerContent from 'components/Containers/JackpotBetDrawerContent/JackpotBetDrawerContent';
import Layout from 'components/Containers/Layout';
import Modal from 'components/Containers/Modal';
import OptionBetDrawerContent from 'components/Containers/OptionBetDrawerContent/OptionBetDrawerContent';
import PlaceYourBet from 'components/Containers/PlaceYourBet';
import RowBet from 'components/Containers/RowBet';
import StringTabContent from 'components/Containers/StringTabContent';
import TableMain from 'components/Containers/Table/TableMain';
import TableRow from 'components/Containers/Table/TableRow';
import TotalBlock from 'components/Containers/TotalBlock';
import Winners from 'components/Containers/Winners';
import { BtcIcon, TetherIcon } from 'components/Icons';
import Button from 'components/Simple/Button';
import Drawer from 'components/Simple/Drawer/Drawer';
import { MainLoader } from 'components/Simple/MainLoader/MainLoader';
import Tabs from 'components/Simple/Tabs';
import Typography from 'components/Simple/Typography';
import Chart1 from 'components/chart1';
import Chart2 from 'components/chart2';
import formatAddress from 'helpers/formatAddress';
import { formatDate } from 'helpers/formatDate';
import formatLeft from 'helpers/formatLeft';
import getEventById from 'helpers/getEventById';
import { hooks } from 'metamask/connector';

import s from './JackPot.module.scss';
import bscIcon from './images/bsc.webp';
import linkIcon from './images/link.png';

const { useAccount } = hooks;
const JackPot = () => {
  const params = useParams();
  const {
    state: { balance },
  } = useContext(CryptoContext);
  const [userBets, setBets] = useState({
    1: [],
    10: [],
    100: [],
  });
  const { join, isLoading } = useJackpot();
  const [bet, setBet] = useState();
  const [button, setButton] = useState(1);
  const [drawerContent, setDrawerContent] = useState(null);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [openCookieNotification, setOpenCookieNotification] = useState(true);
  const [openDealNotification, setOpenDealNotification] = useState(true);
  const now = useSelector((state: RootState) => state.app.now);
  const [pricesPage, setPricesPage] = useState(0);

  const chainLinkDateTime = useTranslate('chainlink.datetime');
  const chainLinkRoundId = useTranslate('chainlink.roundid');
  const chainLinkValue = useTranslate('chainlink.value');
  const chainLinkLink = useTranslate('chainlink.link');

  const predictRate = useTranslate('portfolio.predict-btc');
  const myBets = useTranslate('options.my-bets');
  const lastBets = useTranslate('options.last-bets');
  const dateAndTime = useTranslate('portfolio.date-time');
  const meaning = useTranslate('options.meaning');
  const accountText = useTranslate('options.account');
  const dealDetails = useTranslate('app.deal-details');
  const makeBet = useTranslate('sidebar.finance-deals.make-bet');
  const acceptingBets = useTranslate('options.accepting-bets');
  const waitingResult = useTranslate('options.waiting-result');
  const waitingNext = useTranslate('options.waiting-next');
  const jackpotLongText = useTranslate('jackpot.long-text');
  const dealClosed = useTranslate('options.deal-closed');
  const poolDstributed = useTranslate('options.pool-distributed');
  const dynamyc = useTranslate('options.dinamyc');
  const priceWill = useTranslate('options.price-will');
  const jackpotPool = useTranslate('jackpot.pool');
  const raffleDate = useTranslate('jackpot.raffle-date');
  const yourBet = useTranslate('states.your-bet');
  const currencyPair = useTranslate('options.currency-pair');
  const yourBetTooltip = useTranslate('tooltip.jackpot.your-bet');
  const maximumWinTooltip = useTranslate('tooltip.jackpot.maximum-win');
  const [getLeader, { data: leader }] = useLazyLeaderQuery();
  const theme = useSelector((state: RootState) => state.app.theme);
  const [current, setCurrent] = useState(0);
  const account = useWrapAccount();
  const isLaptop = useMediaQuery(1240);
  const ref = useRef(null);
  const [totalPool, setTotalPool] = useState();
  const handleTargetSelect = (value: any) => console.info(value);
  const [joinsPage, setJoinsPage] = useState(0);
  const [clientJoinsPage, setClientJoinsPage] = useState(0);

  const [getNext, { data: nextState, isFetching }] = useLazyGetNextQuery();
  const handleOpenBottomSheet = () => setOpenBottomSheet((prevState) => !prevState);
  const { data: events } = useEventsListQuery(null);
  const timeout = useRef<any>(null);

  const [getJoins, { data: joins }] = useLazyJoinListQuery();
  const [getClientJoins, { data: clientJoins }] = useLazyJoinListQuery();
  const [getBets, { data: bets }] = useLazyBetListQuery();

  const [getHistoricalPrices, { data: historicalPrices }] = useLazyGetHistoricalPricesQuery();

  const data = bets?.result[0];
  const event = getEventById(data?.eventId, events?.result);

  const refNots = useRef(null);
  const [st, setSt] = useState(refNots.current);
  useEffect(() => {
    setSt(refNots.current);
  }, [openCookieNotification]);

  useEffect(() => {
    if (event?.fromOracle && pricesPage === 0) {
      getHistoricalPrices({ eventId: event.id, frame: 'PT1M', size: 10 });

      const interval = setInterval(() => {
        getHistoricalPrices({ eventId: event.id, frame: 'PT1M', size: 10 });
      }, 15 * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [event, pricesPage, getHistoricalPrices]);

  useEffect(() => {
    event?.fromOracle && getHistoricalPrices({ eventId: event.id, frame: 'PT1M', size: 10 });
  }, [event]);

  useEffect(() => {
    getNext(null);

    const periodInterval = setInterval(() => {
      getNext(null);
    }, 60 * 1000);

    return () => {
      clearInterval(periodInterval);
    };
  }, [getNext]);

  useEffect(() => {
    if (joinsPage === 0 && data?.id) {
      getJoins({ id: data.id });

      const interval = setInterval(() => {
        getJoins({ id: data.id });
      }, 15 * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [data, account, joinsPage]);

  useEffect(() => {
    if (account && clientJoinsPage === 0 && data?.id) {
      getClientJoins({ id: data.id, client: account.toLowerCase() });

      const interval = setInterval(() => {
        getClientJoins({ id: data.id, client: account.toLowerCase() });
      }, 15 * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [data, account, clientJoinsPage]);

  useEffect(() => {
    drawerContent && isLaptop && data?.id && setDrawerContent(data);
  }, [isLaptop, data?.id]);

  useEffect(() => {
    getBets(params?.id || null);

    const periodInterval = setInterval(() => {
      getBets(params?.id || null);
    }, 15 * 1000);

    return () => {
      clearInterval(periodInterval);
    };
  }, [params]);

  const handleClick = () => {
    join(
      data?.id,
      data?.requestAmount,
      //@ts-ignore
      userBets[button]?.length ? userBets[button] : [bet],
      button,
    ).then(() => {
      isLaptop && handleOpenBottomSheet();
      setBets({
        1: [],
        10: [],
        100: [],
      });
    });
  };
  const lang = useSelector((state: RootState) => state.app.lang);

  const left = new Date(data?.lockDate + 'Z').getTime() - now;
  const expiration = new Date(data?.expirationDate + 'Z').getTime();
  const nextFireTime = new Date(nextState?.nextFireTime + 'Z').getTime();

  let pulseSubtitle = '',
    pulseTitle = '',
    pulseVariant: 'open' | 'accepted' | 'closed' | 'waiting' = 'open';

  if (left > 0) {
    pulseTitle = acceptingBets;
    pulseSubtitle = formatLeft(left, lang);
  } else if (expiration > now) {
    pulseVariant = 'accepted';
    pulseTitle = waitingResult;
    pulseSubtitle = formatLeft(expiration - now, lang);
  } else {
    pulseVariant = 'waiting';
    pulseTitle = waitingNext;
    pulseSubtitle = formatLeft(nextFireTime - now, lang);
  }

  const loading = !data || !event || !current;

  useEffect(() => {
    if (!loading && !isFetching) {
      setTotalPool(Date.now() > expiration ? nextState?.currentBank : data?.totalPool);
    }
  }, [expiration, loading, data?.totalPool, nextState?.currentBank]);

  const accountTab = (
    <TableMain
      page={clientJoinsPage}
      totalPages={clientJoins?.totalPages}
      handlePage={(page) => {
        if (data.id && account) {
          setClientJoinsPage(page);
          getClientJoins({ page, id: data.id, client: account.toLowerCase() });
        }
      }}
      columnNames={[accountText, meaning, dateAndTime]}
      theme={theme}>
      {clientJoins?.result
        .filter((item: any) => item.client === account?.toLowerCase())
        .map((item: any) => (
          <TableRow
            key={Math.random()}
            isLeader={leader?.id === item.id && left < 0}
            isOwner={item.client === account?.toLowerCase()}
            namesTd={[
              formatAddress(item.client),
              `${item.targetValue}$`,
              `${formatDate(item.createdDate)}`,
            ]}
          />
        ))}
    </TableMain>
  );

  const joinsTab = (
    <TableMain
      page={joinsPage}
      totalPages={joins?.totalPages}
      handlePage={(page) => {
        if (data.id) {
          setJoinsPage(page);
          getJoins({ page, id: data.id });
        }
      }}
      columnNames={[accountText, meaning, dateAndTime]}
      theme={theme}>
      {left <= 0 && leader && (
        <TableRow
          key={Math.random()}
          isLeader={true}
          isOwner={leader.client === account?.toLowerCase()}
          namesTd={[
            formatAddress(leader.client),
            `${left > 0 ? 'xxxxx' : leader.targetValue}$`,
            `${formatDate(leader.createdDate)}`,
          ]}
        />
      )}
      {clientJoins?.result.length > 0 && (clientJoins?.result[0].id !== leader?.id || left > 0) && (
        <TableRow
          key={Math.random()}
          isOwner={clientJoins.result[0].client === account?.toLowerCase()}
          namesTd={[
            formatAddress(clientJoins.result[0].client),
            `${clientJoins.result[0].targetValue}$`,
            `${formatDate(clientJoins.result[0].createdDate)}`,
          ]}
        />
      )}
      {joins?.result
        .filter((item: any) => {
          const notLeader = item.id !== leader?.id || left > 0;
          const notLastClient = clientJoins?.result[0]
            ? item.id !== clientJoins.result[0].id
            : true;

          return notLeader && notLastClient;
        })
        .map((item: any) => (
          <TableRow
            key={Math.random()}
            isOwner={item.client === account?.toLowerCase()}
            namesTd={[
              formatAddress(item.client),
              `${left > 0 && item.client !== account?.toLowerCase() ? 'xxxxx' : item.targetValue}$`,
              `${formatDate(item.createdDate)}`,
            ]}
          />
        ))}
    </TableMain>
  );

  const chainLinkTab = (
    <TableMain
      key={3}
      page={pricesPage}
      totalPages={historicalPrices?.totalPages}
      handlePage={(page) => {
        if (data?.eventId) {
          setPricesPage(page);
          getHistoricalPrices({
            page,
            eventId: data.eventId,
            frame: 'PT1M',
            size: 10,
          });
        }
      }}
      columnNames={[chainLinkDateTime, chainLinkRoundId, chainLinkValue, chainLinkLink]}
      theme={theme}>
      {historicalPrices?.content.map((row: any) => {
        return (
          <TableRow
            key={Math.random()}
            namesTd={[
              formatDate(row.created),
              row.roundId,
              row.amount?.toFixed(event?.scale || 2),
              <div
                className={s.blue}
                onClick={() => {
                  window.open(
                    `https://bscscan.com/address/${event?.oracleAggregatorContractAddress}`,
                  );
                }}>
                {chainLinkLink}
              </div>,
            ]}
          />
        );
      })}
    </TableMain>
  );

  let tabs = [];

  const hasPublicJoins = joins?.result.length > 0 && expiration > now;

  if (hasPublicJoins) {
    tabs.push(lastBets);
  }

  const hasJoins = account && clientJoins?.result.length > 0 && expiration > now;

  if (hasJoins) {
    tabs.push(myBets);
  }

  tabs.push('Chainlink');

  return (
    <Layout>
      <div
        style={
          loading
            ? {
                position: 'relative',
                overflow: 'hidden',
                height: `calc(100vh - ${isLaptop ? 106 : 171}px)`,
              }
            : {}
        }>
        {loading && <MainLoader />}
        <div className={classNames(s.wrapper, s[theme])}>
          <div className={s.content}>
            <div className={s.totalBlock}>
              <TotalBlock
                centerText={
                  <>
                    {totalPool !== undefined && (
                      <ReactOdometer format={'( ddd).dd'} value={totalPool} />
                    )}
                    &nbsp;<span style={{ fontSize: 24, position: 'relative', top: 4 }}>USDT</span>
                  </>
                }
                theme={theme}
                title={jackpotPool}
                variant="gold"
                icon={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      bottom: -16,
                      position: 'relative',
                    }}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_SCAN_URL}/address/${process.env.REACT_APP_JACKPOT_ADDRESS}`,
                      )
                    }>
                    <img src={bscIcon} width={80} />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 20 20"
                      style={{ marginLeft: 6 }}>
                      <title>external link</title>
                      <g fill="#fff">
                        <path d="M17 17H3V3h5V1H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5h-2z" />
                        <path d="M11 1l3.29 3.29-5.73 5.73 1.42 1.42 5.73-5.73L19 9V1z" />
                      </g>
                    </svg>
                  </div>
                }
              />
            </div>
            <TotalBlock
              centerText={formatDate(data?.expirationDate)}
              className={s.mobileTotal}
              theme={theme}
              title={raffleDate}
              variant="primary"
            />
            <RowBet
              className={s.rowBet}
              currencySubTitle={currencyPair}
              currencyTitle={`${event?.name} · USDT`}
              inIcon={<img alt="" src={event?.iconBase64} width={25} />}
              outIcon={<TetherIcon />}
              pulseSubtitle={pulseSubtitle}
              pulseTitle={pulseTitle}
              pulseVariant={pulseVariant}
              theme={theme}
            />
            <div className={s.title}>
              <Typography variant="h1">
                {predictRate}{' '}
                {expiration &&
                  formatDate(now > expiration ? nextState?.nextEndTime : data?.expirationDate)}
              </Typography>
            </div>
            <div className={classNames(s.chart, s[theme])}>
              <Chart2
                loading={loading}
                colors={{}}
                ref={ref}
                theme={theme}
                eventId={event?.id}
                type={event?.symbol}
                scale={event?.scale}
                name={event?.name}
                handleCurrent={setCurrent}
              />
            </div>
            <div className={s.tableTabs}>
              <Tabs tabsName={tabs}>
                {hasPublicJoins ? joinsTab : chainLinkTab}
                {hasJoins ? accountTab : chainLinkTab}
                {hasJoins ? chainLinkTab : <></>}
              </Tabs>
            </div>
            <div className={s.strTabsBlock}>
              <Tabs tabsName={[dealDetails]}>
                <StringTabContent str={jackpotLongText} theme={theme} />
              </Tabs>
            </div>

            <Winners event={event} />
          </div>
          <div className={s.rightBlock}>
            <div className={s.totalBlock}>
              <TotalBlock
                centerText={formatDate(
                  now > expiration ? nextState?.nextEndTime : data?.expirationDate,
                )}
                theme={theme}
                title={raffleDate}
                variant="primary"
              />
            </div>
            {!!account && left > 0 && (
              <PlaceYourBet
                accordionOff
                bid
                button={button}
                setButton={setButton}
                buttonGroup
                buttonAmount={data?.requestAmount}
                buttonGroupTitle={yourBet}
                className={s.rightBlockItem}
                bet={bet}
                setBet={setBet}
                currentTitle="$"
                setJackBets={
                  button !== 100
                    ? (bets: any[]) => {
                        //@ts-ignore
                        setBets({
                          ...userBets,
                          [button]: bets,
                        });
                      }
                    : undefined
                }
                //@ts-ignore
                jackBets={button !== 100 ? userBets[button] : undefined}
                iconBid={<BtcIcon />}
                inValue={balance}
                labelRangeSlider={priceWill}
                maxPrize={((totalPool || 0) * 0.777).toFixed(2)}
                onChangeSelect={handleTargetSelect}
                onClickBet={handleClick}
                selectOptions={options}
                theme={theme}
                isLoading={isLoading}
                tooltipCommission="tooltip"
                tooltipDeal="tooltipDeal"
                tooltipPrize={maximumWinTooltip}
                tooltipButtonGroupTitle={yourBetTooltip}
                type="input"
              />
            )}

            {left <= 0 && (
              <div className={s.rightBlock} style={{ marginLeft: 0 }}>
                <BetStatus theme={theme} item={data} jackpot={!params?.id} />
              </div>
            )}
          </div>
        </div>
        <Drawer visible={!!drawerContent} onVisibleChange={() => setDrawerContent(null)}>
          <JackpotBetDrawerContent
            content={drawerContent}
            onVisibleChange={() => setDrawerContent(null)}
          />
        </Drawer>
        {!drawerContent && isLaptop && !!account && left > 0 && (
          <div style={{ zIndex: 10, position: 'relative' }}>
            <Button color="secondary" makeBet onClick={() => setDrawerContent(data)} size="xl">
              {makeBet}
            </Button>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default JackPot;
