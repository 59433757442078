import { useTitle } from 'hooks/useTitle.hook';

import ChartRadioGroup from 'components/Simple/ChartRadioGroup';

import s from './Chart.module.scss';

export interface IChartHeader {
  chainlink?: boolean;
  direction: string;
  current: number;
  onChange: (val: string | number) => void;
  name?: string | undefined;
}

const ChartHeader: React.FC<IChartHeader> = ({ direction, chainlink, current, onChange, name }) => {
  useTitle(`${current} | ${name} · USDT`);
  return (
    <div className={s.chart_header}>
      <div
        style={{
          color: '#FFF',
        }}
        className={s.rate}>
        ${current}
      </div>

      <div>
        <ChartRadioGroup chainlink={chainlink} onChange={onChange} />
      </div>
    </div>
  );
};
export default ChartHeader;
