import { FC, memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ThemeType } from 'types/baseTypes';
import { Urls } from '../../../../../consts/urls';
import { useWallets } from '../../../../../hooks/useWalletsData';

import s from './style.module.scss';

import DropDown from 'components/Containers/DropDown';
import Typography from 'components/Simple/Typography';
import Translated from 'components/Containers/Translated';
import { CardDesctop } from '../../../../childrens/card-desktop';
import Button from 'components/Simple/Button';

interface IWalletDropDown {
  theme: ThemeType
  isOpen?: boolean,
  closeDropDown: () => void
  handleOpenModalWalletDownload: () => void
}

export const WalletDropDown: FC<IWalletDropDown> = memo((props) => {
  const {
    theme = 'default',
    handleOpenModalWalletDownload,
    closeDropDown,
  } = props;

  const {
    btnsConnectWallet,
  } = useWallets();

  return (
    <DropDown
      theme={theme}
      closeDropDown={closeDropDown}
    >
      <div className={s['text']}>
        <Typography variant='title'>
          <Translated id='header.dropdown.headline' />
        </Typography>
      </div>

      <div className={s['text']} style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Typography color='grey' variant='small' style={{ lineHeight: '18px' }}>
          <Translated id='header.dropdown.text.first' /><br />
          <Link to={Urls.termsOfServices} target='_blank'>
            <Translated id='header.dropdown.text.terms_of_services' />
          </Link>
          <Translated id='header.dropdown.text.second' />
          <Link to={Urls.privacyPolicy} target='_blank'>
            <Translated id='header.dropdown.text.privacy_policy' />
          </Link>
        </Typography>
      </div>

      {
        btnsConnectWallet
          .map((btn: any, index: number) => {
            return (
              <div
                className={s['card']}
                key={index}
              >
                <CardDesctop
                  click={() => btn.handler(btn.type, handleOpenModalWalletDownload)}
                  theme={theme}
                  type={btn.type}
                  icon={btn.icon}
                />
              </div>
            );
          })
      }

      <Button
        onClick={handleOpenModalWalletDownload}
        align='center'
        variant='contained'
        color='none'
        className={s['install-wallet-btn']}
      >
        <Typography variant='small' color='darkblue'>
          <Translated id='header.dropdown.connect.button' />
        </Typography>
      </Button>
    </DropDown>
  );
});
