import { initializeConnector } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { useAppSelector } from 'hooks/store';

const [connector, hooks, store] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions }),
);
//
hooks.useAccount = () => useAppSelector((state) => state.wallet.address);
hooks.useProvider = () => useAppSelector((state) => state.wallet.provider);
hooks.useChainId = () => 97;
hooks.useAccounts = () => [useAppSelector((state) => state.wallet.address)];
hooks.useIsActivating = () => true;
hooks.useIsActive = () => true;

export { connector, hooks, store };
