import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApi } from 'store/consts';
import { getExecutionType } from 'store/utils/getExecutionType';

export const API_NAME = 'clientService';

export interface IClientInfo {
  bestWon: number | string;
  onBetAmount: number | string;
  profitAmount: number | string;
  registrationDate: string;
  totalWonAmount: number | string;
}

export enum BetType {
  All,
  CustomBets = 'CUSTOM',
  BinaryOptions = 'BINARY',
  Lottery = 'AUCTION',
  Jackpot = 'JACKPOT',
}

export const clientService = createApi({
  reducerPath: API_NAME,
  baseQuery: fetchBaseQuery({ baseUrl: `${baseApi}/client/` }),
  endpoints: (builder) => ({
    clientInfo: builder.query<IClientInfo, string>({
      query: (address) => ({
        url: `${getExecutionType()}/${address.toLowerCase()}/info`,
        method: 'GET',
      }),
    }),
    transactionsJoin: builder.query({
      query: ({ page }) => ({
        url: `transactions/join`,
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: {
          executionType: getExecutionType(),
          page: page || 0,
          size: 10,
          sort: {
            direction: 'DESC',
            property: 'createdDate',
          },
        },
      }),
    }),
    getLastWining: builder.query({
      query: () => ({
        url: `${getExecutionType()}/joins/last-wining?betType=JACKPOT`,
        method: 'GET',
      }),
    }),
    transactions: builder.query({
      query: ({ account, page }) => ({
        url: `transactions`,
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: {
          executionType: getExecutionType(),
          clientAddress: account.toLowerCase(),
          page: page || 0,
          size: 10,
          sort: {
            direction: 'DESC',
            property: 'createdDate',
          },
        },
      }),
    }),
    joins: builder.query({
      query: ({ address, type, page }) => ({
        url: `joins`,
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: {
          executionType: getExecutionType(),
          clientAddress: address?.toLowerCase(),
          betType: type,
          page: page || 0,
          size: 10,
          sort: {
            direction: 'DESC',
            property: 'createdDate',
          },
        },
      }),
    }),
  }),
});

export const {
  useLazyGetLastWiningQuery,
  useLazyTransactionsJoinQuery,
  useClientInfoQuery,
  useLazyTransactionsQuery,
  useLazyJoinsQuery,
} = clientService;
