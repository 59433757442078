import React from 'react';
import { Children } from 'types/children';
import Typography from '../../Simple/Typography';
import s from './TransactionsWrapper.module.scss';

interface TransactionsWrapperProps {
  children?: Children;
  date?: string;
}

const TransactionsWrapper = ({ children, date }: TransactionsWrapperProps) => (
  <div className={s.transactionBlock}>
    <Typography color='grey' variant='h4'>
      {date}
    </Typography>
    <div className={s.transactionItem}>
      {children}
    </div>
  </div>
);

export default TransactionsWrapper;
