import en from 'lang/en';
import ru from 'lang/ru';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type ITranslated = {
  id: keyof typeof en;
};
const Translated = ({ id }: ITranslated) => {
  const lang = useSelector((state: RootState) => state.app.lang);
  if (lang === 'ru') {
    return <>{ru[id]}</>;
    //return <span>{}</span>;
  }
  return <>{en[id]}</>;
  //return <span>{en[id]}</span>;
};

export default Translated;
