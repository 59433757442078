import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApi } from 'store/consts';

import { BetType } from './clientService';

export const API_NAME = 'notificationService';

export interface INotificationBody {
  betId: number;
  betType: string;
  createdDate: string;
  id: string;
  notificationType: string;
  payload: string;
  status: NotificationSeenStatus;
  userId: string;
}

export enum NotificationSeenStatus {
  NEW = 'NEW',
  SEEN = 'SEEN',
}

export interface INotificationServiceResponse {
  content: INotificationBody[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged: boolean;
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  totalElements: number;
  totalPages: number;
}

/** betType */

export enum NotificationCategory {
  All,
  CustomBets = 'CUSTOM',
  BinaryOptions = 'BINARY',
  Lottery = 'AUCTION',
  Jackpot = 'JACKPOT',
}

export enum NotificationResult {
  WON = 'WON',
  FAIL = 'FAIL',
  REFUNDED = 'REFUNDED',
}

export enum NotificationType {
  All,
  CREATE_MASS_JOIN = 'CREATE_MASS_JOIN',
  BET_RESULT = 'BET_RESULT',
  CREATE_BET = 'CREATE_BET',
  CREATE_JOIN = 'CREATE_JOIN',
  DELETE_BET = 'DELETE_BET',
  LOCK_TIME_EXPIRATION = 'LOCK_TIME_EXPIRATION',
  MONEY_ACCEPTED = 'MONEY_ACCEPTED',
}

interface INotificationRequestParams {
  userId: string;
  betType?: NotificationCategory;
  notificationType?: NotificationType;
  size?: number;
  offset?: number;
  page?: number;
  unpaged?: boolean;
  status?: NotificationSeenStatus;
}

export type Notifications = Pick<
  INotificationServiceResponse,
  'content' | 'size' | 'totalPages' | 'totalElements' | 'last'
>;

export const notificationService = createApi({
  reducerPath: API_NAME,
  tagTypes: ['Notification'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_NOTIFICATION_API as string,
  }),
  endpoints: (builder) => ({
    getUnreadNotificationsCounter: builder.query<number, string>({
      query: (userId) => ({
        url: '/unread',
        method: 'GET',
        params: { userId },
      }),

      providesTags: [{ type: 'Notification' }],
    }),
    getNotifications: builder.query<Notifications, INotificationRequestParams>({
      query: ({ userId, page, betType, notificationType, size }) => ({
        url: '',
        method: 'GET',
        params: {
          userId,
          page,
          betType,
          size,
          sort: 'createdDate,desc',
          notificationType,
        },
        // providesTags: ['Notification'],
      }),
      transformResponse: (response: INotificationServiceResponse) => ({
        content: response.content,
        size: response.size,
        totalPages: response.totalPages,
        totalElements: response.totalElements,
        last: response.last,
      }),
      providesTags: [{ type: 'Notification' }],
    }),
    seenNotifications: builder.mutation<void, string[]>({
      query: (ids) => ({
        url: '/seen',
        method: 'POST',
        body: { ids },
      }),
      invalidatesTags: ['Notification'],
    }),
    seenAllNotifications: builder.mutation<void, string[]>({
      query: (userId) => ({
        url: '/seen/all',
        method: 'POST',
        body: { userId },
      }),
      invalidatesTags: ['Notification'],
    }),
  }),
});

export const {
  useSeenAllNotificationsMutation,
  useGetUnreadNotificationsCounterQuery,
  useLazyGetNotificationsQuery,
  useSeenNotificationsMutation,
} = notificationService;
