import { BitKeep } from './BitKeep.js';
import { MetaMask } from './MetaMask.js';
import { TrustWallet } from './TrustWallet.js';
import { WalletConnect } from './WalletConnect.js';

const RPC_URL = process.env.REACT_APP_RPC_URL;
const NETWORK_ID = process.env.REACT_APP_NETWORK_ID;
const INFURA_ID = '';

class Web3 {
  constructor() {
    this.MetaMask = new MetaMask(RPC_URL, NETWORK_ID, INFURA_ID);
    this.WalletConnect = new WalletConnect(RPC_URL, NETWORK_ID, INFURA_ID);
    this.TrustWallet = new TrustWallet(RPC_URL, NETWORK_ID, INFURA_ID);
    this.BitKeep = new BitKeep(RPC_URL, NETWORK_ID, INFURA_ID);
  }
}

const web3 = new Web3();

export { web3 };
