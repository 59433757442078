import React, { ReactNode } from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';

import s from './TotalBlock.module.scss';

interface TotalBlockProps {
  variant?: 'gold' | 'primary';
  theme?: ThemeType;
  title?: string;
  centerText?: string | ReactNode;
  className?: string;
  icon?: any;
}

const TotalBlock = ({
                      className,
                      variant = 'gold',
                      theme = 'default',
                      title = '',
                      centerText = '',
                      icon,
                    }: TotalBlockProps) => (
  <div className={classNames(s.wrapper, s[variant], s[theme], className)}>
    <div className={s.shadow}>
      <div className={s.titleBlock}>{title}</div>
    </div>
    <div className={s.valueBlock}>{centerText}</div>
    {icon && <div className={s.icon}>{icon}</div>}
  </div>
);

export default TotalBlock;
