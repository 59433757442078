export class BitKeep {
  constructor(RPC_URL, NETWORK_ID, INFURA_ID) {
    this.RPC_URL = RPC_URL;
    this.NETWORK_ID = NETWORK_ID;
    this.INFURA_ID = INFURA_ID;

    this.address = null;
    this.provider = null;
  }

  async connect() {
    let { bitkeep } = window;

    if (typeof bitkeep !== 'undefined') {
      const walletAddress = await bitkeep.ethereum.request(
        { method: 'eth_requestAccounts' });
      console.log(walletAddress);
      if (bitkeep.chainId === this.NETWORK_ID) {
        if (walletAddress) {
          this.address = walletAddress[0];
          this.provider = bitkeep;

          return {
            address: this.address,
            provider: this.provider,
            type: 'BitKeep',
          };
        }
      } else {
        try {
          await bitkeep.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: this.NETWORK_ID }],
          });

          if (walletAddress) {
            this.address = walletAddress[0];
            this.provider = bitkeep;

            return {
              address: this.address,
              provider: this.provider.target,
              type: 'BitKeep',
            };
          }
        } catch (error) {
          if (error.code === 4902) {
            try {
              await bitkeep.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: this.NETWORK_ID,
                    rpcUrls: [`${this.RPC_URL}${this.INFURA_ID}`],
                    chainName: 'newChain',
                    nativeCurrency: {
                      name: 'bnb',
                      symbol: 'bnb',
                      decimals: 18,
                    },
                  }],
              });

              if (walletAddress) {
                this.address = walletAddress[0];
                this.provider = bitkeep;

                return {
                  address: this.address,
                  provider: this.provider.target,
                  type: 'BitKeep',
                };
              }
            } catch (error) {
              return error;
            }
          }
        }
      }
    } else if (typeof bitkeep == 'undefined') {
      return {
        linkToOpen: true,
        type: 'BitKeep',
      };
    }
  }
}
