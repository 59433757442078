import React from 'react';

import ReactPaginate from 'react-paginate';
import useMediaQuery from 'theme/useMediaQuery';

import s from './Pagination.module.scss';

interface PaginationProps {
  page: number;
  onPageChange: ({ selected }: { selected: any }) => void;
  totalPages: number;
}
export default function Pagination(props: PaginationProps) {
  const { page, onPageChange, totalPages } = props;
  const isMobile = useMediaQuery(700);
  return (
    <div className={s.pagination}>
      <ReactPaginate
        breakLabel="..."
        forcePage={page}
        nextLabel=">"
        onPageChange={onPageChange}
        pageRangeDisplayed={isMobile ? 1 : 3}
        marginPagesDisplayed={isMobile ? 1 : 1}
        pageCount={totalPages}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
    </div>
  );
}
