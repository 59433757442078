export const enum ExecutionType {
  AVALANCHE = 'AVALANCHE',
  BSC = 'BSC',
  FREE = 'FREE',
  FREE_QR = 'FREE_QR',
  POLYGON = 'POLYGON',
  TRON = 'TRON'
}

export const setExetutionType = (type: ExecutionType) => localStorage.setItem('exetutionType', type);


export const getExecutionType = (): ExecutionType => localStorage.getItem('exetutionType') as ExecutionType ?? ExecutionType.BSC;





