import React from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';

import s from './CurrencyPair.module.scss';

interface CurrencyPairProps {
  theme?: ThemeType;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  large?: boolean;
  small?: boolean;
}

const CurrencyPair = ({
  theme = 'default',
  iconLeft,
  iconRight,
  small,
  large = false,
}: CurrencyPairProps) => (
  <div className={classNames(s.wrapper, large && s.largeBlock, small && s.small)}>
    <div className={classNames(s.canvas, s[theme])}>{iconLeft}</div>
    <div className={s.rightIcon}>{iconRight}</div>
  </div>
);

export default CurrencyPair;
