import { FC, useState } from 'react';
import { ThemeType } from 'types/baseTypes';

import s from './style.module.scss';

import { ModalWalletConnect } from './components/walletDropDown';
import { WalletModalDownload } from './components/walletModalDownload';

interface IWeb3ConnectWalletMob {
  theme: ThemeType;
  setOpen: () => void;
  openModal: boolean;
}

export const Web3ConnectWalletMob: FC<IWeb3ConnectWalletMob> = (props) => {

  const {
    theme,
    setOpen,
    openModal,
  } = props;

  const [
    openModalWalletDownload,
    setOpenModalWalletDownload,
  ] = useState<boolean>(false);

  const handleOpenModalWalletDownload = () => setOpenModalWalletDownload((prevState) => !prevState);

  return (
    <div className={s[theme]}>
      <ModalWalletConnect
        theme={theme}
        openModal={openModal}
        setOpen={setOpen}
        handleOpenModalWalletDownload={handleOpenModalWalletDownload}
      />

      {
        openModalWalletDownload &&
        <WalletModalDownload
          theme={theme}
          openModal={openModalWalletDownload}
          setOpen={handleOpenModalWalletDownload}
        />
      }
    </div>
  );
};
