import s from './LoginLoader.module.css';

export const LoginLoader = () => (
  <div className={s.loginLoader}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
