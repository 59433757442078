import { useMemo } from 'react';

import { ethers } from 'ethers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { fetchingFailed, fetchingSucceeded, setAutoConnectionLoading } from 'store/web3/wallet';

import formatAddress from 'helpers/formatAddress';
import { web3 } from '../../web3/wallets';
import { useAppSelector } from '../store';


const enum WalletType {
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
  TrustWallet = 'TrustWallet',
  BitKeep = 'BitKeep',
}

const useConnectWallet = () => {
  const dispatch = useDispatch();
  const dataWallet = useSelector((store: RootState): any => store.wallet);
  const isLoading = useAppSelector((state) => state.wallet.loading);

  const disconnect = {
    address: null,
    provider: null,
    type: null,
    error: null,
  };

  const logoutBrowser = () => {
    dispatch(fetchingSucceeded(disconnect));
    removeWalletFromLocalStorage();
  };

  const logoutWalletConnect = () => {
    dispatch(fetchingSucceeded(disconnect));
    dataWallet.provider.disconnect();
    removeWalletFromLocalStorage();
  };

  const logoutWallet = () => {
    if (dataWallet.type === 'MetaMask') logoutBrowser();
    else if (dataWallet.type === 'WalletConnect') logoutWalletConnect();
    else if (dataWallet.type === 'TrustWallet') logoutBrowser();
    else if (dataWallet.type === 'BitKeep') logoutBrowser();
    removeWalletFromLocalStorage();
  };

  const onMetaMask = async (): Promise<any> => {
    try {
      const res = (await web3.MetaMask.connect()) as any;
      res.provider = new ethers.providers.Web3Provider(res.provider);
      dispatch(fetchingSucceeded(res));
      saveWalletToLocalStorage(WalletType.MetaMask);
      return res;
    } catch (err) {
      dispatch(fetchingFailed(err));
    }
  };

  const onWalletConnect = async (): Promise<any> => {
    try {
      const res = (await web3.WalletConnect.connect()) as any;
      res.provider = new ethers.providers.Web3Provider(res.provider);
      dispatch(fetchingSucceeded(res));
      saveWalletToLocalStorage(WalletType.WalletConnect);
      return res;
    } catch (err) {
      dispatch(fetchingFailed(err));
    }
  };

  const onTrustWallet = async (): Promise<any> => {
    try {
      const res = (await web3.TrustWallet.connect()) as any;
      res.provider = new ethers.providers.Web3Provider(res.provider);
      dispatch(fetchingSucceeded(res));
      saveWalletToLocalStorage(WalletType.TrustWallet);
      return res;
    } catch (err) {
      dispatch(fetchingFailed(err));
    }
  };

  const onBitKeep = async (): Promise<any> => {
    try {
      const res = (await web3.BitKeep.connect()) as any;
      res.provider = new ethers.providers.Web3Provider(res.provider);
      dispatch(fetchingSucceeded(res));
      saveWalletToLocalStorage(WalletType.BitKeep);
      return res;
    } catch (err) {
      dispatch(fetchingFailed(err));
    }
  };

  const address = useMemo(() => {
    let status = null;
    if (dataWallet.address) status = formatAddress(dataWallet.address);
    return status;
  }, [dataWallet]);

  const saveWalletToLocalStorage = (wallet: WalletType) => localStorage.setItem('wallet', wallet);
  const removeWalletFromLocalStorage = () => localStorage.removeItem('wallet');

  const autoConnect = async () => {
    dispatch(setAutoConnectionLoading(true));
    const wallet = <WalletType>localStorage.getItem('wallet');
    if (wallet === WalletType.MetaMask) await onMetaMask();
    else if (wallet === WalletType.WalletConnect) await onWalletConnect();
    else if (wallet === WalletType.TrustWallet) await onTrustWallet();
    else if (wallet === WalletType.BitKeep) await onBitKeep();
    dispatch(setAutoConnectionLoading(false));
  };

  return {
    onMetaMask,
    onWalletConnect,
    onTrustWallet,
    logoutWallet,
    onBitKeep,
    autoConnect,
    address,
    fullAddress: dataWallet?.address,
    isLoading,
  };
};

export { useConnectWallet };
