import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApi } from 'store/consts';
import { getExecutionType } from 'store/utils/getExecutionType';

export const API_NAME = 'auctionService';

export const auctionService = createApi({
  reducerPath: API_NAME,
  baseQuery: fetchBaseQuery({ baseUrl: `${baseApi}/auction/` }),

  endpoints: (builder) => ({
    getReport: builder.mutation({
      query: (id) => ({
        url: `bet/${getExecutionType()}/${id}/report`,
        method: 'GET',
        responseHandler: async (response) => {
          if (response.status === 200 && response.url) {
            const tempLink = document.createElement('a');
            tempLink.href = response.url;
            tempLink.click();
          }
        },
        cache: 'no-cache',
      }),
    }),
    betList: builder.query({
      query: ({ period, amount, page = 0, id }) => {
        let body;
        if (id) {
          body = {
            executionType: getExecutionType(),
            ids: [id],
            size: 1,
            page: 0,
          };
        } else {
          body = {
            executionType: getExecutionType(),
            page,
            size: 1,
            statusList: {
              list: ['CREATED'],
            },
            sort: {
              direction: 'DESC',
              property: 'createdDate',
            },
            requestAmount: amount,
            periodFilter: [
              {
                period,
                condition: 'EQUALS',
              },
            ],
          };
        }
        return {
          url: `bet`,
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body,
        };
      },
    }),
    joinList: builder.query({
      query: ({ id, client, page, size }) => ({
        url: `bet/joins`,
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: {
          executionType: getExecutionType(),
          page: page || 0,
          size: size || 20,
          client,
          auctionBet: id,
          mode: 'no-cors',
          statusList: {
            list: ['JOINED', 'LOST', 'PRIZE_TAKEN', 'WON'],
          },
          sort: {
            direction: 'DESC',
            property: 'createdDate',
          },
        },
      }),
    }),
    leader: builder.query({
      query: ({ id, value }) => ({
        url: `bet/${getExecutionType()}/${id}/leader?value=${value}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetReportMutation,
  useLazyBetListQuery,
  useLazyJoinListQuery,
  useLazyLeaderQuery,
} = auctionService;
