import { createSlice } from '@reduxjs/toolkit';

interface IAppState {
  address: any;
  type: any;
  provider: any;
  error: any;
  loading: boolean;
}

let initialState: IAppState = {
  address: null,
  type: null,
  provider: null,
  error: null,
  loading: false,
};

const wallet = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    fetchingSucceeded(state, data: any): void {
      state.address = data.payload.address;
      state.type = data.payload.type;
      state.provider = data.payload.provider;
    },
    fetchingFailed(state, err: any): void {
      state.error = err.payload;
    },
    setAutoConnectionLoading(state, data: any): void {
      state.loading = data.payload;
    },
  },
});

export const { fetchingSucceeded, fetchingFailed, setAutoConnectionLoading } = wallet.actions;

export default wallet.reducer;
