export class MetaMask {
  constructor(RPC_URL, NETWORK_ID, INFURA_ID) {
    this.RPC_URL = RPC_URL;
    this.NETWORK_ID = NETWORK_ID;
    this.INFURA_ID = INFURA_ID;

    this.address = null;
    this.provider = null;
  }

  async connect() {
    let { ethereum } = window;
    if (typeof ethereum !== 'undefined') {
      const walletAddress = await ethereum.request(
        { method: 'eth_requestAccounts' });
      if (ethereum.chainId === this.NETWORK_ID) {
        if (walletAddress) {
          this.address = walletAddress[0];
          this.provider = ethereum;

          return {
            address: this.address,
            provider: this.provider,
            type: 'MetaMask',
          };
        }
      } else {
        try {
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: this.NETWORK_ID }],
          });

          if (walletAddress) {
            this.address = walletAddress[0];
            this.provider = ethereum;

            return {
              address: this.address,
              provider: this.provider,
              type: 'MetaMask',
            };
          }
        } catch (error) {
          if (error.code === 4902) {
            try {
              await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: this.NETWORK_ID,
                    rpcUrls: [`${this.RPC_URL}${this.INFURA_ID}`],
                    chainName: 'newChain',
                    nativeCurrency: {
                      name: 'bnb',
                      symbol: 'bnb',
                      decimals: 18,
                    },
                  }],
              });

              if (walletAddress) {
                this.address = walletAddress[0];
                this.provider = ethereum;

                return {
                  address: this.address,
                  provider: this.provider,
                  type: 'MetaMask',
                };
              }
            } catch (error) {
              return error;
            }
          }
        }
      }
    } else if (typeof ethereum == 'undefined') {
      return {
        linkToOpen: true,
        type: 'MetaMask',
      };
    }
  }
}
