export const TOKEN_ADDRESS = process.env.REACT_APP_TOKEN_ADDRESS as string;
export const AUCTION_ADDRESS = process.env.REACT_APP_AUCTION_ADDRESS as string;
export const BINARY_ADDRESS = process.env.REACT_APP_BINARY_ADDRESS as string;
export const JACKPOT_ADDRESS = process.env.REACT_APP_JACKPOT_ADDRESS as string;
export const CUSTOM_ADDRESS = process.env.REACT_APP_CUSTOM_ADDRESS as string;

export const JACKPOT_CONTACT_ABI = [
  {
    inputs: [
      { internalType: 'address', name: 'mainToken', type: 'address' },
      { internalType: 'address[]', name: 'owners', type: 'address[]' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'AddOwner',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'newAlterToken', type: 'address' }],
    name: 'ChangeAlterToken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestampExpirationDelay',
        type: 'uint256',
      },
    ],
    name: 'ChangeTimestampDelay',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'previousAlternativeFee', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newAlternativeFee', type: 'uint256' },
    ],
    name: 'CompanyAlterFeeChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'previousCompanyFee', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newCompanyFee', type: 'uint256' },
    ],
    name: 'CompanyFeeChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousCompany', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newCompany', type: 'address' },
    ],
    name: 'CompanyTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'targetAddress', type: 'address' },
      { indexed: false, internalType: 'bool', name: 'isAlternative', type: 'bool' },
    ],
    name: 'FeeTaken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'client', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'joinIdRef', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'mainTokenRefunded', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'alterTokenRefunded', type: 'uint256' },
    ],
    name: 'JackpotBetCancelled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'finalValue', type: 'string' },
      { indexed: false, internalType: 'uint256', name: 'firstWonSize', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'secondWonSize', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'thirdWonSize', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'totalRaffled', type: 'uint256' },
    ],
    name: 'JackpotBetClosed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'id', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'eventId', type: 'string' },
      { indexed: false, internalType: 'uint256', name: 'lockTime', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'expirationTime', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'creator', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'startBank', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'requestAmount', type: 'uint256' },
    ],
    name: 'JackpotBetCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bool', name: 'useAlterFee', type: 'bool' },
      { indexed: true, internalType: 'address', name: 'client', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'joinId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'joinRefId', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'targetValue', type: 'string' },
    ],
    name: 'JackpotBetJoined',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'client', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'mainTokenRefunded', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'alterTokenRefunded', type: 'uint256' },
    ],
    name: 'JackpotBetRefunded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'client', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'JackpotPrizeTaken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'ownerToRemove', type: 'address' }],
    name: 'RemoveOwner',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'newSwapRouter', type: 'address' }],
    name: 'SetRouter',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'code', type: 'string' },
      { indexed: false, internalType: 'uint256', name: 'votingNumber', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: 'passed', type: 'bool' },
    ],
    name: 'VotingResult',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'code', type: 'string' },
      { indexed: false, internalType: 'uint256', name: 'votingNumber', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'initiator', type: 'address' },
    ],
    name: 'VotingStarted',
    type: 'event',
  },
  {
    inputs: [],
    name: 'acquireNewAlternativeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireNewOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireOwnerToRemove',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireTakeFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireTransferCompany',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'addOwnerVoting',
    outputs: [
      { internalType: 'address', name: 'newOwner', type: 'address' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'cancelEnabled',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'uint256', name: 'joinRefId', type: 'uint256' },
    ],
    name: 'cancelJackpotJoin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'changeAlterToken',
    outputs: [
      { internalType: 'address', name: 'newAlterToken', type: 'address' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'alternativeToken', type: 'address' }],
    name: 'changeAlternativeTokenStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'clientBetsLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'close', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'string', name: 'finalValue', type: 'string' },
    ],
    name: 'closeJackpotBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'company',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'string', name: 'eventId', type: 'string' },
          { internalType: 'uint256', name: 'lockTime', type: 'uint256' },
          { internalType: 'uint256', name: 'expirationTime', type: 'uint256' },
          { internalType: 'uint256', name: 'requestAmount', type: 'uint256' },
        ],
        internalType: 'struct JackpotDTOs.CreateJackpotRequest',
        name: 'createRequest',
        type: 'tuple',
      },
    ],
    name: 'createJackpotBet',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: 'enable', type: 'bool' }],
    name: 'enableAlternativeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getAlternativeFee',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getAlternativeIERC20Token',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCompanyFee',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'getCompanyFeeBalance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'betId', type: 'uint256' }],
    name: 'getJackpotBet',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'id', type: 'uint256' },
          { internalType: 'string', name: 'eventId', type: 'string' },
          { internalType: 'uint256', name: 'requestAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'lockTime', type: 'uint256' },
          { internalType: 'uint256', name: 'expirationTime', type: 'uint256' },
          { internalType: 'uint256', name: 'startBank', type: 'uint256' },
          { internalType: 'uint256', name: 'finalBank', type: 'uint256' },
          { internalType: 'string', name: 'finalJackpotValue', type: 'string' },
        ],
        internalType: 'struct JackpotDTOs.JackpotBet',
        name: '',
        type: 'tuple',
      },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'address', name: 'client', type: 'address' },
    ],
    name: 'getJackpotWonAmount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getMainIERC20Token',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getTimestampExpirationDelay',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'isAlternativeTokenEnabled',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'jackpotBetIdCounter',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint256', name: 'betId', type: 'uint256' },
          { internalType: 'bool', name: 'useAlterFee', type: 'bool' },
          { internalType: 'string', name: 'targetValue', type: 'string' },
        ],
        internalType: 'struct JackpotDTOs.JoinJackpotRequest',
        name: 'joinRequest',
        type: 'tuple',
      },
    ],
    name: 'joinJackpotBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'bool', name: 'useAlterFee', type: 'bool' },
          { internalType: 'uint256', name: 'betId', type: 'uint256' },
          { internalType: 'string[]', name: 'targetValues', type: 'string[]' },
        ],
        internalType: 'struct JackpotDTOs.MassJoinJackpotRequest',
        name: 'massJoinRequest',
        type: 'tuple',
      },
    ],
    name: 'massJoinJackpotBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'ownerAddStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'ownerToRemove', type: 'address' }],
    name: 'ownerToRemoveStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'owners',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'address', name: 'client', type: 'address' },
    ],
    name: 'refundJackpotBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'removeOwnerVoting',
    outputs: [
      { internalType: 'address', name: 'ownerToRemove', type: 'address' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'alternativeFee', type: 'uint256' }],
    name: 'setAlternativeFeeFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: 'enable', type: 'bool' }],
    name: 'setCancelEnabled',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'companyFee', type: 'uint256' }],
    name: 'setCompanyFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'router', type: 'address' }],
    name: 'setRouter',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'timestampExpirationDelay', type: 'uint256' }],
    name: 'setTimestampExpirationDelay',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'swapRouter',
    outputs: [{ internalType: 'contract SwapRouter', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'address', name: 'targetAddress', type: 'address' },
      { internalType: 'bool', name: 'isAlternative', type: 'bool' },
    ],
    name: 'takeFeeStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'takeFeeVoting',
    outputs: [
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'address', name: 'targetAddress', type: 'address' },
      { internalType: 'bool', name: 'isAlternative', type: 'bool' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'address', name: 'client', type: 'address' },
    ],
    name: 'takeJackpotPrize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalOwners',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newCompany', type: 'address' }],
    name: 'transferCompanyStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'transferCompanyVoting',
    outputs: [
      { internalType: 'address', name: 'newCompanyAddress', type: 'address' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'voteNegative',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'votePositive',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'voted',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'votingActive',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'votingInfo',
    outputs: [
      { internalType: 'address', name: 'initiator', type: 'address' },
      { internalType: 'uint256', name: 'currentNumberOfVotesPositive', type: 'uint256' },
      { internalType: 'uint256', name: 'currentNumberOfVotesNegative', type: 'uint256' },
      { internalType: 'uint256', name: 'startedDate', type: 'uint256' },
      { internalType: 'string', name: 'votingCode', type: 'string' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];

export const BINARY_CONTACT_ABI = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'mainToken',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'alternativeToken',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'AddOwner',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'client',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'joinIdRef',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'mainTokenRefunded',
        type: 'uint256',
      },
    ],
    name: 'BinaryBetCancelled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'lockedValue',
        type: 'string',
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'finalValue',
        type: 'string',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'sideWon',
        type: 'bool',
      },
    ],
    name: 'BinaryBetClosed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'eventId',
        type: 'string',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'lockTime',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'expirationTime',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'creator',
        type: 'address',
      },
    ],
    name: 'BinaryBetCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bool',
        name: 'side',
        type: 'bool',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'mainAmount',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'client',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'joinId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'joinRefId',
        type: 'uint256',
      },
    ],
    name: 'BinaryBetJoined',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'client',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'mainTokenRefunded',
        type: 'uint256',
      },
    ],
    name: 'BinaryBetRefunded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'client',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'useAlterFee',
        type: 'bool',
      },
    ],
    name: 'BinaryPrizeTaken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'previousAlternativeFee',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newAlternativeFee',
        type: 'uint256',
      },
    ],
    name: 'CompanyAlterFeeChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'previousCompanyFee',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newCompanyFee',
        type: 'uint256',
      },
    ],
    name: 'CompanyFeeChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousCompany',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newCompany',
        type: 'address',
      },
    ],
    name: 'CompanyTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'targetAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'isAlternative',
        type: 'bool',
      },
    ],
    name: 'FeeTaken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'targetAddress',
        type: 'address',
      },
    ],
    name: 'OtherTokensTaken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'ownerToRemove',
        type: 'address',
      },
    ],
    name: 'RemoveOwner',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'string',
        name: 'code',
        type: 'string',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'votingNumber',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'passed',
        type: 'bool',
      },
    ],
    name: 'VotingResult',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'string',
        name: 'code',
        type: 'string',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'votingNumber',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'initiator',
        type: 'address',
      },
    ],
    name: 'VotingStarted',
    type: 'event',
  },
  {
    inputs: [],
    name: 'acquireNewOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireOwnerToRemove',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireTakeFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireTransferCompany',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'addOwnerVoting',
    outputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'createdDate',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'votingCode',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'binaryBetIdCounter',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'joinIdRef',
        type: 'uint256',
      },
    ],
    name: 'cancelBinaryJoin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'clientBetsLength',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'close',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        internalType: 'string',
        name: 'lockedValue',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'finalValue',
        type: 'string',
      },
      {
        internalType: 'bool',
        name: 'sideWon',
        type: 'bool',
      },
    ],
    name: 'closeBinaryBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'company',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'string',
            name: 'eventId',
            type: 'string',
          },
          {
            internalType: 'uint256',
            name: 'lockTime',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expirationTime',
            type: 'uint256',
          },
        ],
        internalType: 'struct BinaryDTOs.CreateBinaryRequest',
        name: 'createRequest',
        type: 'tuple',
      },
    ],
    name: 'createBinaryBet',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bool',
        name: 'enable',
        type: 'bool',
      },
    ],
    name: 'enableAlternativeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getAlternativeFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getAlternativeToken',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
    ],
    name: 'getBinaryBet',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'string',
            name: 'eventId',
            type: 'string',
          },
          {
            internalType: 'uint256',
            name: 'lockTime',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expirationTime',
            type: 'uint256',
          },
          {
            internalType: 'string',
            name: 'lockedValue',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'finalValue',
            type: 'string',
          },
          {
            internalType: 'bool',
            name: 'sideWon',
            type: 'bool',
          },
        ],
        internalType: 'struct BinaryDTOs.BinaryBet',
        name: '',
        type: 'tuple',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'client',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
    ],
    name: 'getBinaryClientJoins',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'client',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'side',
            type: 'bool',
          },
          {
            internalType: 'uint256',
            name: 'joinRefId',
            type: 'uint256',
          },
        ],
        internalType: 'struct BinaryDTOs.JoinBinaryBetClient[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'client',
        type: 'address',
      },
    ],
    name: 'getBinaryWonAmount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'client',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'offset',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'size',
        type: 'uint256',
      },
    ],
    name: 'getClientBets',
    outputs: [
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCompanyAlternativeTokenBalance',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCompanyFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCompanyTokenBalance',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getMainToken',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getTimestampExpirationDelay',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'isAlternativeTokenEnabled',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        components: [
          {
            internalType: 'bool',
            name: 'side',
            type: 'bool',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        internalType: 'struct BinaryDTOs.JoinBinaryRequest',
        name: 'joinRequest',
        type: 'tuple',
      },
    ],
    name: 'joinBinaryBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'ownerAddStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'ownerToRemove',
        type: 'address',
      },
    ],
    name: 'ownerToRemoveStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'owners',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'client',
        type: 'address',
      },
    ],
    name: 'refundBinaryBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'removeOwnerVoting',
    outputs: [
      {
        internalType: 'address',
        name: 'ownerToRemove',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'createdDate',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'votingCode',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'alternativeFee',
        type: 'uint256',
      },
    ],
    name: 'setAlternativeFeeFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'companyFee',
        type: 'uint256',
      },
    ],
    name: 'setCompanyFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'router',
        type: 'address',
      },
    ],
    name: 'setRouter',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'timestampExpirationDelay',
        type: 'uint256',
      },
    ],
    name: 'setTimestampExpirationDelay',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'client',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'useAlterFee',
        type: 'bool',
      },
    ],
    name: 'takeBinaryPrize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'targetAddress',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'isAlternative',
        type: 'bool',
      },
    ],
    name: 'takeFeeStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'takeFeeVoting',
    outputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'targetAddress',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'isAlternative',
        type: 'bool',
      },
      {
        internalType: 'uint256',
        name: 'createdDate',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'votingCode',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newCompany',
        type: 'address',
      },
    ],
    name: 'transferCompanyStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'transferCompanyVoting',
    outputs: [
      {
        internalType: 'address',
        name: 'newCompanyAddress',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'createdDate',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'votingCode',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'voteNegative',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'votePositive',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'voted',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'votingActive',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'votingInfo',
    outputs: [
      {
        internalType: 'address',
        name: 'initiator',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'currentNumberOfVotesPositive',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'currentNumberOfVotesNegative',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'startedDate',
        type: 'uint256',
      },
      {
        internalType: 'string',
        name: 'votingCode',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];

export const CUSTOM_CONTRACT_ABI = [
  {
    inputs: [
      { internalType: 'address', name: 'mainToken', type: 'address' },
      { internalType: 'address[]', name: 'owners', type: 'address[]' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'AddOwner',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'newAlterToken', type: 'address' }],
    name: 'ChangeAlterToken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestampExpirationDelay',
        type: 'uint256',
      },
    ],
    name: 'ChangeTimestampDelay',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'previousAlternativeFee', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newAlternativeFee', type: 'uint256' },
    ],
    name: 'CompanyAlterFeeChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'previousCompanyFee', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newCompanyFee', type: 'uint256' },
    ],
    name: 'CompanyFeeChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousCompany', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newCompany', type: 'address' },
    ],
    name: 'CompanyTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'client', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'joinIdRef', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'mainTokenRefunded', type: 'uint256' },
    ],
    name: 'CustomBetCancelled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'finalValue', type: 'string' },
      { indexed: false, internalType: 'bool', name: 'targetSideWon', type: 'bool' },
    ],
    name: 'CustomBetClosed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'id', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'eventId', type: 'string' },
      { indexed: false, internalType: 'bool', name: 'hidden', type: 'bool' },
      { indexed: false, internalType: 'uint256', name: 'lockTime', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'expirationTime', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'targetValue', type: 'string' },
      { indexed: false, internalType: 'bool', name: 'targetSide', type: 'bool' },
      { indexed: false, internalType: 'uint256', name: 'coefficient', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'creator', type: 'address' },
    ],
    name: 'CustomBetCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bool', name: 'side', type: 'bool' },
      { indexed: false, internalType: 'uint256', name: 'mainAmount', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'client', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'joinId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'joinIdRef', type: 'uint256' },
    ],
    name: 'CustomBetJoined',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'client', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'mainTokenRefunded', type: 'uint256' },
    ],
    name: 'CustomBetRefunded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'client', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: 'useAlterFee', type: 'bool' },
    ],
    name: 'CustomPrizeTaken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'targetAddress', type: 'address' },
      { indexed: false, internalType: 'bool', name: 'isAlternative', type: 'bool' },
    ],
    name: 'FeeTaken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'ownerToRemove', type: 'address' }],
    name: 'RemoveOwner',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'newSwapRouter', type: 'address' }],
    name: 'SetRouter',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'code', type: 'string' },
      { indexed: false, internalType: 'uint256', name: 'votingNumber', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: 'passed', type: 'bool' },
    ],
    name: 'VotingResult',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'code', type: 'string' },
      { indexed: false, internalType: 'uint256', name: 'votingNumber', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'initiator', type: 'address' },
    ],
    name: 'VotingStarted',
    type: 'event',
  },
  {
    inputs: [],
    name: 'acquireNewAlternativeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireNewOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireOwnerToRemove',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireTakeFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireTransferCompany',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'addOwnerVoting',
    outputs: [
      { internalType: 'address', name: 'newOwner', type: 'address' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'uint256', name: 'joinIdRef', type: 'uint256' },
    ],
    name: 'cancelCustomJoin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'changeAlterToken',
    outputs: [
      { internalType: 'address', name: 'newAlterToken', type: 'address' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'alternativeToken', type: 'address' }],
    name: 'changeAlternativeTokenStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'clientBetsLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'close', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'string', name: 'finalValue', type: 'string' },
      { internalType: 'bool', name: 'targetSideWon', type: 'bool' },
    ],
    name: 'closeCustomBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'company',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'string', name: 'eventId', type: 'string' },
          { internalType: 'bool', name: 'hidden', type: 'bool' },
          { internalType: 'uint256', name: 'lockTime', type: 'uint256' },
          { internalType: 'uint256', name: 'expirationTime', type: 'uint256' },
          { internalType: 'string', name: 'targetValue', type: 'string' },
          { internalType: 'bool', name: 'targetSide', type: 'bool' },
          { internalType: 'uint256', name: 'coefficient', type: 'uint256' },
        ],
        internalType: 'struct CustomDTOs.CreateCustomRequest',
        name: 'createRequest',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bool', name: 'side', type: 'bool' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        internalType: 'struct CustomDTOs.JoinCustomRequest',
        name: 'joinRequest',
        type: 'tuple',
      },
    ],
    name: 'createCustomBet',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'customBetIdCounter',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: 'enable', type: 'bool' }],
    name: 'enableAlternativeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getAlternativeFee',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getAlternativeIERC20Token',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'client', type: 'address' },
      { internalType: 'uint256', name: 'offset', type: 'uint256' },
      { internalType: 'uint256', name: 'size', type: 'uint256' },
    ],
    name: 'getClientBets',
    outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCompanyFee',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'getCompanyFeeBalance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'betId', type: 'uint256' }],
    name: 'getCustomBet',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'id', type: 'uint256' },
          { internalType: 'string', name: 'eventId', type: 'string' },
          { internalType: 'bool', name: 'hidden', type: 'bool' },
          { internalType: 'uint256', name: 'lockTime', type: 'uint256' },
          { internalType: 'uint256', name: 'expirationTime', type: 'uint256' },
          { internalType: 'string', name: 'targetValue', type: 'string' },
          { internalType: 'bool', name: 'targetSide', type: 'bool' },
          { internalType: 'uint256', name: 'coefficient', type: 'uint256' },
          { internalType: 'string', name: 'finalValue', type: 'string' },
          { internalType: 'bool', name: 'targetSideWon', type: 'bool' },
        ],
        internalType: 'struct CustomDTOs.CustomBet',
        name: '',
        type: 'tuple',
      },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'client', type: 'address' },
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
    ],
    name: 'getCustomClientJoins',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'id', type: 'uint256' },
          { internalType: 'address', name: 'client', type: 'address' },
          { internalType: 'uint256', name: 'freeAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'lockedAmount', type: 'uint256' },
          { internalType: 'bool', name: 'targetSide', type: 'bool' },
          { internalType: 'uint256', name: 'joinRefId', type: 'uint256' },
        ],
        internalType: 'struct CustomDTOs.JoinCustomBetClient[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'address', name: 'client', type: 'address' },
    ],
    name: 'getCustomWonAmount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getMainIERC20Token',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getTimestampExpirationDelay',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'isAlternativeTokenEnabled',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      {
        components: [
          { internalType: 'bool', name: 'side', type: 'bool' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        internalType: 'struct CustomDTOs.JoinCustomRequest',
        name: 'joinRequest',
        type: 'tuple',
      },
    ],
    name: 'joinCustomBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'ownerAddStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'ownerToRemove', type: 'address' }],
    name: 'ownerToRemoveStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'owners',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'address', name: 'client', type: 'address' },
    ],
    name: 'refundCustomBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'removeOwnerVoting',
    outputs: [
      { internalType: 'address', name: 'ownerToRemove', type: 'address' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'alternativeFee', type: 'uint256' }],
    name: 'setAlternativeFeeFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'companyFee', type: 'uint256' }],
    name: 'setCompanyFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'router', type: 'address' }],
    name: 'setRouter',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'timestampExpirationDelay', type: 'uint256' }],
    name: 'setTimestampExpirationDelay',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'swapRouter',
    outputs: [{ internalType: 'contract SwapRouter', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'address', name: 'client', type: 'address' },
      { internalType: 'bool', name: 'useAlterFee', type: 'bool' },
    ],
    name: 'takeCustomPrize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'address', name: 'targetAddress', type: 'address' },
      { internalType: 'bool', name: 'isAlternative', type: 'bool' },
    ],
    name: 'takeFeeStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'takeFeeVoting',
    outputs: [
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'address', name: 'targetAddress', type: 'address' },
      { internalType: 'bool', name: 'isAlternative', type: 'bool' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalOwners',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newCompany', type: 'address' }],
    name: 'transferCompanyStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'transferCompanyVoting',
    outputs: [
      { internalType: 'address', name: 'newCompanyAddress', type: 'address' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'voteNegative',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'votePositive',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'voted',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'votingActive',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'votingInfo',
    outputs: [
      { internalType: 'address', name: 'initiator', type: 'address' },
      { internalType: 'uint256', name: 'currentNumberOfVotesPositive', type: 'uint256' },
      { internalType: 'uint256', name: 'currentNumberOfVotesNegative', type: 'uint256' },
      { internalType: 'uint256', name: 'startedDate', type: 'uint256' },
      { internalType: 'string', name: 'votingCode', type: 'string' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];

export const AUCTION_CONTACT_ABI = [
  {
    inputs: [
      { internalType: 'address', name: 'mainToken', type: 'address' },
      { internalType: 'address', name: 'alternativeToken', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'AddOwner',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'client', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'joinIdRef', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'mainTokenRefunded', type: 'uint256' },
    ],
    name: 'AuctionBetCancelled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'finalValue', type: 'string' },
      { indexed: false, internalType: 'uint256[]', name: 'joinIdsWon', type: 'uint256[]' },
    ],
    name: 'AuctionBetClosed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'id', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: 'hidden', type: 'bool' },
      { indexed: false, internalType: 'string', name: 'eventId', type: 'string' },
      { indexed: false, internalType: 'uint256', name: 'lockTime', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'expirationTime', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'creator', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'requestAmount', type: 'uint256' },
    ],
    name: 'AuctionBetCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'client', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'joinId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'joinRefId', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'targetValue', type: 'string' },
    ],
    name: 'AuctionBetJoined',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'client', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'mainTokenRefunded', type: 'uint256' },
    ],
    name: 'AuctionBetRefunded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'betId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'client', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: 'useAlterFee', type: 'bool' },
    ],
    name: 'AuctionPrizeTaken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'previousAlternativeFee', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newAlternativeFee', type: 'uint256' },
    ],
    name: 'CompanyAlterFeeChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'previousCompanyFee', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newCompanyFee', type: 'uint256' },
    ],
    name: 'CompanyFeeChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousCompany', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newCompany', type: 'address' },
    ],
    name: 'CompanyTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'targetAddress', type: 'address' },
      { indexed: false, internalType: 'bool', name: 'isAlternative', type: 'bool' },
    ],
    name: 'FeeTaken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'tokenAddress', type: 'address' },
      { indexed: true, internalType: 'address', name: 'targetAddress', type: 'address' },
    ],
    name: 'OtherTokensTaken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'ownerToRemove', type: 'address' }],
    name: 'RemoveOwner',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'code', type: 'string' },
      { indexed: false, internalType: 'uint256', name: 'votingNumber', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: 'passed', type: 'bool' },
    ],
    name: 'VotingResult',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'code', type: 'string' },
      { indexed: false, internalType: 'uint256', name: 'votingNumber', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'initiator', type: 'address' },
    ],
    name: 'VotingStarted',
    type: 'event',
  },
  {
    inputs: [],
    name: 'acquireNewOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireOwnerToRemove',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireTakeFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'acquireTransferCompany',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'addOwnerVoting',
    outputs: [
      { internalType: 'address', name: 'newOwner', type: 'address' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'auctionBetIdCounter',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'uint256', name: 'joinRefId', type: 'uint256' },
    ],
    name: 'cancelAuctionJoin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'clientBetsLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'close', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'string', name: 'finalValue', type: 'string' },
      { internalType: 'uint256[]', name: 'joinIdsWon', type: 'uint256[]' },
    ],
    name: 'closeAuctionBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'company',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'string', name: 'eventId', type: 'string' },
          { internalType: 'uint256', name: 'lockTime', type: 'uint256' },
          { internalType: 'uint256', name: 'expirationTime', type: 'uint256' },
          { internalType: 'uint256', name: 'requestAmount', type: 'uint256' },
        ],
        internalType: 'struct AuctionDTOs.CreateAuctionRequest',
        name: 'createRequest',
        type: 'tuple',
      },
    ],
    name: 'createAuctionBet',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'string', name: 'eventId', type: 'string' },
          { internalType: 'uint256', name: 'lockTime', type: 'uint256' },
          { internalType: 'uint256', name: 'expirationTime', type: 'uint256' },
          { internalType: 'uint256', name: 'requestAmount', type: 'uint256' },
        ],
        internalType: 'struct AuctionDTOs.CreateAuctionRequest',
        name: 'createRequest',
        type: 'tuple',
      },
    ],
    name: 'createPrivateAuctionBet',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: 'enable', type: 'bool' }],
    name: 'enableAlternativeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getAlternativeFee',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getAlternativeToken',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'betId', type: 'uint256' }],
    name: 'getAuctionBet',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'id', type: 'uint256' },
          { internalType: 'bool', name: 'hidden', type: 'bool' },
          { internalType: 'string', name: 'eventId', type: 'string' },
          { internalType: 'uint256', name: 'requestAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'lockTime', type: 'uint256' },
          { internalType: 'uint256', name: 'expirationTime', type: 'uint256' },
          { internalType: 'string', name: 'finalValue', type: 'string' },
        ],
        internalType: 'struct AuctionDTOs.AuctionBet',
        name: '',
        type: 'tuple',
      },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'client', type: 'address' },
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
    ],
    name: 'getAuctionClientJoins',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'id', type: 'uint256' },
          { internalType: 'address', name: 'client', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'string', name: 'targetValue', type: 'string' },
          { internalType: 'uint256', name: 'joinIdRef', type: 'uint256' },
        ],
        internalType: 'struct AuctionDTOs.JoinAuctionBetClient[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'address', name: 'client', type: 'address' },
    ],
    name: 'getAuctionWonAmount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'client', type: 'address' },
      { internalType: 'uint256', name: 'offset', type: 'uint256' },
      { internalType: 'uint256', name: 'size', type: 'uint256' },
    ],
    name: 'getClientBets',
    outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCompanyAlternativeTokenBalance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCompanyFee',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCompanyTokenBalance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getMainToken',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getTimestampExpirationDelay',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'isAlternativeTokenEnabled',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint256', name: 'betId', type: 'uint256' },
          { internalType: 'string', name: 'targetValue', type: 'string' },
        ],
        internalType: 'struct AuctionDTOs.JoinAuctionRequest',
        name: 'joinRequest',
        type: 'tuple',
      },
    ],
    name: 'joinAuctionBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint256', name: 'betId', type: 'uint256' },
          { internalType: 'bool', name: 'useAlterFee', type: 'bool' },
          { internalType: 'string[]', name: 'targetValues', type: 'string[]' },
        ],
        internalType: 'struct AuctionDTOs.MassJoinAuctionRequest',
        name: 'massJoinRequest',
        type: 'tuple',
      },
    ],
    name: 'massJoinAuctionBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'ownerAddStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'ownerToRemove', type: 'address' }],
    name: 'ownerToRemoveStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'owners',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'address', name: 'client', type: 'address' },
    ],
    name: 'refundAuctionBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'removeOwnerVoting',
    outputs: [
      { internalType: 'address', name: 'ownerToRemove', type: 'address' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'alternativeFee', type: 'uint256' }],
    name: 'setAlternativeFeeFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'companyFee', type: 'uint256' }],
    name: 'setCompanyFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'router', type: 'address' }],
    name: 'setRouter',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'timestampExpirationDelay', type: 'uint256' }],
    name: 'setTimestampExpirationDelay',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'betId', type: 'uint256' },
      { internalType: 'address', name: 'client', type: 'address' },
      { internalType: 'bool', name: 'useAlterFee', type: 'bool' },
    ],
    name: 'takeAuctionPrize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'address', name: 'targetAddress', type: 'address' },
      { internalType: 'bool', name: 'isAlternative', type: 'bool' },
    ],
    name: 'takeFeeStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'takeFeeVoting',
    outputs: [
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'address', name: 'targetAddress', type: 'address' },
      { internalType: 'bool', name: 'isAlternative', type: 'bool' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newCompany', type: 'address' }],
    name: 'transferCompanyStart',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'transferCompanyVoting',
    outputs: [
      { internalType: 'address', name: 'newCompanyAddress', type: 'address' },
      { internalType: 'uint256', name: 'createdDate', type: 'uint256' },
      { internalType: 'uint256', name: 'votingCode', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'voteNegative',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'votePositive',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'voted',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'votingActive',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'votingInfo',
    outputs: [
      { internalType: 'address', name: 'initiator', type: 'address' },
      { internalType: 'uint256', name: 'currentNumberOfVotesPositive', type: 'uint256' },
      { internalType: 'uint256', name: 'currentNumberOfVotesNegative', type: 'uint256' },
      { internalType: 'uint256', name: 'startedDate', type: 'uint256' },
      { internalType: 'string', name: 'votingCode', type: 'string' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];

export const TOKEN_CONTRACT_ABI = [
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'from', type: 'address' },
      { name: 'to', type: 'address' },
      { name: 'value', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'DECIMALS',
    outputs: [{ name: '', type: 'uint8' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'INITIAL_SUPPLY',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', type: 'uint8' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'addedValue', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'to', type: 'address' },
      { name: 'value', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ name: 'value', type: 'uint256' }],
    name: 'burn',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: 'owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'from', type: 'address' },
      { name: 'value', type: 'uint256' },
    ],
    name: 'burnFrom',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ name: 'account', type: 'address' }],
    name: 'addMinter',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [],
    name: 'renounceMinter',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'subtractedValue', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'to', type: 'address' },
      { name: 'value', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: 'account', type: 'address' }],
    name: 'isMinter',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], payable: false, stateMutability: 'nonpayable', type: 'constructor' },
  { payable: false, stateMutability: 'nonpayable', type: 'fallback' },
  {
    anonymous: false,
    inputs: [{ indexed: true, name: 'account', type: 'address' }],
    name: 'MinterAdded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, name: 'account', type: 'address' }],
    name: 'MinterRemoved',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'from', type: 'address' },
      { indexed: true, name: 'to', type: 'address' },
      { indexed: false, name: 'value', type: 'uint256' },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'owner', type: 'address' },
      { indexed: true, name: 'spender', type: 'address' },
      { indexed: false, name: 'value', type: 'uint256' },
    ],
    name: 'Approval',
    type: 'event',
  },
];
