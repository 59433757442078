import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import HeaderBlock from 'components/Containers/HeaderBlock';
import JackPool from 'components/Containers/JackPool';
import BellButton from 'components/NotificationCenter/BellButton';
import { ThemeType } from 'types/baseTypes';

import { useConnectWallet } from '../../../../hooks/web3/useConnectWallet';
import { Web3ConnectWalletDesktop, Web3ConnectWalletMob } from '../../../../module/web3/wallet';
import useColor from '../../../../theme/useColor';
import useMediaQuery from '../../../../theme/useMediaQuery';
import { BurgerIcon, CircleIcon, CloseIcon, CrumbsIcon } from '../../../Icons';
import LogoIcon from '../../../Icons/LogoIcon';
import LogoIconTestnet from '../../../Icons/LogoIconTestnet';
import NotificationCenter from '../../../NotificationCenter';
import Button from '../../../Simple/Button';
import Label from '../../../Simple/Label';
import { LoginLoader } from '../../../Simple/Loader/LoginLoader';
import Switcher from '../../../Simple/Switcher';
import Typography from '../../../Simple/Typography';
import Menu from '../../Menu';
import NavigateBlock from '../../NavigateBlock';
import Translated from '../../Translated';
import s from './Header.module.scss';

interface IsConnectWalletBlockProps {
  menuFunction?: () => void;
  theme?: ThemeType;
}

interface HeaderProps {
  theme?: ThemeType;
  setOpenSideBar?: () => void;
  isOpenSideBar?: boolean;
  changeTheme: () => void;
  notification?: boolean;
  menuFunction?: () => void;
  connectWalletFunction?: () => void;
  isConnectWallet?: boolean;
  connectText?: string;
}

const IsConnectWalletBlock = ({ theme }: IsConnectWalletBlockProps) => {
  const navigate = useNavigate();
  const { fullAddress, logoutWallet } = useConnectWallet();

  return (
    <>
      <div className={s.menuLabel} onClick={() => navigate('/portfolio')}>
        <Label
          nextElement
          theme={theme}
          title={
            <Typography variant="title">
              <Translated id="header.burger.my-deals" />
            </Typography>
          }
        />
      </div>
      <div className={s.menuLabel} onClick={() => navigator.clipboard.writeText(fullAddress || '')}>
        <Label
          theme={theme}
          nextElement
          title={
            <Typography variant="title">
              <Translated id="header.burger.copy-address" />
            </Typography>
          }
        />
      </div>
      <div
        className={s.menuLabel}
        onClick={() => {
          logoutWallet();
        }}>
        <Label
          theme={theme}
          title={
            <Typography variant="title">
              <Translated id="header.burger.log-out" />
            </Typography>
          }
        />
      </div>
    </>
  );
};

const Header = ({ theme = 'default', setOpenSideBar, isOpenSideBar, changeTheme }: HeaderProps) => {
  const { lowDark, white, blue800, primary } = useColor();
  const isLaptop = useMediaQuery(1024);
  const navigate = useNavigate();

  const [openModalWalletConnect, setOpenModalWalletConnect] = useState(false);

  const handleOpenModalWalletConnect = () => {
    setOpenModalWalletConnect((prevState) => !prevState);
  };

  const { address, isLoading } = useConnectWallet();

  useEffect(() => {
    if (address) setOpenModalWalletConnect(false);
  }, [address]);

  const isDarkTheme = theme === 'dark';
  const getColor = (isDarkTh: boolean) => (isDarkTh ? white : lowDark);
  const isTestnet = window.location.href.includes('testnet');
  return (
    <header>
      <div className={`${s.wrapper} ${s[theme]} `}>
        <div
          className={classNames(
            s.sideBlock,
            s[`sideBlock-${theme}`],
            `${s[`sideBlock-${theme}-${isOpenSideBar}`]}`,
          )}>
          <div
            className={s.logoI}
            onClick={() => {
              navigate('/');
            }}>
            {isTestnet ? (
              <LogoIconTestnet color={getColor(isDarkTheme)} />
            ) : (
              <LogoIcon color={getColor(isDarkTheme)} />
            )}
          </div>
        </div>
        <div className={classNames(s.navigateWrapper, s[`navigateWrapper-${theme}`])}>
          <NavigateBlock />
          <div className={s.connectBlock}>
            {/*<JackPool />*/}
            {isLoading && <LoginLoader />}
            {address ? (
              <>
                {/*<NotificationCenter />*/}
                <div className={s.connectLeft}>
                  {' '}
                  <div className={s.connectBtn}>
                    <div className={s[`circleIcon-${theme}`]}>
                      <CircleIcon stopColor={isDarkTheme ? blue800 : primary} />
                    </div>
                    <div className={s.box}>
                      <div className={s.text}>{address}</div>
                    </div>
                  </div>
                </div>
              </>
            ) : !isOpenSideBar || window.innerWidth >= 1024 ? (
              <div>
                <Button
                  onClick={handleOpenModalWalletConnect}
                  size="sm"
                  className={s.connectWalletBtn}>
                  <span className={s.wallet_button}>Connect Wallet</span>
                </Button>
                {openModalWalletConnect && window.innerWidth >= 1024 && (
                  <Web3ConnectWalletDesktop
                    theme={theme}
                    handlerDropDown={handleOpenModalWalletConnect}
                  />
                )}
                {openModalWalletConnect && window.innerWidth < 1024 && (
                  <Web3ConnectWalletMob
                    openModal={openModalWalletConnect}
                    setOpen={handleOpenModalWalletConnect}
                    theme={theme}
                  />
                )}
              </div>
            ) : null}
            {address && (
              <div className={s.rightIconBtn}>
                <Menu
                  anchor="bottom"
                  button={
                    <Button iconButton>
                      <div style={{ transform: 'rotate(90deg)', marginLeft: '-3px' }}>
                        <CrumbsIcon color={getColor(isDarkTheme)} />
                      </div>
                    </Button>
                  }
                  theme={theme}>
                  <IsConnectWalletBlock theme={theme} />
                </Menu>
              </div>
            )}
          </div>
        </div>
      </div>
      {!isLaptop && isOpenSideBar ? <HeaderBlock changeTheme={changeTheme} theme={theme} /> : null}
    </header>
  );
};

export default Header;
