import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApi } from 'store/consts';
import { getExecutionType } from 'store/utils/getExecutionType';

export const API_NAME = 'eventsService';

export interface IEvent {
  id: string;
  type: string;
  symbol: string;
  fullName: string;
  name: string;
  createdDate: Date;
  finBetCount: number;
  finBetPool: number;
  iconBase64: string;
}

export const eventsService = createApi({
  reducerPath: API_NAME,
  baseQuery: fetchBaseQuery({ baseUrl: `${baseApi}/events/` }),
  endpoints: (builder) => ({
    eventsList: builder.query<any | Event[], unknown>({
      query: () => ({
        url: ``,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: {
          executionType: getExecutionType(),
          page: 0,
          size: 100,
        },
      }),
    }),
    eventTypes: builder.query({
      query: () => ({
        url: `/types`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useEventsListQuery, useEventTypesQuery } = eventsService;
