import React, { useContext, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { CryptoContext } from 'context';
import useBinary from 'hooks/useBinary';
import useJackpot from 'hooks/useJackpot';
import useTranslate from 'hooks/useTranslate.hook';
import useWrapAccount from 'hooks/useWrapAccount';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useEventsListQuery } from 'store/services/eventsService';
import { mapCoefficient } from 'store/utils/mapCoef';

import RangeSlider from 'components/Containers/RangeSlider';
import Translated from 'components/Containers/Translated';
import { ChevronIcon, CloseIcon, TetherIcon, TooltipIcon } from 'components/Icons';
import AccordionSwitcher from 'components/Simple/AccordionSwitcher';
import Button from 'components/Simple/Button';
import CurrencyPair from 'components/Simple/CurrencyPair';
import Processing from 'components/Simple/Processing';
import PulseBulb from 'components/Simple/PulseBulb';
import TextField from 'components/Simple/TextField';
import Tooltip from 'components/Simple/Tooltip';
import Typography from 'components/Simple/Typography';
import { formatDate } from 'helpers/formatDate';
import formatLeft from 'helpers/formatLeft';
import { encode } from 'helpers/hash';
import { AppLangs } from 'types/baseTypes';

import s from './JackpotBetDrawerContent.module.scss';

interface OptionBetDrawerContentProps {
  content?: any;
  onVisibleChange: (value: any) => void;
}

interface CeilProps {
  leftBlock?: React.ReactNode;
  title?: string;
  subTitle?: string;
}

const Ceil = ({ leftBlock, title = '', subTitle = '' }: CeilProps) => (
  <div className={s.ceil}>
    {leftBlock}
    <div className={s.centerText}>
      <Typography>{title}</Typography>
      <Typography color='grey' variant='subtitle'>
        {subTitle}
      </Typography>
    </div>
  </div>
);

const regexp = {
  1: /^([0-9]{1,5})(\.?)([0-9]{0,2})$/,
  10: /^([0-9]{1,5})(\.?)([0-9]{0,1})$/,
  100: /^([0-9]{1,5})$/,
};

export default function JackpotBetDrawerContent({
                                                  content,
                                                  onVisibleChange,
                                                }: OptionBetDrawerContentProps) {
  const theme = useSelector((state: RootState) => state.app.theme);
  const rates = useSelector((state: RootState) => state.app.rates);
  const lang = useSelector((state: RootState) => state.app.lang);
  const account = useWrapAccount();
  const [userBets, setUserBets] = useState({
    1: [],
    10: [],
    100: [],
  });

  const setBets = (bets: any[]) => {
    setUserBets({
      ...userBets,
      [button]: bets,
    });
  };


  const { join, isLoading, jackpot_company_fee } = useJackpot();

  const {
    state: { balance },
  } = useContext(CryptoContext);

  const navigate = useNavigate();
  const location = useLocation();

  const { data: events } = useEventsListQuery(null);
  const [bet, setBet] = useState('');

  const event = useMemo(() => {
    return events?.result?.find((event: any) => event.id === content?.eventId);
  }, [events, content]);

  const commonPool = useTranslate('jackpot.pool');
  const currentRate = useTranslate('new-bet.current-rate');
  const predictRate = useTranslate('portfolio.predict-btc');
  const dynamyc = useTranslate('options.dinamyc');
  const maxWin = useTranslate('states.maximum-win');

  const acceptingBets = useTranslate('options.accepting-bets');
  const waitingResult = useTranslate('options.waiting-result');
  const dealClosed = useTranslate('options.deal-closed');
  const yourBet = useTranslate('states.your-bet');
  const priceWill = useTranslate('options.price-will');
  const yourBetTooltip = useTranslate('tooltip.jackpot.your-bet');
  const maximumWinTooltip = useTranslate('tooltip.jackpot.maximum-win');
  const nextBet = useTranslate('states.next-bet');
  const [showMore, setShowMore] = useState(false);
  const betText = useTranslate('my-deals.bet');
  const processing = useTranslate('app.processing');
  const makeABet = useTranslate('states.make-bet');
  const confirmBets = useTranslate('states.confirm-bets');
  const [button, setButton] = useState(1);


  //@ts-ignore
  const bets = button !== 100 ? userBets[button] : undefined;

  useEffect(() => {
    if (button === 1 && bet) setBet(Number(bet).toFixed(2));
    if (button === 10 && bet) setBet(Number(bet).toFixed(1));
    if (button === 100 && bet) setBet(Number(bet).toFixed(0));
  }, [button]);

  const makeBet = () => {
    join(content?.id, content?.requestAmount, bets.length ? bets : [bet], button).then(() => {
      onVisibleChange(false);
      setUserBets({
        1: [],
        10: [],
        100: [],
      });
    });
  };

  if (!content) {
    return null;
  }

  const now = Date.now();
  const left = new Date(content.lockDate + 'Z').getTime() - now;
  const expiration = new Date(content.expirationDate + 'Z').getTime();

  let pulseTitle = '',
    pulseVariant: 'open' | 'accepted' | 'closed' = 'open';
  if (left > 0) {
    pulseTitle = acceptingBets;
  } else if (expiration > now) {
    pulseVariant = 'accepted';
    pulseTitle = waitingResult;
  } else {
    pulseVariant = 'closed';
    pulseTitle = dealClosed;
  }

  const sum = content?.requestAmount
    ? (bets ? bets.length || 1 : 1) * content?.requestAmount
    : Number(bet);

  const disabledNextBet =
    (bets && bets.find((b: any) => Number(b) === Number(bet))) ||
    (content?.requestAmount && balance !== undefined && sum + content?.requestAmount > balance);

  return (
    <>
      <div className={s.top}>
        <Typography variant={'h1'}>
          <Translated id='sidebar.finance-deals.make-bet' />
        </Typography>

        <div className={s.label}>
          <PulseBulb variant={pulseVariant} />
          <span>{pulseTitle}</span>
        </div>
      </div>
      <div className={s.headRow}>
        <Ceil
          leftBlock={
            <CurrencyPair
              iconLeft={<img src={event?.iconBase64} width={25} />}
              iconRight={<TetherIcon />}
              theme={theme}
            />
          }
          subTitle={currentRate}
          title={`$ ${rates[event?.symbol] || 0}`}
        />
        <div className={s.divider} />
        <Ceil subTitle={commonPool} title={`${content?.totalPool?.toFixed(2)} USDT`} />
      </div>
      <div className={s.pane}>
        {predictRate} {formatDate(content?.expirationDate)}
      </div>

      <div className={classNames(s.mb_5, s.dealBlock, s.btnGroupTitle)}>
        <Typography>{yourBet}</Typography>
        <Tooltip theme={theme} toolTipText={yourBetTooltip}>
          <TooltipIcon theme={theme} />
        </Tooltip>
      </div>
      <div className={classNames(s.mb_10, s.btnGroup)}>
        <div
          className={classNames(button === 1 && s.active)}
          onClick={() => setButton && setButton(1)}>
          <Typography>
            {Math.ceil(content?.requestAmount * ((100 + jackpot_company_fee) / 100))} $
          </Typography>
        </div>
        <div
          className={classNames(button === 10 && s.active)}
          onClick={() => setButton && setButton(10)}>
          {Math.ceil(content?.requestAmount * 10 * ((100 + jackpot_company_fee) / 100))} $
        </div>
        <div
          className={classNames(button === 100 && s.active)}
          onClick={() => setButton && setButton(100)}>
          {Math.ceil(content?.requestAmount * 100 * ((100 + jackpot_company_fee) / 100))} $
        </div>
      </div>
      <div className={classNames(s.mb_5, s.dealBlock)}>
        <Typography>{priceWill}</Typography>
      </div>
      <div className={s.mb_20}>
        <TextField
          background={theme === 'dark' ? '#171A22' : '#FFFFFF'}
          placeholder='10 USDT'
          setValue={(e) => {
            let v = e.target.value;
            const splited = v.split('.');

            if (splited[0].length > 1 && v[0] === '0' && v !== '0.') {
              v = '0.' + v.slice(1);
            } else if (
              splited[0].length > 5 &&
              bet &&
              v.length > bet.length &&
              splited.length === 1
            ) {
              v = v.slice(0, 5) + '.' + v[v.length - 1];
            } else if (
              button !== 100 &&
              splited[0].length > 4 &&
              bet &&
              v.length > bet.length &&
              splited.length === 1
            ) {
              v += '.';
            }

            // @ts-ignore
            if (v && !v.match(regexp[button || 1])) {
              return false;
            }

            setBet(v);
          }}
          value={bet || ''}
          theme={theme}
        />
      </div>
      <div className={s['divider-horizontal']} />
      <div className={classNames(s.mb_25, s.row)}>
        <div className={s.commissionBlock}>
          <Typography>{maxWin}</Typography>
          <Tooltip theme={theme} toolTipText={maximumWinTooltip}>
            <TooltipIcon theme={theme} />
          </Tooltip>
        </div>

        <div>{(content?.totalPool * 0.777).toFixed(2)}</div>
      </div>
      <div className={s.betsWrapper}>
        {bets?.slice(0, showMore ? bets.length : 3).map((bet: any, index: number) => (
          <div className={s.betRow}>
            <span>
              {betText} #{index + 1}
            </span>
            <div className={s.betRight}>
              <span>$ {bet}</span>
              <div
                className={s.removeBet}
                onClick={() => {
                  setBets(bets.filter((b: any, i: number) => index !== i));
                }}>
                <CloseIcon color={'#FFF'} size={'5'} />
              </div>
            </div>
          </div>
        ))}
      </div>
      {bets && bets.length > 3 && (
        <div onClick={() => setShowMore(!showMore)} className={s.showMore}>
          <ChevronIcon color={'#FFF'} className={showMore ? s.rotated : ''} />
        </div>
      )}
      <div className={s.buttons}>
        {bets && <Button
          color='grey'
          disabled={!bet || !!disabledNextBet || (left !== undefined && left <= 0)}
          fullWidth
          onClick={() => {
            /* @ts-ignore */
            setBets([...bets, Number(bet).toString()]);
            setBet('');
          }}
          size='xl'
          theme={theme}>
          {nextBet}
        </Button>}
        <Button
          color='secondary'
          fullWidth
          disabled={
            (left !== undefined && left <= 0) ||
            !account ||
            (!bet && (!bets || bets?.length === 0)) ||
            (!!content?.requestAmount && balance !== undefined && sum > balance)
          }
          onClick={makeBet}
          size='xl'
          isLoading={isLoading}
          theme={theme}>
          {bets && bets.length >= 1
            ? !isLoading
              ? confirmBets + ` (${bets.length})`
              : processing
            : !isLoading
              ? makeABet
              : processing}
        </Button>
        <Button
          color='grey'
          fullWidth
          onClick={() => {
            onVisibleChange(null);
          }}
          size='xl'
          theme={theme}>
          <Translated id={'states.back-to-deal'} />
        </Button>
      </div>
    </>
  );
}
